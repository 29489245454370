import { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@naturacosmeticos/natds-web'

export const useMaintenancePersonalDataPage = makeStyles<Theme>(() => ({
  title: {
    marginBottom: '24px',
  },
  container: {
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  '@global': {
    'input#nickname': {
      textTransform: 'capitalize'
    },
  },
}))
