import React from 'react'
import styled from 'styled-components'
import PendingIcon from '@/main/assets/svgs/pending_icon.svg'
import ApprovedIcon from '@/main/assets/svgs/approved_icon_thin.svg'
import RejectedIcon from '@/main/assets/svgs/rejected_icon.svg'
import ErrorSadIcon from '@/main/assets/svgs/error_sad_icon.svg'
import WarningIcon from '@/main/assets/svgs/warning.svg'
import { StatusType } from '@/domain/use-cases/digital-store'

type StatusIconType = {
  name?: StatusType
  size?: string
  marginBottom?: string
}

const StyledIcon = styled.img<{ size?: string, marginBottom?: string }>`
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '16px')};
  width: ${(props) => props.size};

`

const Icons = {
  approved: ApprovedIcon,
  success: ApprovedIcon,
  rejected: RejectedIcon,
  pending: PendingIcon,
  error: ErrorSadIcon,
  warning: WarningIcon,
}

export const StatusIcon: React.FC<StatusIconType> = ({ name, size, marginBottom }) => {
  const ariaLabel = `${name}_icon`
  const iconPath = Icons[name]

  if (iconPath) {
    return <StyledIcon src={iconPath} aria-label={ariaLabel} size={size} marginBottom={marginBottom} />
  }

  return null
}
