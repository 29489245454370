import React, { useContext } from 'react'
import { PaymentAccountStatusSuccessMessages } from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import {
  Divider,
  Intro,
  Spacing,
  TextParagraph,
  Typography,
} from '@/main/components'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { IdentityContext } from '@/main/contexts'
import { useTenantVendor } from '@/main/hooks/useTenantVendor'
import { PaymentAccountStatusDefault } from '../payment-account-status-default/payment-account-status-default'
import {
  StyledButton, StyledButtonContainer,
  StyledSuccessInformationContainer,
} from './payment-account-status-success.styles'

export const PaymentAccountStatusSuccess: React.FC = () => {
  const { tenantId } = useContext(IdentityContext)
  const vendor = useTenantVendor(tenantId)
  const pageMessagesOptions = { vendorName: vendor.name }
  const messages = usePageMessages(Page.PaymentAccountStatusSuccess, pageMessagesOptions).messages as PaymentAccountStatusSuccessMessages

  const openMinegocioUrl = () => {
    window.open(messages.miNegocioUrl, '_self')
  }

  const openCTAUrl = () => {
    window.open(messages.ctaButton.link, '_self')
  }

  return (
    <PaymentAccountStatusDefault config={{ status: 'success' }}>
      <Spacing marginY="standard">
        <Divider />
      </Spacing>
      <Typography align="left">
        <Spacing marginY="semi">
          <TextParagraph
            content={messages.reviewConsultant.info}
            className="styled-text-paragraph"
          />
        </Spacing>
        <StyledSuccessInformationContainer>
          <Spacing marginY="semi">
            <Intro
              title={messages.newConsultantInfo.title}
              detail={
                <TextParagraph content={messages.newConsultantInfo.text} />
              }
            />
          </Spacing>
          <Spacing marginY="semi">
            <Intro
              title={messages.alreadyIsConsultantInfo.title}
              detail={messages.alreadyIsConsultantInfo.text}
            />
          </Spacing>
        </StyledSuccessInformationContainer>
      </Typography>

      <StyledButtonContainer>
        <StyledButton
          variant="contained"
          color="primary"
          onClick={openMinegocioUrl}
          className="payment-account-success-button"
        >
          {messages.button}
        </StyledButton>

        <StyledButton
          variant="contained"
          color="primary"
          onClick={openCTAUrl}
          className="payment-account-success-button"
        >
          {messages.ctaButton.text}
        </StyledButton>
      </StyledButtonContainer>
    </PaymentAccountStatusDefault>
  )
}
