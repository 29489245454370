import React from 'react'
import {
  CarouselContainer,
  Content, DescriptionTexts, DurationText, Loader,
} from '@/main/pages/confirmation/loading/loading-styles'
import BewareOfTheOriginIcon from '@/main/assets/svgs/beware_of_the_origin.svg'
import NaturalFormulasIcon from '@/main/assets/svgs/natural_formulas.svg'
import NoAnimalTestingIcon from '@/main/assets/svgs/no_animal_testing.svg'
import SafeForYouAndEnvironmentIcon from '@/main/assets/svgs/safe_for_you_and_environment.svg'
import CommitmentToTheClimateIcon from '@/main/assets/svgs/commitment_to_the_climate.svg'
import EcoFriendlyPackagingIcon from '@/main/assets/svgs/eco_friendly_packaging.svg'
import { LoadingSlider } from '@/main/pages/confirmation/loading/loading-slider'
import { LoadingMessages } from '@/main/pages/confirmation/loading/loading-wrapper'
import { StatusCardContainer } from '@/main/components/status-card-container'

export type LoadingSlide = {
  translationKey: string,
  icon: string,
  name: string
}

const slidesItems : LoadingSlide[] = [
  {
    translationKey: 'slide1',
    icon: NaturalFormulasIcon,
    name: 'natural_formulas_icon',
  },
  {
    translationKey: 'slide2',
    icon: NoAnimalTestingIcon,
    name: 'no_animal_testing_icon',
  },
  {
    translationKey: 'slide3',
    icon: SafeForYouAndEnvironmentIcon,
    name: 'safe_for_you_and_environment_icon',
  },
  {
    translationKey: 'slide4',
    icon: CommitmentToTheClimateIcon,
    name: 'commitment_to_climate_icon',
  },
  {
    translationKey: 'slide5',
    icon: EcoFriendlyPackagingIcon,
    name: 'eco_friendly_packging_icon',
  },
  {
    translationKey: 'slide6',
    icon: BewareOfTheOriginIcon,
    name: 'beware_of_the_origin_icon',
  },
]

type LoadingProps ={
  messages : LoadingMessages
}

export const Loading: React.FC<LoadingProps> = ({ messages }) => (
  <StatusCardContainer>
    <Content>
      <Loader data-testid="loading-loader" />
      <DescriptionTexts
        className="description_loading_text"
        variant="subtitle2"
      >
        {messages.description.first}
      </DescriptionTexts>
      <DescriptionTexts
        className="description_loading_text"
        variant="subtitle2"
      >
        {messages.description.second}
      </DescriptionTexts>
      <DurationText variant="body2" className="duration_text">{messages.durationText}</DurationText>
    </Content>

    <CarouselContainer>
      <LoadingSlider messages={messages.carousel} slidesItems={slidesItems} />
    </CarouselContainer>
  </StatusCardContainer>
)
