import React, {
  useEffect,
  useRef,
  useState,
} from 'react'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { Page } from '@/domain/models/page/page'
import {
  DigitalSignatureMessages,
} from '@/domain/models/messages/messages'
import {
  makeSignaturePad,
  SignatureData,
} from '@/domain/models/digital-signature/signature-pad'
import { AppBar } from '@/main/components/app-bar'
import {
  Container,
  Content,
  Header,
  Main,
  Footer,
  Title,
  ClearButton,
  FooterDelimiter,
  NavigationButton,
  MainDelimiter,
  SignatureLine,
} from './signature-pad-board.styles'

interface SignaturePadBoardProps {
  setData: Function,
  onClose: Function,
}

const disabledButtonInitialState = true

export const SignaturePadBoard: React.FC<SignaturePadBoardProps> = ({
  setData,
  onClose,
}: SignaturePadBoardProps) => {
  const { signaturePadBoard } = usePageMessages(Page.DigitalSignaturePage).messages as DigitalSignatureMessages
  const canvasContainer = useRef(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)

  const [loadingScript, setLoadingScript] = useState(false)
  const [signaturePad, setSignaturePad] = useState(null)
  const [disabledButton, setDisabledButton] = useState(disabledButtonInitialState)

  useEffect(() => {
    function createScriptTag(): HTMLScriptElement {
      const scriptTag = document.createElement('script')
      scriptTag.src = 'https://aibv-public-assets.prd.naturacloud.com/agreement/seguridata/SignaturePad.js'
      scriptTag.type = 'text/javascript'
      scriptTag.async = true
      document.head.appendChild(scriptTag)

      return scriptTag
    }

    const scriptTag = createScriptTag()

    scriptTag.addEventListener('load', () => setLoadingScript(true))

    return () => {
      scriptTag.removeEventListener('load', () => setLoadingScript(true))
    }
  }, [])

  useEffect(() => {
    if (loadingScript) {
      const newSignaturePad = makeSignaturePad({
        canvas: canvasRef.current,
        brushSize: 2,
        splashText: '',
        bgColor: '#FAFAFA',
        brushColor: '#333333',
      })

      setSignaturePad(newSignaturePad)
    }
  }, [loadingScript])

  const clearSignaturePad = () => {
    signaturePad.clear()
    setDisabledButton(disabledButtonInitialState)
  }

  const getSignaturePadData = () => {
    const data = signaturePad.getData(true, false)

    const {
      timeValues,
      xValues,
      yValues,
    } = data

    const {
      current: {
        offsetHeight,
        offsetWidth,
      },
    } = canvasRef

    const signatureData: SignatureData = {
      biometricTime: timeValues,
      biometricX: xValues,
      biometricY: yValues,
      canvasHeight: offsetHeight,
      canvasWidth: offsetWidth,
      signatureImage: signaturePad.toDataURL(),
    }

    setData(signatureData)
    setDisabledButton(disabledButtonInitialState)
    onClose()
  }

  const handlePrevious = () => {
    onClose()
  }

  useEffect(() => {
    function handleResize() {
      if (signaturePad) {
        const {
          current: {
            clientWidth,
            clientHeight,
          },
        } = canvasContainer

        canvasRef.current.setAttribute('width', clientWidth.toString())
        canvasRef.current.setAttribute('height', clientHeight.toString())
        signaturePad.setSize(clientWidth, clientHeight)
      }
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [canvasRef, signaturePad])

  useEffect(() => {
    const canvas = canvasRef.current

    if (canvas && disabledButton) {
      canvas.addEventListener('mousedown', () => setDisabledButton(false))
      canvas.addEventListener('touchstart', () => setDisabledButton(false))
    }

    return () => {
      canvas.removeEventListener('mousedown', () => setDisabledButton(false))
      canvas.removeEventListener('touchstart', () => setDisabledButton(false))
    }
  }, [disabledButton])

  return (
    <Container>
      <Content>
        <AppBar color="default">
          <Header>
            <Title variant="h6">{signaturePadBoard.title}</Title>
            <ClearButton
              onClick={clearSignaturePad}
              disabled={disabledButton}
            >
              {signaturePadBoard.clearButton}
            </ClearButton>
          </Header>
        </AppBar>
        <Main>
          <MainDelimiter id="signature-container" ref={canvasContainer}>
            <SignatureLine />
            <canvas
              data-testid="signature-pad-canvas"
              id="signature-pad"
              width="100%"
              height="100%"
              ref={canvasRef}
            />
          </MainDelimiter>
        </Main>
        <Footer>
          <FooterDelimiter>
            <NavigationButton
              variant="outlined"
              size="large"
              onClick={handlePrevious}
            >
              {signaturePadBoard.previousButton}
            </NavigationButton>
            <NavigationButton
              color="primary"
              variant="contained"
              size="large"
              onClick={getSignaturePadData}
              disabled={disabledButton}
            >
              {signaturePadBoard.nextButton}
            </NavigationButton>
          </FooterDelimiter>
        </Footer>
      </Content>
    </Container>
  )
}
