import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const configs = require('./i18n.config')

const { defaultLanguage, resources } = configs

export const initI18n = (language: string) => {
   i18n
    .use(initReactI18next)
    .init({
      resources,
      lng: language,
      fallbackLng: defaultLanguage,
    })
   i18n.changeLanguage(language)

  return i18n
}
