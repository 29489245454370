import React, {
 useContext, useEffect, useState,
} from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Container } from '@/main/components/pages-structures'
import { CardHeader } from '@naturacosmeticos/natds-web/dist/Components/CardHeader'
import { Divider } from '@naturacosmeticos/natds-web/dist/Components/Divider'
import { Spacing } from '@naturacosmeticos/natds-web/dist/Components/Spacing'
import {
  ControlledTextField, GeoCommercialConfig,
  GeoCommercialStructure,
  SelectOption,
} from '@/main/components'
import { Checkbox } from '@/main/components/checkbox'
import { useAddressPageStyles } from '@/main/pages/address/address-page.styles'
import {
  FormContext,
  FormContextProps,
  IdentityContext,
  NavigationContext,
  NavigationContextProps,
} from '@/main/contexts'
import { adaptDefaultValues } from '@/main/pages/address/adapters/input-adapter'
import { AddressFormValues } from '@/main/pages/address/models'
import { CountryId } from '@/domain/models/country'
import { LegacyAddressPageProps } from '@/main/pages/address/address-page-props'
import {
  adaptFormValuesWithAnotherDeliveryAddress,
  adaptFormValuesWithDeliveryAddressCopied,
} from '@/main/pages/address/adapters/output-adapter'
import {
  makeCompleteRegistration,
} from '@/main/factories/remote/complete-registration'
import { useHistory } from 'react-router-dom'

const geoCommercialLevelsAdapter = {
  level1: 'department',
  level2: 'city',
}

type ChileGeoCommercialLevelsIds = {
  department?: SelectOption,
  city?: SelectOption,
}

type DialogMessagesType = {
  infoText: string,
  closeButtonText: string,
}

type CustomChileAddressPageProps = {
  shouldDisplayAlternativeFlowButton: boolean
}

export const ChileAddressPage: React.FC<LegacyAddressPageProps & CustomChileAddressPageProps> = ({
  messages,
  shouldDisplayAlternativeFlowButton,
}) => {
  const { person, onSubmit } = useContext<FormContextProps>(FormContext)
  const {
    goToPreviousPage = () => {},
    goToNextPage = () => {},
  } = useContext<NavigationContextProps>(NavigationContext)
  const {
    countryId,
    personId,
    businessModel,
    role,
    functionId,
    companyId,
    sourceSystem,
    authorization,
  } = useContext(IdentityContext)

  const defaultValues = adaptDefaultValues<ChileGeoCommercialLevelsIds>(person, geoCommercialLevelsAdapter)
  const formMethods = useForm<AddressFormValues & ChileGeoCommercialLevelsIds>({
    mode: 'onTouched',
    defaultValues,
  })

  // local states
  const {
    control, formState: { isValid, isSubmitting }, getValues,
  } = formMethods
  const [copyAddress, setCopyAddress] = useState<boolean>(true)

  const history = useHistory()

  useEffect(() => {
    if (!defaultValues.department) {
      return
    }
    setCopyAddress(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const formRules = { required: messages.addressRequiredError }

  const addressPageClasses = useAddressPageStyles()

  const onNextButtonClick = () => {
    const formValues = getValues()
    const parsedValue = copyAddress ? adaptFormValuesWithDeliveryAddressCopied(formValues)
       : adaptFormValuesWithAnotherDeliveryAddress(formValues, geoCommercialLevelsAdapter)
    onSubmit(parsedValue, goToNextPage)
  }

  const onAlternativeFlowButtonClick = async () => {
    const formValues = getValues()
    const parsedValue = copyAddress ? adaptFormValuesWithDeliveryAddressCopied(formValues)
       : adaptFormValuesWithAnotherDeliveryAddress(formValues, geoCommercialLevelsAdapter)

    const completeRegistration = () => makeCompleteRegistration().complete({
      countryId,
      personId,
      businessModel,
      role,
      functionId,
      companyId: String(companyId),
      sourceSystem,
      authorization,
    }).then(() => {
      history.push('/dashboard/my-registers')
    })
    .catch((err) => {
      console.error(err)
    })

    onSubmit(parsedValue, completeRegistration)
  }

  const geoCommercialConfig: GeoCommercialConfig = {
    requiredErrorMessage: messages.addressRequiredError,
    level1: {
      id: 'department',
      label: messages.geographicalLevels.level1,
      placeholder: messages.geographicalLevels.placeholders.level1,
    },
    level2: {
      id: 'city',
      label: messages.geographicalLevels.level2,
      placeholder: messages.geographicalLevels.placeholders.level2,
    },
  }
  return (
    <>
      <Container
        previousButtonLabel={messages.previousButtonLabel}
        onPreviousButtonClick={goToPreviousPage}
        nextButtonLabel={messages.nextButtonLabel}
        onNextButtonClick={onNextButtonClick}
        disableNextButton={!isValid}
        isLoading={isSubmitting}
        showAlternativeFlowButton={shouldDisplayAlternativeFlowButton}
        alternativeFlowButtonLabel={messages.alternativeFlowButtonLabel}
        onAlternativeFlowButtonClick={onAlternativeFlowButtonClick}
        disableAlternativeFlowButton={!isValid}
      >
        <CardHeader
          classes={{
            root: addressPageClasses.cardHeader,
            title: addressPageClasses.title,
            subheader: addressPageClasses.subtitle,
          }}
          title={messages.title}
          subheader={messages.subtitle}
          subheaderTypographyProps={{ variant: 'body2', color: 'textSecondary' }}
        />
        <FormProvider {...formMethods}>
          <div className={addressPageClasses.formRow}>
            <div className={`${addressPageClasses.inputFullWidth} ${addressPageClasses.inputPadRight}`}>
              <ControlledTextField
                id="street"
                label={messages.mainStreet}
                control={control}
                rules={formRules}
                placeholder={messages.placeholders.mainStreet}
                required
                showAsterisk={false}
              />
            </div>
            <div className={`${addressPageClasses.inputFullWidth} ${addressPageClasses.inputPadLeft}`}>
              <ControlledTextField
                id="number"
                label={messages.number}
                placeholder={messages.placeholders.number}
                control={control}
                rules={formRules}
                required
                showAsterisk={false}
              />
            </div>
          </div>
          <ControlledTextField
            id="complement"
            label={messages.complementaryData}
            control={control}
            placeholder={messages.placeholders.complementaryData}
          />
          <ControlledTextField
            id="references"
            label={messages.references}
            control={control}
            rules={formRules}
            placeholder={messages.placeholders.references}
            required
            showAsterisk={false}
          />
          <div className={addressPageClasses.copyAddressCheckbox}>
            <Checkbox
              color="secondary"
              label={messages.confirmDeliveryAddressAsResidenceAddress}
              checked={copyAddress}
              onChange={(e) => setCopyAddress(e.target.checked)}
            />
          </div>
          {!copyAddress && (
            <>
              <Spacing marginY="semi">
                <Divider />
              </Spacing>
              <CardHeader
                classes={{
                  root: addressPageClasses.cardHeader,
                  title: addressPageClasses.title,
                }}
                title={messages.delivery.title}
              />
              <GeoCommercialStructure customConfig={geoCommercialConfig} countryId={CountryId.Chile} />
              <div className={addressPageClasses.formRow}>
                <div className={`${addressPageClasses.inputFullWidth} ${addressPageClasses.inputPadRight}`}>
                  <ControlledTextField
                    id="deliveryStreet"
                    label={messages.mainStreet}
                    control={control}
                    rules={formRules}
                    placeholder={messages.placeholders.mainStreet}
                    required
                    showAsterisk={false}
                  />
                </div>
                <div className={`${addressPageClasses.inputFullWidth} ${addressPageClasses.inputPadLeft}`}>
                  <ControlledTextField
                    id="deliveryNumber"
                    label={messages.number}
                    placeholder={messages.placeholders.number}
                    control={control}
                    rules={formRules}
                    required
                    showAsterisk={false}
                  />
                </div>
              </div>
              <ControlledTextField
                id="deliveryComplement"
                label={messages.complementaryData}
                control={control}
                placeholder={messages.placeholders.complementaryData}
              />
              <ControlledTextField
                id="deliveryReferences"
                label={messages.references}
                control={control}
                rules={formRules}
                placeholder={messages.placeholders.references}
                required
                showAsterisk={false}
              />
            </>
          )}
        </FormProvider>
      </Container>
    </>
  )
}
