import React from 'react'
import { Tag } from '@naturacosmeticos/natds-web'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'

type TitleWithTagProps = {
  componentClasses: ClassNameMap,
  title: string,
  tag: string,
}

export const TitleWithTag: React.FC<TitleWithTagProps> = ({ componentClasses, title, tag }) => (
  <div className={componentClasses.cardHeader}>
    <p>{title}</p>
    {tag
      && (
      <Tag
        color="primary"
        id="playground"
        position="default"
        size="small"
        className={componentClasses.headerTag}
      >
        {tag}
      </Tag>
    )}
  </div>
)
