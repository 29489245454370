import { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@naturacosmeticos/natds-web'
import { deviceSize } from '@/utils/device-sizes'

export const usePhoneFieldsStyles = makeStyles<Theme>((theme) => {
  const displayEqualOrWiderThenTablet = theme.breakpoints.up(deviceSize.tablet)
  const primaryColor = theme.palette.primary.main

  return {
    formRow: {
      [displayEqualOrWiderThenTablet]: {
        display: 'inline-flex',
        width: '100%',
        justifyContent: 'space-between',
      },
    },
    title: {
      fontSize: '2.5rem',
      color: primaryColor,
    },
  }
})
