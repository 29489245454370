import React from 'react'
import ClockIcon from '@/main/assets/svgs/processing_icon_thin.svg'
import { PendingMessages } from '@/main/pages/confirmation/pending/pending-wrapper'
import {
 DescriptionTexts, Content, Anchor,
} from '@/main/pages/confirmation/status-card.styles'
import { Description, HelpDescription } from '@/main/pages/confirmation/pending/pending.styles'
import {
  colorFromStatus,
  StatusCardContainer,
} from '@/main/components/status-card-container'
import { Divider } from './pending.styles'

type PendingProps = {
  messages: PendingMessages,
  descriptionTranslationKey: string
}

export const Pending: React.FC<PendingProps> = ({ messages, descriptionTranslationKey }) => (
  <StatusCardContainer status={colorFromStatus.PRIMARY}>
    <Content>
      <img
        src={ClockIcon}
        aria-label="clock-image"
        data-testid="clock-image"
      />
      <DescriptionTexts className="bold" variant="h5" color="textSecondary">
        {messages.title}
      </DescriptionTexts>
      <DescriptionTexts variant="subtitle2">
        {messages.subtitle}
      </DescriptionTexts>
      <Description variant="body1" color="textSecondary" className="description">
        {messages[descriptionTranslationKey].description}
      </Description>
      <DescriptionTexts className="bold" variant="subtitle1" color="textSecondary">
        {messages.helpTitle}
      </DescriptionTexts>
      <Divider>
        <HelpDescription variant="body1" color="textSecondary" text={messages[descriptionTranslationKey].helpDescription} />
      </Divider>
      <Anchor
        color="default"
        component="a"
        href={messages.helpHref}
        underline="none"
        variant="body2"
        className="anchor_link"
      >
        {messages.helpAnchorText}
      </Anchor>
    </Content>
  </StatusCardContainer>
)
