import React, { useCallback, useContext, useState } from 'react'
import { Divider, Button } from '@naturacosmeticos/natds-web'

import { IdentityContext } from '@/main/contexts/identity-context'
import { AcceptTerms } from '@/main/components/accept-terms'
import { HelpInformation } from '@/main/components/help-information'

import { usePageMessages } from '@/main/hooks/usePageMessages'
import { Page } from '@/domain/models/page/page'
import { DigitalStoreStatusPendingAgreementsMessages } from '@/domain/models/messages/messages'
import { DigitalStoreCurrentStatus } from '@/domain/models/digital-store'
import { useTenantVendor } from '@/main/hooks/useTenantVendor'
import { ProgressIndicator } from '@/main/components/progress-indicator'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import { makeRemoteAcceptAgreement } from '@/main/factories/remote/agreement'
import { DigitalStoreAddressPanel } from './components/digital-store-status/digital-store-address-panel'
import { DigitalStoreStatusFooter } from './components/digital-store-status/digital-store-status-footer'
import { DigitalStoreStatusBaseCard } from './components/digital-store-status/digital-store-status-base-card'

import { AgreementsPanelContainer } from './digital-store-status-page.styles'

export const DigitalStoreStatusPendingAgreements = ({
  digitalStoreStatus, isLoading, hasDigitalStoreApproved, onSave, hasCustomFlowAgreementsEnabled,
}) => {
  const status: DigitalStoreCurrentStatus = digitalStoreStatus?.status || DigitalStoreCurrentStatus.NOT_STARTED
  const {
    countryId, personId, tenantId, companyId,
  } = useContext(IdentityContext)
  const vendor = useTenantVendor(tenantId)
  const pageMessagesOptions = { vendorName: vendor.name }
  const messages = usePageMessages(Page.DigitalStoreStatusPendingAgreements, pageMessagesOptions).messages as DigitalStoreStatusPendingAgreementsMessages
  const statusMessages = messages.statusMessages[status]
  const configs = useTenantConfigs(tenantId, Page.DigitalStore)

  const [isAgreementsAccepted, setIsAgreementsAccepted] = useState(false)
  const [userNeedsToAcceptTerms, setUserNeedsToAcceptTerms] = useState(true)
  const [agreementId, setAgreementsId] = useState<string[]>([])
  const [isAcceptingTerms, setIsAcceptingTerms] = useState(false)
  const extraEntries = {}
  const [errorOnSave, setErrorOnSave] = useState(false)

  const getDigitalStoreAddressPanel = useCallback(() => {
    const digitalStoreDomain = `${messages.digitalStoreUrl}/${digitalStoreStatus?.digitalStoreName}`
    return (
      <DigitalStoreAddressPanel disableLink digitalStoreDomain={digitalStoreDomain} digitalStoreAddressText={messages.digitalStoreDigitalSpaceAddress} />
    )
  }, [
    digitalStoreStatus?.digitalStoreName,
    messages.digitalStoreDigitalSpaceAddress,
    messages.digitalStoreUrl,
  ])

  const delay = (size: number) => new Promise((resolve) => {
    setTimeout(() => resolve(null), size)
  })

  const getCustomFlowAgreementsContent = useCallback(() => {
    const onClickAcceptAgreements = async (agreementId) => {
      setIsAcceptingTerms(true)
      setErrorOnSave(false)
      try {
        const remoteAcceptAgreement = makeRemoteAcceptAgreement()
        await remoteAcceptAgreement.accept({ agreementId, personId })
        await delay(4500)
        onSave()
      } catch (error) {
        setErrorOnSave(true)
      } finally {
        setIsAcceptingTerms(false)
      }
    }

    return (
      <>
        <AgreementsPanelContainer data-testid="agreements-panel-container">
          <Divider />
          <AcceptTerms
            personId={personId}
            countryId={countryId}
            companyId={companyId}
            products={configs.agreement.products}
            setAgreementId={setAgreementsId}
            setUserNeedsToAcceptTerms={setUserNeedsToAcceptTerms}
            shouldHide={!userNeedsToAcceptTerms}
            termsInfo={messages.digitalStoreTermsInfo}
            dialogInfo={{
              title: messages.digitalStoreTermsAcceptanceModalTitle,
              acceptText: messages.digitalStoreTermsAcceptanceModalAccept,
              closeText: messages.digitalStoreTermsAcceptanceModalClose,
            }}
            checkbox={{
              color: 'secondary',
              normalLabel: messages.digitalStoreTermsAcceptance,
              highlightedLabel: messages.digitalStoreTermsAcceptanceLink,
              value: isAgreementsAccepted,
              onChange: (hasAcceptedAgreement) => setIsAgreementsAccepted(hasAcceptedAgreement),
            }}
          />
          <Button
            color="primary"
            size="large"
            variant="contained"
            disabled={!isAgreementsAccepted || isAcceptingTerms}
            onClick={() => onClickAcceptAgreements(agreementId[0])}
            data-testid="accept-terms-button"
          >
            {
              isAcceptingTerms ? (
                <ProgressIndicator
                  data-testid="accept-terms-loading"
                  size={24}
                />
              ) : messages.digitalStoreTermsAcceptanceButton
            }
          </Button>
          {errorOnSave && (
            <p style={{ color: 'red', fontSize: '2em', marginTop: 0 }}>{messages.errorOnSave}</p>
          )}
          <Divider />
        </AgreementsPanelContainer>
      </>
    )
  }, [personId, countryId, configs.agreement.products, userNeedsToAcceptTerms, messages.digitalStoreTermsInfo, messages.digitalStoreTermsAcceptanceModalTitle, messages.digitalStoreTermsAcceptanceModalAccept, messages.digitalStoreTermsAcceptanceModalClose, messages.digitalStoreTermsAcceptance, messages.digitalStoreTermsAcceptanceLink, messages.digitalStoreTermsAcceptanceButton, isAgreementsAccepted, isAcceptingTerms, onSave, extraEntries, agreementId])

  return (
    <DigitalStoreStatusBaseCard
      isLoading={isLoading}
      statusMessage={statusMessages.description}
      title={statusMessages.title}
      hasDigitalStoreApproved={hasDigitalStoreApproved}
    >
      {getDigitalStoreAddressPanel()}
      {hasCustomFlowAgreementsEnabled && getCustomFlowAgreementsContent()}
      <HelpInformation
        title={messages.contactInfoHelpTitle}
        content={messages.contactInfoHelp}
      />
      {statusMessages?.footer && <DigitalStoreStatusFooter content={statusMessages.footer} />}
    </DigitalStoreStatusBaseCard>
  )
}
