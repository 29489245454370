import React from 'react'
import { Card, Typography, Link } from '@naturacosmeticos/natds-web'
import { Divider } from '@naturacosmeticos/natds-web/dist/Components/Divider'
import { Icon } from '@/main/components'
import { useCustomCardStyles } from './custom-card.styles'

type CustomCardProps = {
  title: string
  className?: string
  children: React.ReactNode,
  editButtonName?: string,
  editButtonLink?: string,
}

export const CustomCard = ({
  title,
  className,
  children,
  editButtonName,
  editButtonLink,
}: CustomCardProps) => {
  const classComponent = useCustomCardStyles()
  return (
    <Card className={`${classComponent.container} ${className}`}>
      <div className={classComponent.titleContainer}>
        <Typography variant="subtitle1" className={classComponent.title}>
          {title}
        </Typography>
        {
          (editButtonName && editButtonLink)
          && (
            <Link
              href={editButtonLink}
              underline="none"
              color="textPrimary"
              className={classComponent.editButton}
            >
              <Typography variant="body1">
                {editButtonName}
              </Typography>
              <Icon
                name="outlined-action-edit"
                size="tiny"
                className={classComponent.editIcon}
              />
            </Link>
          )
        }
      </div>

      <Divider />

      {children}
    </Card>
  )
}
