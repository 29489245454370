import { BadRequestError } from '@/data/protocols/errors'
import { HttpPatchClient, HttpStatusCode } from '@/data/protocols/http'
import { UnexpectedError } from '@/domain/errors'
import { CompleteAttachment, CompleteAttachmentHeader, CompleteAttachmentParams } from '@/domain/use-cases/attachment'

export class RemoteCompleteAttachment implements CompleteAttachment {
  constructor(
    private readonly httpClient: HttpPatchClient<CompleteAttachmentHeader>,
    private readonly xApiKey: string,
  ) {}

  async complete(params:CompleteAttachmentParams): Promise<void> {
    const {
 personId, businessModel, functionId, tenantId, role,
} = params
    const url = `/attachment/complete/${personId}`
    const headers: CompleteAttachmentHeader = {
      businessmodel: businessModel,
      functionid: functionId,
      tenantid: tenantId,
      'x-api-key': this.xApiKey,
      role,
    }

    const response = await this.httpClient.patch({ url, headers })
    this.handleResponse(response.statusCode)
  }

  private handleResponse(statusCode: HttpStatusCode) {
    switch (statusCode) {
      case HttpStatusCode.noContent:
        return
      case HttpStatusCode.badRequest:
        throw new BadRequestError(null)
      default:
        throw new UnexpectedError()
    }
  }
}
