import React from 'react'
import { ControllerProps, useFormContext } from 'react-hook-form'
import { ControlledTextField, Icon } from '@/main/components'
import { Mask } from '@naturacosmeticos/natds-web/dist/Components/Input/Input.props'
import { getOnlyNumbers } from '@/main/pages/register-maintenance/commons/common-fuctions'

type PhoneOptions = {
  maxLength: number,
  minLength: number,
}

type PhoneNumberMessages = {
  label: string
  placeholder?: string
  requiredErrorMessage: string
  firstDigitErrorMessage: string
  lengthErrorMessage: string
}

type PhoneNumberProps = {
  messages: PhoneNumberMessages,
  id: string,
  name: string,
  mask?: Mask,
  options: PhoneOptions
} & Pick<ControllerProps<'input'>, 'rules' | 'control'>

export const PhoneNumber:React.FC<PhoneNumberProps> = ({
  messages,
  id,
  mask,
  options,
}: PhoneNumberProps) => {
  const { control, errors } = useFormContext()
  const { maxLength, minLength } = options

  const lengthValidation = (value: string, maxLength: number, minLength: number): boolean => {
    const onlyDigits = getOnlyNumbers(value)
    return onlyDigits.length <= maxLength && onlyDigits.length >= minLength
  }

  const validatePhoneLength = (maxLength, minLength) => (value) => lengthValidation(value, maxLength, minLength)

  return (
    <ControlledTextField
      id={id}
      label={messages.label}
      aria-label={messages.label}
      control={control}
      placeholder={messages.placeholder}
      rules={
        {
          required: messages.requiredErrorMessage,
          validate: {
            length: validatePhoneLength(maxLength, minLength),
          },
        }
      }
      errorMessage={{
        firstDigit: messages.firstDigitErrorMessage,
        length: messages.lengthErrorMessage,
      }}
      errors={errors}
      mask={mask}
      required
      icon={<Icon name="outlined-action-phone" size="small" />}
    />
  )
}
