import { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@naturacosmeticos/natds-web/dist/styles/makeStyles'

export const useConsultantDataStyles = makeStyles<Theme>((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    position: 'relative',
  },
  editDiv: {
    position: 'absolute',
    top: theme.spacing(4),
    right: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  editIcon: {
    paddingLeft: theme.spacing(1),
  },
  editButton: {
    display: 'flex',
    textTransform: 'uppercase',
  },
  typographyName: {
    paddingBottom: theme.spacing(0),
    textTransform: 'capitalize',
  },
  typographyNickname: {
    paddingBottom: theme.spacing(0.5),
    textTransform: 'capitalize',
  },
  typographyMessageNickname: {
    paddingBottom: theme.spacing(0.5),
    color: '#BBBBBB',
  },
}))
