import { HttpGetClient, HttpStatusCode } from '@/data/protocols/http'
import { BadRequestError, NotFoundError } from '@/data/protocols/errors'
import { UnexpectedError } from '@/domain/errors'
import { CountryId } from '@/domain/models/country'
import { CompanyId } from '@/domain/models/company/company-id'
import { PersonData } from '@/domain/use-cases/person/get-person-data'

export type GetMyProfileHeaders = {
  countryid: CountryId,
  companyid: CompanyId,
  'x-api-key': string,
}

export const myProfileRelations: string[] = ['emails', 'telephones', 'additionalInformation',
'addresses', 'approvedCandidate']

export type GetMyProfileParams = {
  countryid: CountryId,
  companyid: CompanyId,
  personId: string,
}

export class RemoteGetMyProfile {
  constructor(
    private readonly httpGetClient: HttpGetClient<GetMyProfileHeaders, PersonData>,
    private readonly xApiKey: string,
  ) {}

  get = async ({
    countryid,
    companyid,
    personId,
  }: GetMyProfileParams): Promise<PersonData> => {
    const headers: GetMyProfileHeaders = {
      countryid,
      companyid,
      'x-api-key': this.xApiKey,
    }

    const httpResponse = await this.httpGetClient.get({
      url: `/people/${personId}?relations=${myProfileRelations}`,
      headers,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
        return httpResponse.body
      case HttpStatusCode.badRequest:
        throw new BadRequestError(httpResponse.body)
      case HttpStatusCode.notFound:
        throw new NotFoundError(httpResponse.body)
      default:
        throw new UnexpectedError()
    }
  }
}
