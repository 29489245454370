import React, {
  useContext, useEffect, useState,
} from 'react'
import { Container } from '@/main/components/pages-structures'
import { Icon, GridContainer, GridItem, Typography } from '@naturacosmeticos/natds-web'
import { Checkbox } from '@/main/components/checkbox'
import { FormProvider, useForm } from 'react-hook-form'
import { IdentityContext, NavigationContext, NavigationContextProps } from '@/main/contexts'
import { Page } from '@/domain/models/page/page'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { MaintenanceAddressMessages } from '@/domain/models/messages/messages'
import { useHistory } from 'react-router-dom'
import { ControlledAddressNumberField, ControlledSelect, ControlledTextField, ZipCodeTextField } from '@/main/components'
import { LevelName } from '@/domain/models/address/address-levels'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import { AddressConfigs } from '@/domain/models/tenant/tenant-configs'
import { ADDRESS_DELIVERY_TYPE } from '@/domain/models/address/address-delivery-type'
import { AddressPageForms } from '@/data/use-cases/register-maintenance/remote-get-multiple-address'
import { AddressSavePageForms, SaveAddressParams } from '@/data/use-cases/register-maintenance/remote-save-address'
import { getOnlyNumbers } from '@/main/pages/register-maintenance/commons/common-fuctions'
import { useMaintenanceAddressPage } from './maintenance-address-page.styles'
import { AddressMessages } from '../address/models'
import { RegisterMaintenanceAddressPageApi } from './api/make-register-maintenance-address-page-api'
import { MaintenanceDialog } from '../register-maintenance/fields/maintenance-dialog'
import { DialogWithLoader } from '../register-maintenance/commons/components/dialog-with-loader/dialog-with-loader'

export type MaintenanceAddressPageProps = {
  api: RegisterMaintenanceAddressPageApi
}

type DialogState = {
  message?: string
  isOpen: boolean
  confirmButtonName?: string
  cancelButtonName?: string
  confirmationButtonCallback?: () => void
  cancelButtonCallback?: () => void
  component?
}

type FormAddress = {
  city: string
  complement: string
  neighborhood: string
  number: string
  references: string
  state: string
  street: string
  zipCode: string
}

export const MaintenanceAddressPage: React.FC<MaintenanceAddressPageProps> = ({ api }) => {

  const {
    personId,
    countryId,
    companyId,
  } = useContext(IdentityContext)
  const { tenantId } = useContext(IdentityContext)
  const {
    goToPreviousPage,
  } = useContext<NavigationContextProps>(NavigationContext)

  const componentClasses = useMaintenanceAddressPage()

  const history = useHistory()

  const addressMessages = usePageMessages(Page.Address).messages as AddressMessages

  const [deliveryTypeLabel, setDeliveryTypeLabel] = useState<string>('')

  const createMessageOptions = (label) => ({ addressDeliveryType: label })
  const maintenanceAddressMessages = usePageMessages(Page.MaintenanceAddress, createMessageOptions(deliveryTypeLabel)).messages as MaintenanceAddressMessages

  const formMethods = useForm<FormAddress>({
    mode: 'onTouched',
  })

  const [addressModalStatus, setAddressModalStatus] = useState<DialogState>({ message: '', isOpen: false })
  const [openAddressSubmitLoading, setAddressOpenSubmitLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const [savedAddress, setSavedAddress] = useState<AddressPageForms>()
  const [receiveBillingAtSameAddress, setReceiveBillingAtSameAddress] = useState<boolean>(false)

  const { zipCode, number } = useTenantConfigs(
    tenantId,
    Page.Address
  ) as AddressConfigs

  const formRules = { required: addressMessages.addressRequiredError }

  const {
    getValues,
    control,
    reset,
    formState: { isValid },
  } = formMethods

  const getDeliveryTypeAddress = (deliveryType: string) => {
    let deliveryName

    switch (ADDRESS_DELIVERY_TYPE[deliveryType]) {
      case ADDRESS_DELIVERY_TYPE.DELIVERY_ADDRESS:
        deliveryName = addressMessages.addressDeliveryType.delivery
        break
      case ADDRESS_DELIVERY_TYPE.MAILING_ADDRESS:
        deliveryName = addressMessages.addressDeliveryType.mailing
        break
      case ADDRESS_DELIVERY_TYPE.BILLING_ADDRESS:
        deliveryName = addressMessages.addressDeliveryType.billing
        break
      default:
        deliveryName = ''
    }

    return deliveryName
  }

  useEffect(() => {
    setAddressModalStatus({
      isOpen: true,
      component: () => leaderChangeAlert(componentClasses, maintenanceAddressMessages.leaderDialog),
      confirmButtonName: maintenanceAddressMessages.leaderDialog.confirmButton,
      cancelButtonName: maintenanceAddressMessages.leaderDialog.cancelButton,
      confirmationButtonCallback: onCloseWithoutPreviousPage,
      cancelButtonCallback: onClose
    })
    api.getMultipleAddresses({ personId, countryId, companyId })
      .then(({ deliveryAddress: address }) => {
        setDeliveryTypeLabel(getDeliveryTypeAddress(ADDRESS_DELIVERY_TYPE[address.addressUse]))
        setSavedAddress(address)
        reset({
          city: address.city,
          complement: address.complement,
          neighborhood: address.neighborhood,
          number: address.number || maintenanceAddressMessages.withoutNumber,
          references: address.references,
          state: address.state,
          street: address.street,
          zipCode: address.zipCode.replace(zipCode.regex, zipCode.regexOption),
        })
      }).catch(() => {
        console.warn('Error on get addresses')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [api, companyId, countryId, personId, reset])

  const actionMessages = {
    saveButton: maintenanceAddressMessages.labels.saveButton,
    previousButton: maintenanceAddressMessages.labels.previousButton,
    dialogButtonName: maintenanceAddressMessages.dialog.buttonName,
    unexpectedErrorDialogMessage: maintenanceAddressMessages.dialog.unexpectedErrorMessage,
    successDialogMessage: maintenanceAddressMessages.dialog.successMessage,
  }

  const goBackPreviousPage = () => (goToPreviousPage ? goToPreviousPage() : history.goBack())

  const onClose = () => {
    setAddressModalStatus({ isOpen: false })
    goBackPreviousPage()
  }

  const onCloseWithoutPreviousPage = () => {
    setAddressModalStatus({ isOpen: false })
  }

  const mapToAddressPageForms = (address: FormAddress): AddressSavePageForms => {
    return {
      city: address.city,
      complement: address.complement,
      country: savedAddress.country,
      number: address.number,
      neighborhood: address.neighborhood,
      references: address.references,
      state: address.state,
      street: address.street,
      zipCode: getOnlyNumbers(address.zipCode),
      addressType: savedAddress.addressType,
      addressUse: savedAddress.addressUse,
      sequence: savedAddress.sequence,
      receiveBillingAtSameAddress,
    }
  }

  const handleSubmit = () => {
    try {

      setAddressOpenSubmitLoading(true)

      const updatedAddressInfo: SaveAddressParams = {
        personId,
        companyId,
        countryId,
        address: mapToAddressPageForms(getValues()),
      }

      api.saveAddress(updatedAddressInfo)
        .then(() => {
          setAddressModalStatus({
            message: actionMessages.successDialogMessage, isOpen: true, confirmationButtonCallback: onClose, confirmButtonName: actionMessages.dialogButtonName
          })
        })
        .catch(() => {
          setAddressModalStatus({
            message: actionMessages.unexpectedErrorDialogMessage, isOpen: true, confirmationButtonCallback: onCloseWithoutPreviousPage, confirmButtonName: actionMessages.dialogButtonName
          })
        })
        .finally(() => {
          setAddressOpenSubmitLoading(false)
        })
    } catch (e) {
      setAddressModalStatus({
        message: actionMessages.unexpectedErrorDialogMessage, isOpen: true, confirmationButtonCallback: onCloseWithoutPreviousPage, confirmButtonName: actionMessages.dialogButtonName
      })
      setAddressOpenSubmitLoading(false)
    }
  }

  return (
    <Container
      nextButtonLabel={actionMessages.saveButton}
      disableNextButton={!isValid}
      onNextButtonClick={handleSubmit}
      previousButtonLabel={actionMessages.previousButton}
      onPreviousButtonClick={goBackPreviousPage}
      nextButtonTextInline
      isLoading={isLoading}
    >
      <div className={componentClasses.container}>
        <Typography
          variant="h6"
          color="textPrimary"
          className={componentClasses.title}
        >
          <span data-testid={deliveryTypeLabel}>
            {maintenanceAddressMessages.address}
          </span>
        </Typography>
        <FormProvider {...formMethods}>
          {zipCode && (
            <ZipCodeTextField
              id={LevelName.ZipCode}
              label={addressMessages.zipCode.label}
              placeholder={addressMessages.zipCode.placeholder}
              link={{
                show: zipCode.shouldDisplayLink({ authenticated: false }),
                text: addressMessages.zipCode.link.text,
                url: addressMessages.zipCode.link.url,
              }}
              rules={{
                minLength: {
                  message: addressMessages.zipCode.errorMessage,
                  value: zipCode.rules.maxLength,
                },
                maxLength: {
                  message: addressMessages.zipCode.errorMessage,
                  value: zipCode.rules.maxLength,
                },
                required: {
                  value: true,
                  message: addressMessages.zipCode.requiredErrorMessage,
                },
              }}
              validChars={zipCode.rules.pattern}
              control={control}
              mask={zipCode.mask}
              required
              showAsterisk
            />
          )}
          <ControlledTextField
            id={LevelName.Street}
            label={addressMessages.mainStreet}
            control={control}
            rules={formRules}
            placeholder={addressMessages.placeholders.mainStreet}
            required
          />
          <GridContainer className="natds1" justify="space-between" spacing={3}>
            <GridItem className="natds2" md={6} sm={6} xs={6}>
              <div className={componentClasses.addressNumberField}>
                <ControlledAddressNumberField
                  id={LevelName.Number}
                  label={addressMessages.number}
                  placeholder={addressMessages.placeholders.number}
                  rules={{
                    required: {
                      message: addressMessages.addressRequiredError,
                      value: true,
                    },
                  }}
                  noNumberLabel={addressMessages.checkBoxWithoutNumber}
                  validChars={number.validChars}
                  hasNoNumberCheckbox={number?.hasNoNumberCheckbox}
                />
              </div>
            </GridItem>
            <GridItem className="natds2" md={6} sm={6} xs={6}>
              <ControlledTextField
                id={LevelName.Complement}
                label={addressMessages.complementaryData}
                control={control}
                placeholder={addressMessages.placeholders.complementaryData}
              />
            </GridItem>
          </GridContainer>
          <ControlledTextField
            id={LevelName.Neighborhood}
            label={addressMessages.neighborhood}
            control={control}
            rules={formRules}
            placeholder={addressMessages.placeholders.neighborhood}
            required
          />
          <GridContainer className="natds1" justify="space-between" spacing={3}>
            <GridItem className="natds2" md={6} sm={6} xs={6}>
              <ControlledTextField
                id={LevelName.City}
                label={addressMessages.city}
                control={control}
                rules={formRules}
                placeholder={addressMessages.placeholders.city}
                required
              />
            </GridItem>
            <GridItem className="natds2" md={6} sm={6} xs={6}>
              <ControlledTextField
                id={LevelName.State}
                label={addressMessages.state}
                control={control}
                rules={formRules}
                placeholder={addressMessages.placeholders.state}
                required
              />
            </GridItem>
          </GridContainer>
          <ControlledTextField
            id={LevelName.References}
            label={addressMessages.references}
            control={control}
            rules={formRules}
            placeholder={addressMessages.placeholders.references}
            required
          />
          <div className={componentClasses.displayNone}>
            <ControlledSelect
              id={LevelName.addressDeliveryType}
              label={maintenanceAddressMessages.labels.addressDeliveryType}
              aria-label={maintenanceAddressMessages.labels.addressDeliveryType}
              rules={formRules}
              control={control}
              options={[{ label: addressMessages.addressDeliveryType.delivery, value: ADDRESS_DELIVERY_TYPE.DELIVERY_ADDRESS }]}
              disabled
              defaultValue={{ label: addressMessages.addressDeliveryType.delivery, value: ADDRESS_DELIVERY_TYPE.DELIVERY_ADDRESS }}
              hasAsteriskInTheLabel
            />
          </div>
          <div className={componentClasses.displayNone}>
            <Checkbox
              color="secondary"
              label={addressMessages.confirmDeliveryAddressAsResidenceAddress}
              checked={receiveBillingAtSameAddress}
              onChange={(e) => setReceiveBillingAtSameAddress(e.target.checked)}
            />
          </div>
        </FormProvider>
        <MaintenanceDialog
          message={addressModalStatus.message}
          confirmButtonName={addressModalStatus.confirmButtonName}
          cancelButtonName={addressModalStatus.cancelButtonName}
          open={addressModalStatus.isOpen}
          onClickConfirmButton={addressModalStatus.confirmationButtonCallback}
          onClickCancelButton={addressModalStatus.cancelButtonCallback}
          Children={addressModalStatus.component}
        />
        <DialogWithLoader isOpen={openAddressSubmitLoading} />
      </div>
    </Container>
  )
}

const leaderChangeAlert = (componentClasses, messages) => (
  <div className={componentClasses.dialogLeader}>
    <Typography variant="h6" className={componentClasses.dialogLeaderTitle}>
      {messages.title}
    </Typography>
    <span className={componentClasses.dialogLeaderItem}>
      <Icon
        data-testid="status-icon"
        name="outlined-action-requestspecial"
        size="tiny"
        className={componentClasses.dialogLeaderIcon}
      />
      <Typography variant="subtitle1">
        {messages.alertRequestProcess}
      </Typography>
    </span>
    <span className={componentClasses.dialogLeaderItem}>
      <Icon
        data-testid="status-icon"
        name="outlined-social-digitalconsultant"
        size="tiny"
        className={componentClasses.dialogLeaderIcon}
      />
      <Typography variant="subtitle1">
        {messages.leaderChange}
      </Typography>
    </span>
    <Typography variant="subtitle1">
      {messages.confirmation}
    </Typography>
  </div>
)
