import { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@naturacosmeticos/natds-web'

export const useDialogWithLoaderStyles = makeStyles<Theme>(() => ({
  dialogContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '52px',
    margin: '35px',
    '& svg': {
      width: '48px !important',
    },
  },
}))
