import { Loading } from '@/main/pages/confirmation/loading'
import React from 'react'
import { useI18n } from '@/main/hooks/useI18n'

type CarouselMessages = {
  slide1: {
    title: string,
    description: string,
  },
  slide2: {
    title: string,
    description: string,
  },
  slide3: {
    title: string,
    description: string,
  },
  slide4: {
    title: string,
    description: string,
  },
  slide5: {
    title: string,
    description: string,
  },
  slide6: {
    title: string,
    description: string,
  },
}

export type LoadingMessages = {
  description: {
    first: string,
    second: string
  },
  durationText: string,
  carousel: CarouselMessages
}

type LoadingWrapperProps = {
  duration: number
}

export const LoadingWrapper: React.FC<LoadingWrapperProps> = ({ duration }) => {
  const { translate } = useI18n()

  const messages: LoadingMessages = {
    description: {
      first: translate('completedPage.loading.description.first'),
      second: translate('completedPage.loading.description.second'),
    },
    durationText: translate('completedPage.loading.durationText', { duration }),
    carousel: {
      slide1: {
        title: translate('completedPage.loading.carousel.slide1.title'),
        description: translate('completedPage.loading.carousel.slide1.description'),
      },
      slide2: {
        title: translate('completedPage.loading.carousel.slide2.title'),
        description: translate('completedPage.loading.carousel.slide2.description'),
      },
      slide3: {
        title: translate('completedPage.loading.carousel.slide3.title'),
        description: translate('completedPage.loading.carousel.slide3.description'),
      },
      slide4: {
        title: translate('completedPage.loading.carousel.slide4.title'),
        description: translate('completedPage.loading.carousel.slide4.description'),
      },
      slide5: {
        title: translate('completedPage.loading.carousel.slide5.title'),
        description: translate('completedPage.loading.carousel.slide5.description'),
      },
      slide6: {
        title: translate('completedPage.loading.carousel.slide6.title'),
        description: translate('completedPage.loading.carousel.slide6.description'),
      },
    },
  }

  return <Loading messages={messages} />
}
