import React from 'react'
import { ControlledSelect } from '@/main/components'
import { FieldsOrderProps } from '@/domain/models/tenant/tenant-configs/configs'

export const CologneField = (fieldsOrderProps: FieldsOrderProps) => {
  const { messages, configs, control, address } = fieldsOrderProps
  return (
    <>
      <ControlledSelect
        id="cologne"
        label={messages.cologne.label}
        placeholder={messages.cologne.placeholder}
        rules={{
          maxLength: {
            message: messages.cologne.rules.maxLength,
            value: configs.cologne.rules.maxLength,
          },
          required: {
            message: messages.cologne.rules.required,
            value: configs.cologne.rules.required,
          },
        }}
        control={control}
        options={address?.cologne?.map((option) => {
          return {
            label: option,
            value: option,
          }
        })}
      />
    </>
  )
}
