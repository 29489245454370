import React, { useContext } from 'react'
import { Card, LetterAvatar } from '@/main/components'
import {
  GridContainer, GridItem, Typography, Link, Icon,
} from '@naturacosmeticos/natds-web'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import { PersonDataResponse } from '@/domain/use-cases/person'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { MyProfileMessages } from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import { IdentityContext } from '@/main/contexts'
import { ProfileConfigs } from '@/domain/models/tenant/tenant-configs'
import { useConsultantDataStyles } from './consultant-data.styles'

export type ConsultantDataProps = {
  personData: PersonDataResponse
}

const getFirstTwoNames = (name: string) => name.split(' ').slice(0, 2).join(' ')

export const ConsultantData: React.FC<ConsultantDataProps> = ({ personData: { person } }) => {
  const {
    name, nickname, naturaCode, personId,
  } = person
  const componentClasses = useConsultantDataStyles()
  const { consultantData, editButton: editButtonName } = usePageMessages(Page.MyProfile).messages as MyProfileMessages
  const {
    tenantId,
  } = useContext(IdentityContext)
  const {
    consultantData: {
      editUrl,
    },
  } = useTenantConfigs(tenantId, Page.MyProfile) as ProfileConfigs
  const editButtonLink = `${editUrl}${personId}`

  return (
    <Card data-testid="consultant-data" className={componentClasses.container}>
      <GridContainer spacing={2}>
        <GridItem className="natds2" lg={1} md={12} sm={12} xs={12}>
          <LetterAvatar size="huge" variant="h4" name={getFirstTwoNames(name)} />
        </GridItem>
        <GridItem lg={11} md={12} sm={12} xs={12}>
          {
            nickname ? <Typography className={componentClasses.typographyNickname} variant="h5">{nickname?.toLowerCase()}</Typography> :
            <Typography className={componentClasses.typographyMessageNickname} variant="h5">{consultantData.enterSocialName}</Typography>
          }
          <Typography className={componentClasses.typographyName} variant="subtitle1">{name.toLowerCase()}</Typography>
          <Typography variant="body2" color="textSecondary">
            {consultantData.naturaCode}
            :&nbsp;
            {naturaCode}
          </Typography>
        </GridItem>
      </GridContainer>
      <div className={componentClasses.editDiv}>
        {
          (editButtonName && editButtonLink)
          && (
            <Link
              href={editButtonLink}
              underline="none"
              color="textPrimary"
              className={componentClasses.editButton}
            >
              <Typography variant="body1">
                {editButtonName}
              </Typography>
              <Icon
                name="outlined-action-edit"
                size="tiny"
                className={componentClasses.editIcon}
              />
            </Link>
          )
        }
      </div>

    </Card>
  )
}
