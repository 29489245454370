import { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@naturacosmeticos/natds-web'

export const useMaintenanceAddressPage = makeStyles<Theme>((theme) => ({
  title: {
    marginBottom: theme.spacing(3),
  },
  container: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  addressNumberField: {
    '& > div > div ': {
      paddingBottom: theme.spacing(0.5),
    },
  },
  displayNone: {
    display: 'none',
  },
  dialogLeader: {
    display: 'flex',
    flexFlow: 'column',
  },
  dialogLeaderTitle: {
    marginBottom: theme.spacing(4),
  },
  dialogLeaderItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  dialogLeaderIcon: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    marginRight: theme.spacing(2.5),
    padding: theme.spacing(0.5),
  }
}))
