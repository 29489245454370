import React, { useContext } from 'react'
import styled from 'styled-components'
import { HelpInformation } from '@/main/components/help-information'
import { DigitalStoreStatusWarningPanel } from '@/main/pages/digital-store/pages/digital-store-status-page/components/digital-store-status/digital-store-status-warning-panel'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { Page } from '@/domain/models/page/page'
import { DigitalStoreStatusPaymentMessages } from '@/domain/models/messages/messages'
import { DigitalStoreCurrentStatus } from '@/domain/models/digital-store'
import { IdentityContext } from '@/main/contexts'
import { useTenantVendor } from '@/main/hooks/useTenantVendor'
import { Button } from '@naturacosmeticos/natds-web'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import { DigitalStoreConfigs } from '@/domain/models/tenant/tenant-configs'
import { DigitalStoreAddressPanel } from './components/digital-store-status/digital-store-address-panel'
import { DigitalStoreStatusFooter } from './components/digital-store-status/digital-store-status-footer'

import { DigitalStoreStatusBaseCard } from './components/digital-store-status/digital-store-status-base-card'

const DigitalStoreAddressContainer = styled.span`
  margin-bottom: 4rem;
`
const DigitalStoreButtonContainer = styled.span`
margin-bottom: 2rem;
`

export const DigitalStoreStatusPayment = ({
 isLoading, digitalStoreStatus, hasDigitalStoreApproved, setShouldRenderDigitalStorePage,
}) => {
  const { tenantId } = useContext(IdentityContext)
  const vendor = useTenantVendor(tenantId)
  const pageMessagesOptions = { vendorName: vendor.name }
  const status: DigitalStoreCurrentStatus = digitalStoreStatus?.status || DigitalStoreCurrentStatus.NOT_STARTED
  const messages = usePageMessages(Page.DigitalStoreStatusPayment, pageMessagesOptions).messages as DigitalStoreStatusPaymentMessages
  const digitalStoreDomain = `${messages.digitalStoreUrl}/${digitalStoreStatus?.digitalStoreName}`
  const statusMessages = messages.statusMessages[status]
  const LinkingSteps = vendor.linkingSteps

  const {
    digitalStoreTryCreateAgain,
  } = useTenantConfigs(tenantId, Page.DigitalStore) as DigitalStoreConfigs

  const redirectCreateDigitalStorePage = () => setShouldRenderDigitalStorePage(true)

  return (
    <DigitalStoreStatusBaseCard
      isLoading={isLoading}
      statusType={statusMessages?.type}
      statusMessage={statusMessages?.message}
      title={statusMessages?.title}
      subtitle={statusMessages?.subtitle}
      hasDigitalStoreApproved={hasDigitalStoreApproved}
    >
      {statusMessages?.warning?.title && statusMessages?.warning?.message && <DigitalStoreStatusWarningPanel title={statusMessages.warning.title} content={statusMessages.warning?.message} />}
      {hasDigitalStoreApproved && (
        <DigitalStoreAddressContainer>
          <DigitalStoreAddressPanel digitalStoreDomain={digitalStoreDomain} digitalStoreAddressText={messages.digitalStoreDigitalSpaceAddress} />
        </DigitalStoreAddressContainer>
      )}
      {status === 'CEASED' && digitalStoreTryCreateAgain && (
        <DigitalStoreButtonContainer>
          <Button onClick={redirectCreateDigitalStorePage} color="primary" variant="contained">
            {messages.digitalStoreTryCreateAgain}
          </Button>
        </DigitalStoreButtonContainer>
      )}

      <LinkingSteps />
      <HelpInformation
        title={messages.contactInfoHelpTitle}
        content={messages.contactInfoHelp}
      />
      {statusMessages?.footer && <DigitalStoreStatusFooter content={statusMessages.footer} />}
    </DigitalStoreStatusBaseCard>
  )
}
