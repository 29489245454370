import { PersonInfoInterface } from '@/data/use-cases/indication/remote-search-indication'
import { CompletePersonalDataParams, DataForm } from '@/data/use-cases/personal-data/remote-complete-personal-data'
import { PersonalDataMessages } from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import { PersonalDataForm } from '@/domain/models/personal-data/personal-data'
import {
  AddressConfigs,
  PersonalDataConfigs
} from '@/domain/models/tenant/tenant-configs'
import { AddressResponse, GetPredictionsResponse, Location } from '@/domain/use-cases/address'
import {
  CardContent,
  CardHeader,
  GeoCommercialStructure,
  SelectOption,
  ZipCodeTextField,
} from '@/main/components'
import { Checkbox } from '@/main/components/checkbox'
import { DialogAlert } from '@/main/components/dialog-alert/dialog-alert'
import { getGeoCommercialConfig } from '@/main/components/geo-commercial-fields/build-geo-commercial-structure-config'
import { Container } from '@/main/components/pages-structures'
import { SelectedPersonField } from '@/main/components/selected-person-field/selected-person-field'
import {
  AuthenticatedUserContext,
  IdentityContext,
  NavigationContext,
  NavigationContextProps,
  RouterMatchContext,
  RouterMatchContextProps,
} from '@/main/contexts'
import { useDebounce } from '@/main/hooks/debounce'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import {
  createPhoneNumberLengthValidation,
  EmailTextField,
  FullNameTextField,
  PhoneFields,
  SearchAddressWithZipCodeFields,
} from '@/main/pages/personal-data/fields'
import { SeparatedNamesTextField } from '@/main/pages/personal-data/fields/separated-names/separated-names-text-field'
import { Typography } from '@naturacosmeticos/natds-web'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { v4 as uuidv4 } from 'uuid'
import { PersonalDataApi } from './api'
import { SearchAddressField } from './fields/search-address/search-address-field'
import { usePersonPersonalData } from './hooks/use-person-personal-data'

type PersonalDataPageProps = {
  api: PersonalDataApi
}

export const PersonalDataPage: React.FC<PersonalDataPageProps> = ({ api }) => {
  const {
    tenantId,
    countryId,
    companyId,
    personId,
    userName,
    authorization,
    personalDataSearchAddressWithZipCodeToggle,
  } = useContext(IdentityContext)

  const { params } = useContext<RouterMatchContextProps>(RouterMatchContext)

  const { authenticated, sellerId, isAttendance } = useContext(AuthenticatedUserContext)

  const [hasNoRecommender, setHasNoRecommender] = useState(false)
  const [selectedRecommender, setSelectedRecommender] = useState<PersonInfoInterface>({})
  const [showDialogAlertEvent, setShowDialogAlertEvent] = useState(false)
  const [locationData, setLocationData] = useState(null)
  const [sessionToken, setSessionToken] = useState(null)
  const [hasSearchAddressError, setHasSearchAddressError] = useState<boolean>(false)
  const [dialogErrorMessage, setDialogErrorMessage] = useState<string>('')
  const [shouldNotResetGeoGraphicalLevelsOnce, setShouldNotResetGeoGraphicalLevelsOnce] = useState(false)
  const [isLoadingComplete, setIsLoadingComplete] = useState(false)
  const messages = usePageMessages(Page.PersonalData).messages as PersonalDataMessages
  const configs = useTenantConfigs(tenantId, Page.PersonalData) as PersonalDataConfigs
  const addressConfigs = useTenantConfigs(tenantId, Page.Address) as AddressConfigs

  const { goToNextPage, goToPreviousPage } = useContext<NavigationContextProps>(NavigationContext)
  const formMethods = useForm<PersonalDataForm>({
    mode: 'onTouched',
  })
  const { getValues, formState, setValue, reset, setError } = formMethods
  const { isLoading: isFetchingPersonalData, data } = usePersonPersonalData({
    getPersonalData: api.getPersonalData,
  })

  const getRecommenderValueForComplete = (formValues) => {
    if (isRecommenderFieldDisplayed) {
      return getRecommenderFromField(formValues)
    }

    if (shouldGetAuthenticatedRecommender()) {
      return getAuthenticatedRecommender()
    }

    return null
  }

  const getRecommenderFromField = ({ recommender }) => {
    if (hasNoRecommender) {
      return getDefaultRecommenderValue()
    }
    return recommender
  }

  const getDefaultRecommenderValue = () =>
    shouldGetAuthenticatedRecommender() ? getAuthenticatedRecommender() : null

  const shouldGetAuthenticatedRecommender = () => configs.recommender && authenticated

  const getAuthenticatedRecommender = () => (!isAttendance ? sellerId : null)

  const searchLatitudeAndLongitude = async (formValues): Promise<Location> => {
    const { state, city, street, cologne, number } = formValues
    const { location } = await api.getLatitudeLongitude({
      tenantId,
      address: {
        state,
        city,
        street,
        cologne: cologne?.value,
        number: Number(number),
      },
    })

    return { ...location }
  }

  const formatAddress = (formValues: PersonalDataForm): DataForm => {
    const addressKeys = [
      'state',
      'city',
      'street',
      'cologne',
      'number',
      'references',
      'complement',
      'zipCode',
    ]

    const copyFormValues = { ...formValues }

    const address = addressKeys.reduce((acc, curr) => {
      delete copyFormValues[curr]

      if (formValues[curr]) {
        return {
          ...acc,
          [curr]: formValues[curr],
        }
      }

      return acc
    }, {})

    if (hasNoRecommender) {
      delete copyFormValues.recommender
    }

    return {
      ...copyFormValues,
      address,
    }
  }

  const mountCompleteParams = async (): Promise<CompletePersonalDataParams> => {
    const formValues = getValues()
    let defaultCompleteParams: CompletePersonalDataParams = {
      countryId,
      companyId,
      personId: personId || params?.personId,
      userName,
      userId: sellerId,
      authorization,
      data: {
        ...formValues,
        ...locationData,
        recommender: getRecommenderValueForComplete(formValues),
      }
    }

    if (personalDataSearchAddressWithZipCodeToggle) {
      defaultCompleteParams = {
        ...defaultCompleteParams,
        data: {
          ...formatAddress(formValues),
          location: await getLocation(formValues)
        }
      }
    }

    return defaultCompleteParams
  }

  const getLocation = async (formValues) => {
    if (isDataLocationValid()) {
        return data.location
    }
    const newCoordinates = await searchLatitudeAndLongitude(formValues)
    return newCoordinates
  }

  const isDataLocationValid = () => {
    if (!data.location) { return false }

    const shouldUseLocation: boolean = Object.values(data.location)
      .every((coordinate: number | null) => coordinate)

    return shouldUseLocation
  }

  const onSubmitPersonalData = async () => {
    setIsLoadingComplete(true)

    try {
      const completeParams = await mountCompleteParams()
      await api.completePersonalData(completeParams)

      goToNextPage()
    } catch (error) {
      const messageKey = error.data?.error?.messageKey
      const dialogInfoText = messages.dialog.infoText
      setDialogErrorMessage(dialogInfoText[messageKey] || dialogInfoText.unexpectedError)
      setShowDialogAlertEvent(true)
    } finally {
      setIsLoadingComplete(false)
    }
  }

  const handleNoRecommender = (event) => {
    const checked = Boolean(event.target.checked)
    setHasNoRecommender(checked)
  }

  useEffect(() => {
    const value = hasNoRecommender ? messages.recommender.noRecommenderText.label : ''
    setValue('recommender', value, { shouldValidate: true, shouldDirty: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNoRecommender])

  const isRecommenderFieldDisplayed =
    configs.recommender && configs.recommender.shouldDisplay({ authenticated })

  const isRecommenderValid = selectedRecommender.isSelected || hasNoRecommender
  const shouldDisableNextButton = !(formState.isValid && (!isRecommenderFieldDisplayed || isRecommenderValid))

  const loadAddressOptions = async (inputValue: string) => {
    setHasSearchAddressError(false)

    let resultFromApi: GetPredictionsResponse

    try {
      resultFromApi = await api.getPredictions({
        input: inputValue,
        sessionToken,
        tenantId,
      })
    } catch {
      setHasSearchAddressError(true)
    }

    return resultFromApi.map((item) => ({ label: item.description, value: item.placeId }))
  }

  const { debouncedFunctionResult: loadOptions } = useDebounce<SelectOption[]>({
    customFunction: loadAddressOptions,
  })

  const handleGetAddressError = () => {
    setDialogErrorMessage(messages.searchAddress.error.address)
    setShowDialogAlertEvent(true)
    reset({
      ...getValues(),
      geoAddress: { label: '', value: null },
    })

    setError('geoAddress', {
      type: 'required',
      message: messages.searchAddress.rules.required,
    })
  }

  const handleSelectOptions = async (inputValue: SelectOption) => {
    if (!inputValue) {
      return
    }
    setHasSearchAddressError(false)

    try {
      setLocationData(null)

      const resultFromApi: AddressResponse = await api.getAddress({
        placeId: inputValue.value,
        sessionToken,
        tenantId,
      })

      setLocationData(resultFromApi)
      handleSetNewSessionToken()
    } catch {
      handleSetNewSessionToken()

      handleGetAddressError()
    }
  }

  const handleSetNewSessionToken = useCallback(() => {
    setSessionToken(uuidv4())
  }, [])

  const formatAddressFieldsFromGeographicalLevel = (field: string, value: string): SelectOption | string => {
    const fieldsToConvertToSelect = ['cologne']
    return fieldsToConvertToSelect.includes(field) ? { value, label: value } : value
  }

  const getAddressFieldsFromGeographicalLevel = () => {
    if (personalDataSearchAddressWithZipCodeToggle) {
      return data.geographicalLevels.reduce((acc, { label, description }) => ({
        ...acc,
        [label]: formatAddressFieldsFromGeographicalLevel(label, description),
      }), {})
    }

    return {}
  }

  useEffect(() => {
    handleSetNewSessionToken()
  }, [handleSetNewSessionToken])

  useEffect(() => {
    const lastNavigationAction = localStorage.getItem('LastNavigationAction')
    const shouldSetSavedData = authenticated || lastNavigationAction === 'Previous'

    const checkNoRecommenderIfNeeded = () => {
      if (isRecommenderFieldDisplayed && data.recommender === null) {
        handleNoRecommender({ target: { checked: true } })
      }
    }

    if (shouldSetSavedData) {
      const hasSavedData = Object.values(data).some((formField) => formField)
      if (hasSavedData) {
        setShouldNotResetGeoGraphicalLevelsOnce(true)
        reset({ ...data, ...getAddressFieldsFromGeographicalLevel() })
        checkNoRecommenderIfNeeded()
      }
      if (data?.geographicalLevels && configs.allocationStrategy.searchAddress) {
        setLocationData({
          geographicalLevels: data.geographicalLevels,
          location: data.location,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const formatPhoneOptions = () =>
    configs.phones.map((phone) => ({
      ...phone,
      phoneType: phone.type,
      validations: {
        firstDigit: phone.firstDigitValidation ? phone.firstDigitValidation : () => true,
        length: createPhoneNumberLengthValidation(phone.length),
      },
    }))

  return (
    <>
      <Container
        previousButtonLabel={messages.previousButtonLabel}
        onPreviousButtonClick={goToPreviousPage}
        nextButtonLabel={messages.nextButtonLabel}
        onNextButtonClick={onSubmitPersonalData}
        disableNextButton={shouldDisableNextButton}
        isLoading={isFetchingPersonalData || isLoadingComplete}
      >
        <CardHeader title={messages.title} subtitle={messages.subtitle} />
        <CardContent>
          <FormProvider {...formMethods}>
            {configs.shouldUseSeparatedNames ? (
              <SeparatedNamesTextField namesOptions={configs.namesOptions} messages={messages.separatedNames} />
            ) : (
              <FullNameTextField
                messages={messages.fullName}
                customProps={{
                  shouldInfoTextBeVisible: true,
                  showAsteriskInRequiredLabel: configs.showAsteriskInRequiredLabel,
                }}
              />
            )}
            <PhoneFields
              messages={messages.phone}
              shouldShowPhoneSelect={configs.shouldUsePhoneSelect}
              options={formatPhoneOptions()}
            />
            <EmailTextField
              messages={messages.email}
              customProps={{
                shouldInfoTextBeVisible: true,
                showAsteriskInRequiredLabel: configs.showAsteriskInRequiredLabel,
              }}
            />
            {isRecommenderFieldDisplayed && (
              <>
                <SelectedPersonField
                  messages={messages.recommender}
                  personCode={data?.recommender}
                  searchPerson={api.getIndication}
                  hasNoSelectedPerson={hasNoRecommender}
                  selectedPerson={selectedRecommender}
                  setSelectedPerson={setSelectedRecommender}
                  id="recommender"
                />
                <Checkbox
                  color="primary"
                  label={
                    <Typography variant="body2">{messages.recommender.noRecommenderText.label}</Typography>
                  }
                  onChange={(e) => handleNoRecommender(e)}
                  checked={hasNoRecommender}
                />
              </>
            )}
            {configs.allocationStrategy.zipCode && (
              <ZipCodeTextField
                label={messages.zipCode.label}
                placeholder={messages.zipCode.placeholder}
                rules={{
                  maxLength: {
                    message: messages.zipCode.errorMessage,
                    value: configs.allocationStrategy.zipCode.rules.maxLength,
                  },
                  required: {
                    message: messages.zipCode.requiredErrorMessage,
                    value: true,
                  },
                }}
                validChars={configs.allocationStrategy.zipCode.rules.pattern}
                link={{
                  show: false,
                }}
              />
            )}

            {!personalDataSearchAddressWithZipCodeToggle && configs.allocationStrategy.searchAddress && (
              <SearchAddressField
                name="geoAddress"
                messages={{
                  label: messages.searchAddress.label,
                  loading: messages.searchAddress.loading,
                  noOptions: hasSearchAddressError
                    ? messages.searchAddress.error.predictions
                    : messages.searchAddress.notFound,
                  placeholder: messages.searchAddress.placeholder,
                }}
                rules={messages.searchAddress.rules}
                promiseOptions={loadOptions}
                handleSelectOptions={(inputValue: SelectOption) => handleSelectOptions(inputValue)}
              />
            )}
            {configs.allocationStrategy.levels && (
              <GeoCommercialStructure
                customConfig={getGeoCommercialConfig(
                  { ...messages.geographicalLevels },
                  configs.allocationStrategy.levels.length
                )}
                countryId={countryId}
                shouldNotResetOnce={shouldNotResetGeoGraphicalLevelsOnce}
              />
            )}
            {personalDataSearchAddressWithZipCodeToggle &&
              configs.allocationStrategy.searchAddressWithZipCode && (
                <SearchAddressWithZipCodeFields
                  page={{ configs, messages, addressConfigs }}
                  api={{ getAddressByZipCodeApi: api.getAddressByZipCode }}
                />
              )}
          </FormProvider>
        </CardContent>
      </Container>
      <DialogAlert
        show={showDialogAlertEvent}
        messages={{
          infoText: dialogErrorMessage,
          closeButtonText: messages.dialog.closeButtonText,
        }}
        closeDialogCallback={() => setShowDialogAlertEvent(false)}
      />
    </>
  )
}
