import { makeStyles } from '@naturacosmeticos/natds-web'
import { Theme } from '@material-ui/core/styles'

type ControlledSelectStylesProps = {
  disabled?: boolean
}

export const useControlledSelectStyles = makeStyles<Theme, ControlledSelectStylesProps>((theme) => ({
  container: {
    cursor: ({ disabled }) => disabled && 'no-drop !important',
    padding: `${theme.spacing(1.5)}px 0`,
  },
  label: {
    marginBottom: theme.spacing(0.5),
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: `${theme.spacing(0.15)}em`,
    letterSpacing: '0.11px',
  },
}))
