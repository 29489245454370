import { createPhoneNumberFirstDigitValidation } from '@/main/pages/personal-data/fields'
import {
  AddressConfigs,
  AttachmentsConfigs,
  AuthorizationCodeConfigs,
  CompletedConfigs,
  DigitalStoreConfigs,
  DocumentationConfigs,
  MaintenancePersonalDataConfigs,
  MaintenanceRegisterConfigs,
  PagesTenantConfigs,
  PersonalDataConfigs,
  PreRegisterConfigs,
  ProfileConfigs,
} from './configs.d'
import { AgreementProduct } from '../../agreement/agreement-product'
import { AttachmentType } from '../../attachments/attachments'
import { Document } from '../../documents/documents'
import { DigitalStoreTermsEnum } from '../../digital-store/digital-store-terms-type'
import { VendorId } from '../../vendor/vendor-id'
import { AddressPageType, AddressOrder } from '../../address'
import { PhoneType } from '../../person'

const documentation: DocumentationConfigs = {
  agreementProducts: [AgreementProduct.NATURA, AgreementProduct.BUREAU],
  shouldDisplayPepOption: true,
}

const preRegister: PreRegisterConfigs = {
  documents: [
    {
      type: Document.CitizenCard,
      name: 'Cédula de Ciudadanía',
      placeholder: 'C.C',
      validChars: /^[\d]{0,10}$/m,
      rules: {
        pattern: /[\d]*/,
        minLength: 4,
        maxLength: 10,
      },
    },
    {
      type: Document.ForeignCard,
      name: 'Cédula de Extranjería',
      placeholder: 'C.E',
      validChars: /^[\d]{0,10}$/m,
      rules: {
        pattern: /[\d]*/,
        minLength: 4,
        maxLength: 10,
      },
    },
  ],
}

const attachments: AttachmentsConfigs = {
  documents: [
    {
      type: Document.CitizenCard,
      name: 'Cédula de Ciudadanía',
      attachments: [
        { title: 'Frente', type: AttachmentType.CitizenCardFront },
        { title: 'Reverso', type: AttachmentType.CitizenCardBack },
      ],
    },
    {
      type: Document.ForeignCard,
      name: 'Cédula de Extranjería',
      attachments: [
        { title: 'Frente', type: AttachmentType.ForeignCardFront },
        { title: 'Reverso', type: AttachmentType.ForeignCardBack },
      ],
    },
  ],
  canChooseDocument: false,
}

const digitalStore: DigitalStoreConfigs = {
  showSkipButton: false,
  showEmailInfoRegistration: false,
  acceptTermsStrategy: DigitalStoreTermsEnum.DEFAULT,
  vendorId: VendorId.EMPTY,
  agreement: {
    products: [AgreementProduct.DIGITAL_STORE],
  },
  digitalStoreNameMaxOfChars: null,
  shouldUnlinkAgreements: true,
}

const address: AddressConfigs = {
  pageType:
    process.env.COLOMBIA_ADDRESS_PAGE_STATELESS_TOGGLE === 'true'
      ? AddressPageType.GeographicalLevels
      : AddressPageType.Legacy,
  shouldDisableTheFilledInput: false,
  geographicalLevels: { length: 3 },
  number: {
    validChars: null,
    hasNoNumberCheckbox: true,
  },
  reference: {},
  showDeliveryCheckbox: true,
  fieldsOrder: [
    AddressOrder.STREET,
    AddressOrder.NUMBER,
    AddressOrder.COMPLEMENT,
    AddressOrder.REFERENCES,
    AddressOrder.COLOGNE,
    AddressOrder.CITY,
    AddressOrder.STATE,
  ],
}

const completed: CompletedConfigs = {
  rejected: {
    hasEmailContact: false,
  },
  shouldSearchIndication: true,
  shouldCheckStatus: true,
  shouldOnlyShowConfirmation: false,
  goToClickButton: {
    show: false,
  },
}

const authorizationCode: AuthorizationCodeConfigs = {
  phoneNumberHyphenIndex: 3,
}

const personalData: PersonalDataConfigs = {
  shouldUsePhoneSelect: true,
  showAsteriskInRequiredLabel: false,
  phones: [
    {
      type: PhoneType.CELLULAR,
      mask: [/[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
      length: 10,
      firstDigitValidation: createPhoneNumberFirstDigitValidation('3'),
    },
  ],
  shouldUseSeparatedNames: process.env.SHOULD_USE_SEPARATED_NAMES === 'true',
  namesOptions: ['firstName', 'lastName'],
  allocationStrategy: { levels: { length: 3 } },
}

const maintenanceRegisterPage: MaintenanceRegisterConfigs = null

const maintenancePersonalDataPage: MaintenancePersonalDataConfigs = null

const profile: ProfileConfigs = null

export const naturaColConfigs: PagesTenantConfigs = {
  documentation,
  personalData,
  attachments,
  preRegister,
  address,
  digitalSignaturePage: null,
  digitalStore,
  completed,
  authorizationCode,
  maintenanceRegisterPage,
  maintenancePersonalDataPage,
  profile,
}
