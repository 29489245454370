import { BadRequestError } from '@/data/protocols/errors'
import { HttpPatchClient, HttpResponse, HttpStatusCode } from '@/data/protocols/http'
import { UnexpectedError } from '@/domain/errors'
import {
  SaveConsultantInfo,
  SaveConsultantInfoParams,
  SaveConsultantInfoRequestHeaders,
  SaveConsultantInfoResponse,
  UpdatePersonBody,
} from '@/domain/use-cases/register-maintenance/save-consultant-info'

export class RemoteSaveConsultantInfo implements SaveConsultantInfo {
  constructor(
    private readonly url: string,
    private readonly httpPatchClient: HttpPatchClient<SaveConsultantInfoRequestHeaders, UpdatePersonBody, SaveConsultantInfoResponse>,
    private readonly xApiKey: string,
  ) { }

   update = async (params: SaveConsultantInfoParams): Promise<SaveConsultantInfoResponse> => {
    const {
      personId, emails, phones, name, nickname, gender, birthday, motherName, companyId, countryId,
    } = params

     const response = await this.httpPatchClient.patch({
       url: `${this.url}/${personId}`,
       headers: {
        'x-api-key': this.xApiKey,
         companyid: companyId,
         countryid: countryId,
         sourcesystem: '7',
      },
       body: {
         emails,
         phones,
         name,
         nickname,
         gender,
         birthday,
         motherName,
       },
     })

    return this.handleResponse(response)
  }

  updateBasicData = async (params: SaveConsultantInfoParams): Promise<SaveConsultantInfoResponse> => {
    const {
      personId, emails, phones, name, nickname, gender, birthday, motherName, companyId, countryId,
    } = params

     const response = await this.httpPatchClient.patch({
       url: `${this.url}/${personId}`,
       headers: {
        'x-api-key': this.xApiKey,
         companyid: companyId,
         countryid: countryId,
         sourcesystem: '7',
      },
       body: {
         emails,
         phones,
         name,
         nickname,
         gender,
         birthday,
         motherName,
       },
     })

    return this.handleResponse(response)
  }

  private handleResponse(response: HttpResponse) {
    const { statusCode, body } = response
    switch (statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.noContent:
        return body
      case HttpStatusCode.badRequest:
        throw new BadRequestError(body.error)
      default:
        throw new UnexpectedError()
    }
  }
}
