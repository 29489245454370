import { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@naturacosmeticos/natds-web/dist/styles/makeStyles'

export const useCustomCardStyles = makeStyles<Theme>((theme) => ({
  container: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
  },
  title: {
    color: theme.palette.primary.main,
  },
  editIcon: {
    paddingLeft: theme.spacing(1),
  },
  editButton: {
    display: 'flex',
    textTransform: 'uppercase',
  },
}))
