import React, { useContext, useEffect, useState } from 'react'
import { ProgressIndicator } from '@/main/components/progress-indicator'
import i18n from 'i18next'

import { useGetDigitalStoreStatusesSWR } from '@/main/hooks/swrServices'
import { IdentityContext } from '@/main/contexts/identity-context'
import { DigitalStoreCurrentStatus } from '@/domain/models/digital-store'

import { Country } from '@/domain/models/country/country'
import { LANGUAGES } from '@/domain/models/language'
import { getLanguage } from '@/infra/translation/i18next/get-language'
import { CountryId } from '@/domain/models/country'
import { Container } from './digital-store-status-page.styles'

import { getStoreStatusPageByStatus } from './strategies/get-store-status-page-by-status/get-store-status-page-by-status'
import { DigitalStoreStatusPageApi } from './api/make-digital-store-status-page-api'

export type DigitalStoreStatusPageProps = {
  api: DigitalStoreStatusPageApi
}

const countriesWithPaymmentAccountStatusPage = [
  CountryId.Peru,
]

const isCountryWithCustomAgreementsFlow = (countryId): boolean => countriesWithPaymmentAccountStatusPage.includes(countryId)

export const DigitalStoreStatusPage = ({ api }: DigitalStoreStatusPageProps) => {
  const { countryId: countryIdAlphaCode, personId, companyId } = useContext(IdentityContext)
  const country = new Country(countryIdAlphaCode)
  const countryId = country.threeAlphaCountryCode
  const lang = LANGUAGES[countryId]
  const { digitalStoreStatus, isLoading, reFetch } = useGetDigitalStoreStatusesSWR({
    countryId: countryIdAlphaCode,
    companyId,
    personId,
    lang,
    apiCall: api.getDigitalStoreStatuses,
  })
  const [shouldRenderDigitalStorePage, setShouldRenderDigitalStorePage] = useState(false)

  const hasDigitalStoreApproved = digitalStoreStatus?.type === 'approved'
  const countryIdAsString = countryIdAlphaCode as string
  const hasCustomFlowAgreementsEnabled = isCountryWithCustomAgreementsFlow(countryIdAsString)
  const props = {
    hasCustomFlowAgreementsEnabled,
    hasDigitalStoreApproved,
    isLoading,
    digitalStoreStatus,
    setShouldRenderDigitalStorePage,
    onSave: reFetch,
  }
  const digitalStoreStatusValue = digitalStoreStatus?.status || DigitalStoreCurrentStatus.NOT_STARTED
  const PageComponent = getStoreStatusPageByStatus({ digitalStoreStatus: digitalStoreStatusValue, countryId, shouldRenderDigitalStorePage })

  useEffect(() => {
    i18n.changeLanguage(getLanguage(countryId, companyId))
  }, [countryId])

  if (isLoading) {
    return (
      <Container>
        <ProgressIndicator />
      </Container>
    )
  }
  return <PageComponent {...props} />
}
