import styled from 'styled-components'

export const StyledSectionTitle = styled.h4`
  color: #333333;
  font-size: 1.7rem;
  font-weight: bold;
  display: inline-block;
  position: relative;

  :before {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
    margin-right: 1.8rem;
    background-color: #f4ab34;
    width: 0.5rem;
    height: 3rem;
    content: "";
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
`
