import { BadRequestError, NotFoundError } from '@/data/protocols/errors'
import { HttpPatchClient, HttpStatusCode } from '@/data/protocols/http'
import { UnexpectedError } from '@/domain/errors'
import { CompanyId } from '@/domain/models/company/company-id'
import { CountryId } from '@/domain/models/country'
import { AddressPageForms } from './remote-get-multiple-address'

type SaveAddressHeaders = {
    countryid: CountryId
    companyid: CompanyId
    'x-api-key': string
}

export type AddressSavePageForms = {
  receiveBillingAtSameAddress: boolean
} & AddressPageForms

export type SaveAddressParams = {
  countryId: CountryId
  companyId: CompanyId
  personId: string
  address: AddressSavePageForms
}

export class RemoteSaveAddress {
    constructor(
        private readonly httpPatchClient: HttpPatchClient<SaveAddressHeaders, AddressPageForms>,
        private readonly xApiKey: string,
    ) {}

    update = async (params : SaveAddressParams): Promise<AddressPageForms> => {
        const httpResponse = await this.httpPatchClient.patch({
          url: `/address/update/${params.personId}`,
          headers: {
           'x-api-key': this.xApiKey,
            companyid: params.companyId,
            countryid: params.countryId,
         },
          body: params.address,
        })

        return this.handleResponse(httpResponse)
    }

    private handleResponse = (httpResponse) => {
        switch (httpResponse.statusCode) {
            case HttpStatusCode.ok:
              return httpResponse.body
            case HttpStatusCode.noContent:
                return httpResponse.body
            case HttpStatusCode.badRequest:
              throw new BadRequestError(httpResponse.body)
            case HttpStatusCode.notFound:
              throw new NotFoundError(httpResponse.body)
            default:
              throw new UnexpectedError()
        }
    }
}
