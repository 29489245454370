import { DocumentationMessages } from '@/domain/models/messages/messages'
import { TFunction } from 'i18next'

export function getDocumentationMessages(translate: TFunction): DocumentationMessages {
  return {
    modal: {
      title: translate('documentation.modal.title'),
      titleAcceptLabel: translate('documentation.modal.titleAcceptLabel'),
      titleCloseLabel: translate('documentation.modal.titleCloseLabel'),
    },
    titleForAcceptTerm: translate('documentation.titleForAcceptTerm'),
    titleForGeneralInformation: translate('documentation.titleForGeneralInformation'),
    instructionsForAcceptTerm: translate('documentation.instructionsForAcceptTerm'),
    instructionsForGeneralInformation: translate('documentation.instructionsForGeneralInformation'),
    birthdayLabel: translate('documentation.birthdayLabel'),
    genderLabel: translate('documentation.genderLabel'),
    ethnicityLabel: translate('documentation.ethnicityLabel'),
    nationalityLabel: translate('documentation.nationalityLabel'),
    birthdayPlaceholder: translate('documentation.birthdayPlaceholder'),
    genderPlaceholder: translate('documentation.genderPlaceholder'),
    ethnicityPlaceholder: translate('documentation.ethnicityPlaceholder'),
    nationalityPlaceholder: translate('documentation.nationalityPlaceholder'),
    checkboxLabel: translate('documentation.checkboxLabel'),
    checkboxLabelLink: translate('documentation.checkboxLabelLink'),
    minAgeError: translate('documentation.minAgeError'),
    maxAgeError: translate('documentation.maxAgeError'),
    invalidDateError: translate('documentation.invalidDateError'),
    requiredError: translate('requiredErrorMessage'),
    nextButtonLabel: translate('nextButtonLabel'),
    previousButtonLabel: translate('previousButtonLabel'),
    birthStateLabel: translate('documentation.birthStateLabel'),
    birthStatePlaceholder: translate('documentation.birthStatePlaceholder'),
    acceptTermsAriaLabel: translate('documentation.acceptTermsAriaLabel'),
    pepVisibility: {
      radioLabel: translate('documentation.pepVisibility.radioLabel'),
      radioOptions: {
        yes: translate('documentation.pepVisibility.radioOptions.yes'),
        no: translate('documentation.pepVisibility.radioOptions.no'),
      },
    },
    publicOffice: {
      name: translate('documentation.publicOffice.name'),
      startDate: translate('documentation.publicOffice.startDate'),
      endDate: translate('documentation.publicOffice.endDate'),
      error: {
        startDateError: translate('documentation.publicOffice.error.startDateError'),
        endDateError: translate('documentation.publicOffice.error.endDateError'),
      },
    },
    dialog: {
      closeButtonText: translate('documentation.dialog.closeButtonText'),
    },
    establishmentLabel: translate('documentation.establishmentLabel'),
    establishmentPlaceholder: translate('documentation.establishmentPlaceholder'),
    admissionDateLabel: translate('documentation.admissionDateLabel'),
    admissionDatePlaceholder: translate('documentation.admissionDatePlaceholder'),
  }
}
