import { CompanyId } from '@/domain/models/company/company-id'
import { themes } from '@naturacosmeticos/natds-web'
import get from 'lodash/get'
import { useEffect, useState } from 'react'

const allThemes = {
  [CompanyId.Natura]: themes.natura,
  [CompanyId.Avon]: themes.avon,
}

export const useTheme = (companyId = CompanyId.Natura) => {
  const [theme, setTheme] = useState(themes.natura)

  useEffect(() => {
    const newTheme = get(allThemes, companyId, themes.natura)
    setTheme(newTheme)
  }, [companyId])

  return theme
}
