import { CompanyId } from '@/domain/models/company/company-id'
import { CountryId } from '@/domain/models/country'
import { DigitalStoreCurrentStatus } from '@/domain/models/digital-store'
import { getLanguage } from '@/infra/translation/i18next/get-language'
import { LoadingContainer } from '@/main/components/modal/modal.styles'
import { CenteredLoadingContainer } from '@/main/components/pages-structures/container.styles'
import { ProgressIndicator } from '@/main/components/progress-indicator'
import { IdentityContext } from '@/main/contexts'
import { useGetDigitalStoreStatusesSWR } from '@/main/hooks/swrServices'
import i18n from 'i18next'
import React, { useContext, useEffect, useState } from 'react'
import { DigitalStoreBrasilSuccessPageWrapper } from './components/digital-store-brasil-success-page/digital-store-brasil-success-page-wrapper'
import { DigitalStoreRenameError } from './digital-store-rename-error'
import { DigitalStoreRenameForm } from './digital-store-rename-form'
import { Content } from './digital-store-rename-form.styles'

export const DigitalStoreRenamePage = (): any => {
  const {
    personId,
  } = useContext(IdentityContext)

  const decodedPersonId = atob(personId)
  const [renamed, setRenamed] = useState(false)
  const [savedName, setSavedName] = useState('')
  const [isSavingDigitalStoreName, setIsSavingDigitalStoreName] = useState(false)

  const { digitalStoreStatus, isLoading } = useGetDigitalStoreStatusesSWR({
    countryId: CountryId.Brazil,
    companyId: CompanyId.Natura,
    personId: decodedPersonId,
    lang: 'pt-BR',
  })

  useEffect(() => {
    i18n.changeLanguage(getLanguage(CountryId.Brazil, CompanyId.Natura))
  }, [])

  const onSave = (newSavedName, isSavingDigitalStoreName) => {
    setIsSavingDigitalStoreName(isSavingDigitalStoreName)
    if (isSavingDigitalStoreName === false && !!newSavedName) {
      setSavedName(newSavedName)
      setRenamed(true)
    }
  }

  if (renamed) {
    return <DigitalStoreBrasilSuccessPageWrapper savedDigitalStoreName={savedName} personId={decodedPersonId} />
  }

  if (isLoading || isSavingDigitalStoreName) {
    return (
      <Content>
        <LoadingContainer data-testid="loading-container">
          <CenteredLoadingContainer>
            <ProgressIndicator />
          </CenteredLoadingContainer>
        </LoadingContainer>
      </Content>
    )
  }

  if (digitalStoreStatus.status === DigitalStoreCurrentStatus.NOT_STARTED && renamed === false) {
    return <DigitalStoreRenameForm personId={decodedPersonId} onSave={onSave} />
  }

  return <DigitalStoreRenameError />
}
