import { makeRemoteGetMultipleAddress } from '@/main/factories/remote/register-maintenance/make-remote-get-multiple-addresses'
import {
  AddressPageForms,
  GetMultipleAddressParams,
  MultipleAddress,
} from '@/data/use-cases/register-maintenance/remote-get-multiple-address'
import { SaveAddressParams } from '@/data/use-cases/register-maintenance/remote-save-address'
import { makeRemoteSaveAddress } from '@/main/factories/remote/register-maintenance/make-remote-save-addresses'

export type RegisterMaintenanceAddressPageApi = {
  saveAddress: (data: SaveAddressParams) => Promise<AddressPageForms>
  getMultipleAddresses: (data: GetMultipleAddressParams) => Promise<MultipleAddress>
}

export const makeRegisterMaintenanceAddressPageApi = (): RegisterMaintenanceAddressPageApi => {

  const remoteSaveAddress = makeRemoteSaveAddress()
  const saveAddress = (params: SaveAddressParams) => remoteSaveAddress.update(params)

  const remoteGetMultipleAddress = makeRemoteGetMultipleAddress()
  const getMultipleAddresses = (params: GetMultipleAddressParams) => remoteGetMultipleAddress.get(params)

  return {
    saveAddress,
    getMultipleAddresses
  }
}
