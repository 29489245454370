import { LevelName } from '@/domain/models/address'
import { Page } from '@/domain/models/page/page'
import { AddressConfigs } from '@/domain/models/tenant/tenant-configs'
import {
  AsyncSelect,
  CardContent,
  CardHeader,
  ControlledAddressNumberField,
  ControlledTextField,
  ZipCodeTextField,
} from '@/main/components'
import { DialogAlert } from '@/main/components/dialog-alert/dialog-alert'
import { Container } from '@/main/components/pages-structures'
import {
  FormContext,
  FormContextProps,
  IdentityContext,
  NavigationContext,
  NavigationContextProps,
} from '@/main/contexts'
import { useDomainState } from '@/main/hooks/useDomainState'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import React, { useContext, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { adaptFormValuesWithDeliveryAddressCopied } from '../adapters/output-adapter'
import { useAddressPageStyles } from '../address-page.styles'
import { AddressPageApi } from '../api'
import { useAddressData } from '../hooks/use-address-data'
import { AddressFormValues, AddressMessages } from '../models'
import { PageContainer } from './search-address-page.styles'

type SearchedAddressPageProps = {
  api: AddressPageApi
}

export const SearchedAddressPage: React.FC<SearchedAddressPageProps> = ({ api }) => {
  const { onSubmit } = useContext<FormContextProps>(FormContext)
  const { goToPreviousPage, goToNextPage } = useContext<NavigationContextProps>(NavigationContext)

  const {
    tenantId,
    countryId,
    shouldCallCompleteEndpoint,
    businessModel,
    role,
    functionId,
    personId,
  } = useContext(IdentityContext)

  const messages = usePageMessages(Page.Address).messages as AddressMessages

  const {
    zipCode,
    reference,
    shouldDisableTheFilledInput,
    validateSpecialCharacters,
    validateAllSpecialCharacters,
    complement,
  } = useTenantConfigs(tenantId, Page.Address) as AddressConfigs

  const formMethods = useForm<AddressFormValues>({ mode: 'onChange' })
  const { isLoading: isFetchingAddressData, data } = useAddressData({ getAddressData: api.getAddressData })

  const {
    control,
    reset,
    formState: { isValid, isSubmitting },
    getValues,
  } = formMethods

  const { selectPromise } = useDomainState({
    countryId,
    getStates: api.getStates,
  })
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState<boolean>(false)

  useEffect(() => {
    const hasSavedData = Object.values(data).some((formField) => formField)
    if (hasSavedData) {
      reset(data)
    }
  }, [data, reset])

  const onNextButtonClick = () => {
    const formValues = getValues()
    if (shouldCallCompleteEndpoint) {
      api
        .complete({
          data: formValues,
          tenantId,
          businessModel,
          role,
          functionId,
          personId,
        })
        .then(() => goToNextPage())
        .catch(() => setIsErrorDialogOpen(true))
      return
    }
    const parsedValue = adaptFormValuesWithDeliveryAddressCopied(formValues)
    onSubmit(parsedValue, goToNextPage)
  }

  const formRules = { required: messages.addressRequiredError }
  const addressPageClasses = useAddressPageStyles()

  const handleShouldDisableFilledInput = (levelName: LevelName) => {
    const hasSavedData = Object.values(data).some((formField) => formField)
    if (!(shouldDisableTheFilledInput && hasSavedData)) {
      return false
    }
    const description = data[levelName]?.label ? data[levelName].label : data[levelName]

    const invalidCases = ['', 'S/N', null, undefined]
    const shouldEnableInput = invalidCases.includes(description?.toString().trim())

    return !shouldEnableInput
  }

  return (
    <PageContainer>
      <Container
        previousButtonLabel={messages.previousButtonLabel}
        onPreviousButtonClick={goToPreviousPage}
        nextButtonLabel={messages.nextButtonLabel}
        onNextButtonClick={onNextButtonClick}
        disableNextButton={!isValid}
        isLoading={isSubmitting || isFetchingAddressData}
      >
        <CardHeader title={messages.title} subtitle={messages.subtitle} />
        <CardContent>
          <FormProvider {...formMethods}>
            <ZipCodeTextField
              label={messages.zipCode.label}
              placeholder={messages.zipCode.placeholder}
              id={LevelName.ZipCode}
              link={{
                show: false,
              }}
              control={control}
              rules={{
                minLength: {
                  message: messages.zipCode.errorMessage,
                  value: zipCode.rules.maxLength,
                },
                maxLength: {
                  message: messages.zipCode.errorMessage,
                  value: zipCode.rules.maxLength,
                },
                required: {
                  message: messages.zipCode.requiredErrorMessage,
                  value: true,
                },
              }}
              validChars={zipCode.rules.pattern}
              disabled={handleShouldDisableFilledInput(LevelName.ZipCode)}
            />
            <ControlledTextField
              id={LevelName.Street}
              label={messages.mainStreet}
              control={control}
              rules={formRules}
              showAsterisk={false}
              required
              shouldValidateIsNotOnlySpaces
              disabled={handleShouldDisableFilledInput(LevelName.Street)}
              validChars={validateAllSpecialCharacters}
            />
            <div className={addressPageClasses.formRow}>
              <div className={addressPageClasses.numberInput}>
                <ControlledAddressNumberField
                  id={LevelName.Number}
                  label={messages.number}
                  placeholder=""
                  rules={{
                    required: {
                      message: messages.addressRequiredError,
                      value: true,
                    },
                  }}
                  noNumberLabel={messages.checkBoxWithoutNumber}
                  showAsterisk={false}
                  checkbox={{ color: 'primary' }}
                  validChars={validateAllSpecialCharacters}
                  disabled={handleShouldDisableFilledInput(LevelName.Number)}
                />
              </div>
              <div className={addressPageClasses.complementInput}>
                <ControlledTextField
                  id={LevelName.Complement}
                  label={messages.complementaryData}
                  control={control}
                  validChars={complement.pattern}
                />
              </div>
            </div>
            <ControlledTextField
              id={LevelName.References}
              rules={{
                ...formRules,
                maxLength: {
                  message: messages.rules.maxLength,
                  value: reference.maxLength,
                },
              }}
              label={messages.references}
              control={control}
              shouldValidateIsNotOnlySpaces
              required
              showAsterisk={false}
              placeholder={messages.placeholders.references}
              validChars={validateSpecialCharacters}
            />

            <ControlledTextField
              id={LevelName.City}
              label={messages.city}
              control={control}
              rules={formRules}
              showAsterisk={false}
              required
              shouldValidateIsNotOnlySpaces
              disabled={handleShouldDisableFilledInput(LevelName.City)}
              validChars={validateSpecialCharacters}
            />

            <ControlledTextField
              id={LevelName.Cologne}
              label={messages.cologne}
              control={control}
              rules={formRules}
              placeholder={messages.placeholders.cologne}
              showAsterisk={false}
              required
              shouldValidateIsNotOnlySpaces
              disabled={handleShouldDisableFilledInput(LevelName.Cologne)}
              validChars={validateSpecialCharacters}
            />

            <AsyncSelect
              id={LevelName.State}
              label={messages.state}
              aria-label={messages.state}
              placeholder={messages.placeholders.state}
              rules={formRules}
              control={control}
              optionsPromise={selectPromise}
              disabled={handleShouldDisableFilledInput(LevelName.State)}
            />
          </FormProvider>
        </CardContent>
        <DialogAlert
          show={isErrorDialogOpen}
          messages={{ infoText: messages.unexpectedError, closeButtonText: messages.closeButtonText }}
          closeDialogCallback={() => setIsErrorDialogOpen(false)}
        />
      </Container>
    </PageContainer>
  )
}
