import { Page } from '@/domain/models/page/page'
import { DigitalStoreConfigs, ExtraEntries } from '@/domain/models/tenant/tenant-configs'
import { IdentityContext, IdentityInfoProps } from '@/main/contexts'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import {
 Dispatch, SetStateAction, useContext, useEffect, useMemo, useState,
} from 'react'
import { FormState } from 'react-hook-form'
import { Agreement } from '@/domain/use-cases/agreement'
import { tenantIdMapper } from '@/domain/models/tenant/tenant-id'
import { DigitalStorePageApi } from '../api/make-digital-store-page-api'
import { useDigitalStoreAgreements } from './use-digital-store-agreements'
import { useDigitalStoreAvailability } from './use-digital-store-availability'
import { useDigitalStoreInfo } from './use-digital-store-info'
import { useDigitalStoreSave } from './use-digital-store-save'
import { DigitalStoreHookContext } from '../types'

export type DigitalStoreResults = {
  save: (extraEntries?: {}) => Promise<void>
  isSaved: boolean
  hasError: boolean
  isLoading: boolean
  agreements: Agreement[]
  errorOnSave: boolean
  errorOnCheckAvailability: boolean
  setHasError: Dispatch<SetStateAction<boolean>>
  isAvailable: boolean
  helperTextKey: string
  isTermsAccepted: boolean
  setHelperTextKey: Dispatch<SetStateAction<string>>
  unlinkAgreements: () => Promise<void>
  savedExtraEntries: unknown
  shouldDisableInput: boolean
  checkAvailability: (newDigitalStoreName: string) => Promise<void>
  setIsTermsAccepted: (isAccepted: boolean) => void
  alreadyHadSavedName: boolean
  isLoadingAgreements: boolean
  shouldHideAgreements: boolean
  shouldShowSkipButton: boolean
  hasSavedExtraEntries: boolean
  shouldEnableSaveButton: boolean
  userNeedsToAcceptTerms: boolean
  currentDigitalStoreName: string
  setUserNeedsToAcceptTerms: (userNeedsToAcceptTerms: boolean) => void
  shouldDisableVerifyButton: boolean
  setCurrentDigitalStoreName: Dispatch<SetStateAction<string>>
  lastCheckedDigitalStoreName: string
  extraEntriesFromTenantConfig: ExtraEntries
  setLastCheckedDigitalStoreName: Dispatch<SetStateAction<string>>
  hasExtraEntriesFromTenantConfig: boolean
  showLoadWhileSavingDigitalStore: boolean
}

export type UseDigitalStoreParams = {
  api: DigitalStorePageApi
  convergence: boolean
  extraEntriesForm: FormState<any>
}

export const useDigitalStore = ({
  api,
  convergence,
  extraEntriesForm,
}: UseDigitalStoreParams): DigitalStoreResults => {
  const {
    role,
    tenantId,
    personId: contextPersonId,
    companyId,
    functionId,
    sourceSystem,
    businessModel,
  } = useContext<IdentityInfoProps>(IdentityContext)

  const countryId = tenantIdMapper[companyId][tenantId]

  const personId = contextPersonId || '462c9d10-fe4d-41b2-9e08-fdb0eb2d66d4'
  // const personId = contextPersonId

  const context: DigitalStoreHookContext = {
    role,
    personId,
    tenantId,
    countryId,
    companyId,
    functionId,
    sourceSystem,
    businessModel,
  }

  const {
    extraEntries: extraEntriesFromTenantConfig,
    showSkipButton,
  } = useTenantConfigs(tenantId, Page.DigitalStore) as DigitalStoreConfigs

  const {
    alreadyHadSavedName,
    isLoading: isLoadingInfo,
    name: savedDigitalStoreName,
    extraEntries: savedExtraEntries,
    hasExtraEntries: hasSavedExtraEntries,
  } = useDigitalStoreInfo({
    api: {
      getInfo: api.getInfo,
    },
    context,
  })

  const {
    isSaved,
    isSaving,
    errorOnSave,
    save: saveDigitalStore,
    helperTextKey: helperTextKeyFromSaveHook,
  } = useDigitalStoreSave({
    convergence,
    api: {
      save: api.save,
      updatePersonSubstatus: api.updatePersonSubstatus,
    },
    context,
  })

  const {
    setIsTermsAccepted,
    userNeedsToAcceptTerms,
    unlink: unlinkAgreements,
    setUserNeedsToAcceptTerms,
    isAccepted: isTermsAccepted,
    isLoading: isLoadingAgreements,
    errorOnLoad: errorOnLoadAgreements,
    lists: {
      ids: agreementsIds,
      complete: agreements,
    },
  } = useDigitalStoreAgreements({
    api: {
      getAgreements: api.getAgreements,
      unlinkAgreements: api.unlinkAgreements,
    },
    context,
  })

  const {
    check: checkNameAvailability,
    errorOnCheckAvailability,
    isChecking: isCheckingAvailability,
    helperTextKey: helperTextKeyFromAvailabilityHook,
    isAvailable: isAvailableAfterCheckNameAvailability,
  } = useDigitalStoreAvailability({
    api: {
      checkNameAvailability: api.checkNameAvailability,
    },
    context,
  })

  const [
    isLoading,
    setIsLoading,
  ] = useState(true)

  const [
    isAvailable,
    setIsAvailable,
  ] = useState(false)

  const [
    hasError,
    setHasError,
  ] = useState(false)

  const [
    currentDigitalStoreName,
    setCurrentDigitalStoreName,
  ] = useState('')

  const [
    lastCheckedDigitalStoreName,
    setLastCheckedDigitalStoreName,
  ] = useState(currentDigitalStoreName)

  const [
    helperTextKey,
    setHelperTextKey,
  ] = useState('')

  const hasExtraEntriesFromTenantConfig = useMemo(() => (
    extraEntriesFromTenantConfig && Array.isArray(extraEntriesFromTenantConfig) && extraEntriesFromTenantConfig.length > 0
  ), [extraEntriesFromTenantConfig])

  const isDigitalStoreNameBlank = useMemo(() => (
    currentDigitalStoreName === ''
  ), [currentDigitalStoreName])

  const shouldHideAgreements = useMemo(() => (
    errorOnLoadAgreements || !userNeedsToAcceptTerms
  ), [errorOnLoadAgreements, userNeedsToAcceptTerms])

  const shouldDisableInput = useMemo(() => (
    Boolean(alreadyHadSavedName) || isLoading
  ), [isLoading, alreadyHadSavedName])

  const shouldDisableVerifyButton = useMemo(() => (
    shouldDisableInput || hasError || isAvailable || isDigitalStoreNameBlank
  ), [hasError, isAvailable, isDigitalStoreNameBlank, shouldDisableInput])

  const shouldShowSkipButton = useMemo(() => {
    const isConsultantAutoRegister = functionId === 1 && role === 1 && businessModel === 1
    return (
      showSkipButton && !convergence && isConsultantAutoRegister
    )
  }, [businessModel, showSkipButton, convergence, functionId, role])

  const shouldEnableSaveButton = useMemo(() => {
    const nameStepIsOk = isAvailable || alreadyHadSavedName
    const termsStepIsOk = errorOnLoadAgreements || !userNeedsToAcceptTerms || isTermsAccepted
    const documentsStepIsOk = !hasExtraEntriesFromTenantConfig || (hasExtraEntriesFromTenantConfig && extraEntriesForm.isValid)

    return nameStepIsOk && termsStepIsOk && documentsStepIsOk
  }, [alreadyHadSavedName, errorOnLoadAgreements, extraEntriesForm.isValid, hasExtraEntriesFromTenantConfig, isTermsAccepted, isAvailable, userNeedsToAcceptTerms])

  const showLoadWhileSavingDigitalStore = useMemo(() => (
    isSaving
  ), [isSaving])

  const save = async (extraEntries = {}) => {
    try {
      const accepted = isTermsAccepted
      const agreementId = agreementsIds[0] || ''
      const digitalStoreName = currentDigitalStoreName

      await saveDigitalStore({
        accepted,
        agreementId,
        digitalStoreName,
        extraEntries,
      })
    } catch (error) {
      console.warn(error)
    }
  }

  const checkAvailability = async (newDigitalStoreName: string) => {
    setCurrentDigitalStoreName(newDigitalStoreName)
    await checkNameAvailability(newDigitalStoreName)
    setLastCheckedDigitalStoreName(newDigitalStoreName)
  }

  useEffect(() => {
    setHelperTextKey(helperTextKeyFromSaveHook)
  }, [helperTextKeyFromSaveHook])

  useEffect(() => {
    setHelperTextKey(helperTextKeyFromAvailabilityHook)
  }, [helperTextKeyFromAvailabilityHook])

  useEffect(() => {
    const isCurrentDigitalStoreNameNotChecked = currentDigitalStoreName !== lastCheckedDigitalStoreName
    const isNameAvailable = (
      isAvailableAfterCheckNameAvailability
      && !isDigitalStoreNameBlank
      && !isCurrentDigitalStoreNameNotChecked
    )

    setIsAvailable(isNameAvailable)
  }, [currentDigitalStoreName, isAvailableAfterCheckNameAvailability, isDigitalStoreNameBlank, lastCheckedDigitalStoreName])

  useEffect(() => {
    const isSomeHookLoading = [
      isSaving,
      isLoadingInfo,
      isCheckingAvailability,
    ].some((isHookLoading) => isHookLoading)

    setIsLoading(isSomeHookLoading)
  }, [isSaving, isLoadingInfo, isCheckingAvailability])

  useEffect(() => {
    setHasError(errorOnCheckAvailability)
  }, [errorOnCheckAvailability])

  useEffect(() => {
    setHasError(errorOnSave)
  }, [errorOnSave])

  useEffect(() => {
    setCurrentDigitalStoreName(savedDigitalStoreName)
    setLastCheckedDigitalStoreName(savedDigitalStoreName)
  }, [savedDigitalStoreName])

  return {
    save,
    isSaved,
    hasError,
    isLoading,
    agreements,
    errorOnSave,
    errorOnCheckAvailability,
    setHasError,
    isAvailable,
    helperTextKey,
    isTermsAccepted,
    setHelperTextKey,
    unlinkAgreements,
    savedExtraEntries,
    shouldDisableInput,
    checkAvailability,
    setIsTermsAccepted,
    alreadyHadSavedName,
    isLoadingAgreements,
    shouldHideAgreements,
    shouldShowSkipButton,
    hasSavedExtraEntries,
    shouldEnableSaveButton,
    userNeedsToAcceptTerms,
    currentDigitalStoreName,
    setUserNeedsToAcceptTerms,
    shouldDisableVerifyButton,
    setCurrentDigitalStoreName,
    lastCheckedDigitalStoreName,
    extraEntriesFromTenantConfig,
    setLastCheckedDigitalStoreName,
    hasExtraEntriesFromTenantConfig,
    showLoadWhileSavingDigitalStore,
  }
}
