import {
  GetPersonDataParams,
  PersonData,
} from '@/domain/use-cases/person'
import {
 useContext, useEffect, useRef, useState,
} from 'react'
import { IdentityContext, IdentityInfoProps } from '@/main/contexts'
import { DocumentType } from '@/domain/models/documents/document'

export type PersonDocumentsResult = {
  documents: Array<DocumentType>
  isLoading: boolean
  error?: Error
}

type UsePersonDocumentsParams = {
  getPersonData: (data: GetPersonDataParams) => Promise<PersonData | {} | null | unknown>
}

export const usePersonDocuments = ({ getPersonData }: UsePersonDocumentsParams): PersonDocumentsResult => {
  const [personDocuments, setPersonDocuments] = useState<PersonDocumentsResult>({
    documents: [],
    isLoading: true,
    error: undefined,
  })
  const { personId, countryId, companyId } = useContext<IdentityInfoProps>(IdentityContext)

  const fetchPersonDocuments = () => {
    if (!personId || !countryId || !companyId) {
      return
    }
    const parameters: GetPersonDataParams = {
      personId,
      countryId,
      companyId,
      relations: ['documents'],
    }
    getPersonData(parameters)
      .then((data: any) => {
        setPersonDocuments({
          documents: data.person.documents || [],
          isLoading: false,
        })
      })
      .catch((error: Error) => {
        setPersonDocuments({
          documents: [],
          isLoading: false,
          error,
        })
      })
  }

  const fetchPersonDocumentsRef = useRef(fetchPersonDocuments)

  useEffect(() => {
    fetchPersonDocumentsRef.current()
  }, [])

  return personDocuments
}
