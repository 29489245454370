import React from 'react'
import styled from 'styled-components'
import {
 Tooltip, Icon, ButtonTextFieldProps, ControlledTextFieldWithButtonProps, InputTextFieldProps, TextFieldWithButton, Typography,
} from '@/main/components'
import { ProgressIndicator } from '@/main/components/progress-indicator'

type LabelProps = {
  children: JSX.Element | JSX.Element[] | React.ReactNode
  className?: string
  tooltipText: string
}

const Label = styled(({ children, tooltipText, className }: LabelProps) => {
  const LabelTooltip = styled(Tooltip)`
    .MuiTooltip-tooltip {
      padding: ${(props) => props.theme.spacing * 2}px;
    }
  `

  const TooltipIcon = styled(Icon)`
    margin-left: ${(props) => props.theme.spacing}px;
  `

  return (
    <span className={className}>
      {children}
      {tooltipText && (
        <LabelTooltip
          title={(
            <Typography variant="body2">
              { tooltipText }
            </Typography>
          )}
          enterTouchDelay={100}
          arrow
        >
          <TooltipIcon
            name="outlined-action-help"
            color="primary"
            size="tiny"
            data-testid="tooltip-icon"
          />
        </LabelTooltip>
    )}
    </span>
  )
})`
  display: inline-flex;
  align-items: center;
`

const ButtonLoader = () => (
  <div>
    <ProgressIndicator
      data-testid="loading-verify"
      size={24}
    />
  </div>
)

export type NameValidationProps = {
  onChangeInput: (value: any) => void
  inputValue: any,
  tooltip?: string
  buttonIsLoading?: boolean
} & Partial<ButtonTextFieldProps>
& Omit <ControlledTextFieldWithButtonProps, 'inputProps' | 'buttonProps'> & Partial<InputTextFieldProps>

const NameValidation = ({
  id = 'digitalStoreName',
  label,
  tooltip,
  buttonLabel,
  buttonIsLoading,
  infoText,
  errorMessage,
  buttonOnClick,
  buttonOnChange,
  onChangeInput,
  shouldButtonBeDisabled,
  disabled,
  isSuccess,
  successMessage,
  inputValue,
  invalid,
}: NameValidationProps) => {
  const buttonProps: ButtonTextFieldProps = {
    buttonLabel: buttonIsLoading ? <ButtonLoader /> : buttonLabel,
    buttonOnClick,
    buttonOnChange,
    shouldButtonBeDisabled,
  }

  const inputProps : InputTextFieldProps = {
    disabled,
    onChange: onChangeInput,
    value: inputValue,
  }

  return (
    <div data-testid="digital-store-name-validation">
      <TextFieldWithButton
        data-testid="name-validation-box"
        id={id}
        label={<Label tooltipText={tooltip}>{label}</Label>}
        buttonProps={buttonProps}
        required
        inputProps={inputProps}
        infoText={infoText}
        errorMessage={errorMessage}
        invalid={invalid}
        isSuccess={isSuccess}
        successMessage={successMessage}
        showAsterisk={false}
      />
    </div>
  )
}

export default NameValidation
