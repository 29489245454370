import React, { useContext } from 'react'
import { Typography } from '@naturacosmeticos/natds-web'
import { ProgressIndicator } from '@/main/components/progress-indicator'
import { IdentityContext } from '@/main/contexts/identity-context'
import { CountryIdAlpha2, COUNTRIES_IDS_KEYS } from '@/domain/models/country'

import { StepConfirmation } from '@/main/pages/digital-store/pages/digital-store-status-page/components/digital-store-status/digital-store-status-page-self-service-helper/step-confirmation'
import LinkInstructions from '@/main/assets/gifs/instructions-account-link.gif'

import { useGetPersonData } from '@/main/pages/digital-store/hooks/use-get-person-data'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { Page } from '@/domain/models/page/page'
import { DigitalStoreStatusAccountLinkStepMessages } from '@/domain/models/messages/messages'
import { useTenantVendor } from '@/main/hooks/useTenantVendor'
import { useAccountBindLink } from '@/main/pages/digital-store/hooks/use-get-account-bind-link'
import { EmailBar } from '@/main/pages/digital-store/pages/digital-store-status-page/components/digital-store-status/digital-store-status-page-self-service-helper/email-bar'
import { LocalConvertCountryIdFromAlpha3ToAlpha2 } from '@/data/use-cases/country/local-convert-country-id-from-alpha-3-to-alpha-2'

export const AccountLinkStep = () => {
  const {
 countryId, personId, tenantId, companyId,
} = useContext(IdentityContext)
  const vendor = useTenantVendor(tenantId)
  const pageMessagesOptions = { vendorName: vendor.name }
  const messages = usePageMessages(Page.DigitalStoreStatusAccountLinkStep, pageMessagesOptions).messages as DigitalStoreStatusAccountLinkStepMessages

  const countryAlpha2 = new LocalConvertCountryIdFromAlpha3ToAlpha2(
    COUNTRIES_IDS_KEYS,
  ).convert({ countryId })

  const { accountBindLinkData, accountBindLinkLoading } = useAccountBindLink({
    personId,
    countryCode: countryAlpha2 as unknown as CountryIdAlpha2,
    companyId,
  })

  const { personData, personDataLoading } = useGetPersonData({
    relations: ['emails'],
    personId,
    countryId,
    companyId,
  })

  const userEmail = personData?.person?.emails[0]?.email

  const onConfirm = () => window.open(accountBindLinkData, '_blank')

  if (accountBindLinkLoading || personDataLoading) return <ProgressIndicator />

  return (
    <StepConfirmation
      title={messages.title}
      confirmText={messages.buttonConfirmLabel}
      onConfirm={onConfirm}
      isFloatingButton
    >
      <Typography color="textSecondary" align="center" style={{ marginBottom: '18px' }}>
        {messages.hint.text}
      </Typography>
      <EmailBar email={userEmail} />
      <img
        alt={messages.hint.imageAlt}
        src={LinkInstructions}
        width="182px"
      />
    </StepConfirmation>
)
}
