import { useEffect, useState } from 'react'
import { buildTheme, Theme } from '@naturacosmeticos/natds-react'
import { CompanyId } from '@/domain/models/company/company-id'
import { companyNameById } from '@/domain/models/company/company-name-by-id'
import { ColorScheme } from '@/domain/models/theme-ds/color-scheme'

export const useFVTheme = (companyId: CompanyId): Theme => {
  const company = companyNameById[companyId]
  const [fvTheme, setTheme] = useState<Theme>(null)

  useEffect(() => {
    const newTheme = buildTheme(company, ColorScheme.LIGHT)
    setTheme(newTheme)
  }, [company])

  return fvTheme
}
