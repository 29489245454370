import { SignatureData } from '@/domain/models/digital-signature/signature-pad'
import {
  DigitalSignatureMessages,
} from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import { CheckboxOrder, DigitalSignaturePageConfigs, DigitalSignatureProduct } from '@/domain/models/tenant/tenant-configs'
import {
  Agreement,
} from '@/domain/use-cases/agreement'
import {
  Button,
  CardContent,
  CardHeader,
  Spacing,
} from '@/main/components'
import { AcceptTerms } from '@/main/components/accept-terms'
import { Container } from '@/main/components/pages-structures'
import { IdentityContext, NavigationContext, NavigationContextProps } from '@/main/contexts'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import { DigitalSignaturePageApi } from '@/main/pages/digital-signature/api'
import { SignaturePadBoard } from '@/main/pages/digital-signature/components/signature-pad-board/signature-pad-board'
import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react'
import { AgreementProduct } from '@/domain/models/agreement/agreement-product'
import { Controller, useForm } from 'react-hook-form'
import SignatureDialog from './components/signature-dialog/signature-dialog'
import { SignatureTutorial } from './components/signature-tutorial'
import { useSignatureNavigationPage } from './hooks/useSignatureNavigationPage'
import { SignatureTutorialProvider } from './context/SignatureTutorialContext'

type DigitalSignaturePageProps = {
  api: DigitalSignaturePageApi
}

type FormNames = {
  firstCheckbox: boolean,
  secondCheckbox: boolean
}

type AgreementRender = {
  checkboxOrder: CheckboxOrder
  agreement: Agreement[]
  shouldOpenInitialDialog?: boolean
}

type AgreementCheckBox = AgreementRender[]

type HandleSetAgreementsCheckBoxAndData = {
  agreements: Agreement[]
  products: DigitalSignatureProduct[]
  openedCheckboxDialog?: {
    first: boolean,
    second: boolean,
  },
}

type HandleGetAgreementByProduct = {
  products: DigitalSignatureProduct[]
}

type AgreementConfigsOrder = {
  product: AgreementProduct,
  shouldOpenInitialDialog?: boolean
}

export type Agreements = {
  checkbox: AgreementCheckBox
  data: Agreement[]
}

const agreementsInitialState: Agreements = {
  checkbox: null,
  data: null,
}

export const DigitalSignaturePage: React.FC<DigitalSignaturePageProps> = ({ api }) => {
  const {
    showDigitalSignatureContent,
    setShowDigitalSignatureContent,
    showTutorial,
    setShowTutorial,
    isTheFirstViewOfTutorial,
    setIsTheFirstViewOfTutorial,
    showDialog,
    setShowDialog,
  } = useSignatureNavigationPage()
  const [signatureData, setSignatureData] = useState<SignatureData>(null)
  const [agreements, setAgreements] = useState<Agreements>(agreementsInitialState)
  const [isAcceptLoading, setIsAcceptLoading] = useState(false)
  const [enableNextButton, setEnableNextButton] = useState(false)

  const {
    formState: { isValid, isDirty }, control,
  } = useForm<FormNames>({
    mode: 'onTouched',
    defaultValues: {
      firstCheckbox: false,
      secondCheckbox: false,
    },
  })

  const {
    personId, countryId, companyId, tenantId, userId,
  } = useContext(IdentityContext)
  const {
    goToPreviousPage, goToNextPage,
  } = useContext<NavigationContextProps>(NavigationContext)

  const messages = usePageMessages(Page.DigitalSignaturePage).messages as DigitalSignatureMessages
  const configs = useTenantConfigs(tenantId, Page.DigitalSignaturePage) as DigitalSignaturePageConfigs

  const onCloseSignatureDialog = () => {
    setShowDialog(false)
    setShowDigitalSignatureContent(true)
  }

  const displayPreviewOfContract = () => {
    const {
      agreement: {
        previewContract,
      },
    } = configs

    const { url } = agreements.data.find((agreement) => agreement.product === previewContract)

    window.open(url)
  }

  const formatAgreements = (agreements: Agreement[], agreementProduct: AgreementProduct) => ({
    addingSignature: () => agreements
      .map((agreement) => {
        const { agreementId, product } = agreement

        if (product === agreementProduct) {
          return ({ agreementId, signatureData })
        }
        return { agreementId }
      }),
  })

  const onPreviousButtonClick = () => {
    if (showTutorial) {
      setShowTutorial(false)
      setShowDigitalSignatureContent(true)
      return
    }

    goToPreviousPage()
  }

  useEffect(() => {
    const handleSetEnableNextButton = () => {
      if (signatureData && isValid && isDirty) {
        setEnableNextButton(true)
        return
      }
      setEnableNextButton(false)
    }

    handleSetEnableNextButton()
  }, [isDirty, isValid, signatureData])

  const onNextButtonClick = async () => {
    const {
      agreement: {
        previewContract,
      },
    } = configs

    try {
      setIsAcceptLoading(true)
      await api.accept({
        personId,
        userId,
        agreements: formatAgreements(agreements.data, previewContract).addingSignature(),
      })

      goToNextPage()
    } catch {
      console.warn('Error on accept term')
    } finally {
      setIsAcceptLoading(false)
    }
  }

  const handleSetAgreementsCheckboxAndData = useCallback(({
    agreements,
    products,
    openedCheckboxDialog,
  }: HandleSetAgreementsCheckBoxAndData) => {
    const firstAgreements: AgreementConfigsOrder[] = products.map(({
      checkboxOrder,
      name,
    }) => checkboxOrder === 'first' && ({
      product: name,
    }))

    const secondAgreements: AgreementConfigsOrder[] = products.map(({
      checkboxOrder,
      name,
    }) => checkboxOrder === 'second' && ({
      product: name,
    }))

    const first = []
    firstAgreements.forEach((firstAgreement) => {
      const agreement = agreements.find((agreement) => agreement.product === firstAgreement.product)
      if (agreement) { first.push(agreement) }
    })

    const second = []

    secondAgreements.forEach((secondAgreement) => {
      const agreement = agreements.find((agreement) => agreement.product === secondAgreement.product)
      if (agreement) { second.push(agreement) }
    })

    const agreementCheckBox: AgreementCheckBox = [
      {
        checkboxOrder: 'first',
        agreement: first,
        shouldOpenInitialDialog: openedCheckboxDialog.first,
      },
      {
        checkboxOrder: 'second',
        agreement: second,
        shouldOpenInitialDialog: openedCheckboxDialog.second,
      },
    ]
    setAgreements({ checkbox: agreementCheckBox, data: agreements })
  }, [])

  const handleGetAgreementByProduct = useCallback(async ({ products }: HandleGetAgreementByProduct) => {
    const productNames = products.map((product) => product.name)

    let agreementResponse: Agreement[]

    try {
      const { agreements } = await api.getAgreementByProduct({
        personId,
        countryId,
        companyId,
        products: productNames,
      })

      agreementResponse = agreements
    } catch {
      console.warn('Error get terms')
    }

    return agreementResponse
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId, personId])

  const handleSetAgreements = useCallback(async () => {
    const { agreement: { products, openedCheckboxDialog } } = configs

    const agreementsResponse = await handleGetAgreementByProduct({ products })
    handleSetAgreementsCheckboxAndData({
      agreements: agreementsResponse,
      products,
      openedCheckboxDialog,
    })
  }, [configs, handleGetAgreementByProduct, handleSetAgreementsCheckboxAndData])

  useEffect(() => {
    handleSetAgreements()
  }, [handleSetAgreements])

  const handleSignatureButton = (): void => {
    if (isTheFirstViewOfTutorial) {
      setIsTheFirstViewOfTutorial(false)
      setShowTutorial(true)
    } else {
      setShowDialog(true)
    }
  }

  return (
    <>
      {showDigitalSignatureContent && (
        <Container
          previousButtonLabel={messages.previousButtonLabel}
          onPreviousButtonClick={onPreviousButtonClick}
          nextButtonLabel={messages.nextButtonLabel}
          onNextButtonClick={onNextButtonClick}
          isLoading={!agreements.data || isAcceptLoading}
          disableNextButton={!enableNextButton}
        >
          <CardHeader
            title={messages.title}
            subtitle={messages.subtitle}
          />
          <CardContent>
            <form>
              {agreements.checkbox && agreements.checkbox.map(({
                checkboxOrder,
                agreement,
                shouldOpenInitialDialog,
              }) => (
                <Controller
                  key={`${checkboxOrder}Key`}
                  name={`${checkboxOrder}Checkbox`}
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({
                    onChange, onBlur, ref, value,
                  }) => (
                    <AcceptTerms
                      agreements={agreement}
                      shouldFetch={false}
                      shouldHide={!agreement}
                      initialDialogState={shouldOpenInitialDialog}
                      dialogInfo={{
                        title: messages.dialog.title.agreements[checkboxOrder],
                        acceptText: messages.dialog.acceptButton,
                        closeText: messages.dialog.closeButton,
                      }}
                      checkbox={{
                        color: 'primary',
                        normalLabel: messages.agreementsCheckbox[checkboxOrder].normalText,
                        highlightedLabel: messages.agreementsCheckbox[checkboxOrder].highlightedText,
                        value,
                        onChange: (event) => {
                          onBlur()
                          onChange(event)
                        },
                        onBlur,
                        inputRef: ref,
                      }}
                    />
                  )}
                />
              ))}
            </form>
            <Spacing
              className="natds2"
              display="flex"
              marginY="standard"
              marginBottom={signatureData ? 'mediumX' : null}
            >
              {!signatureData && (
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={handleSignatureButton}
                >
                  {messages.signatureButton}
                </Button>
              )}
            </Spacing>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              onClick={displayPreviewOfContract}
              disabled={!agreements.data}
            >
              {messages.previewOfContractButton}
            </Button>
          </CardContent>
        </Container>
      )}
      {(showTutorial || showDialog) && (
        <SignatureDialog fullScreen={showDialog}>
          {showTutorial && (
            <SignatureTutorialProvider>
              <SignatureTutorial />
            </SignatureTutorialProvider>
          )}
          {showDialog && (
            <SignaturePadBoard setData={setSignatureData} onClose={onCloseSignatureDialog} />
          )}
        </SignatureDialog>
      )}
    </>
  )
}
