import { validate as rutValidator } from 'rut.js'
import { createPhoneNumberFirstDigitValidation } from '@/main/pages/personal-data/fields'
import {
  AddressConfigs,
  AttachmentsConfigs,
  CompletedConfigs,
  DigitalStoreConfigs,
  DocumentationConfigs,
  MaintenancePersonalDataConfigs,
  MaintenanceRegisterConfigs,
  PagesTenantConfigs,
  PersonalDataConfigs,
  PreRegisterConfigs,
  ProfileConfigs,
} from './configs.d'
import { AgreementProduct } from '../../agreement/agreement-product'
import { Document } from '../../documents/documents'
import { AttachmentType } from '../../attachments/attachments'
import { DigitalStoreTermsEnum } from '../../digital-store/digital-store-terms-type'
import { VendorId } from '../../vendor/vendor-id'
import { AddressPageType, AddressOrder } from '../../address'
import { PhoneType } from '../../person'

const documentation: DocumentationConfigs = {
  shouldAcceptTermsWhenAuthenticated: true,
  agreementProducts: [AgreementProduct.NATURA],
  shouldDisplayEstablishmentInfo: true,
}

const preRegister: PreRegisterConfigs = {
  documents: [
    {
      type: Document.Rut,
      validChars: /^[\d{7,9}\dkK]{0,10}$/m,
      name: 'RUT',
      rules: {
        pattern: /[\d{7,9}\dkK]{0,10}/,
        minLength: 8,
        maxLength: 10,
        validate: (value: string) => rutValidator(value),
      },
      isUpperCase: true,
    },
  ],
}

const attachments: AttachmentsConfigs = {
  documents: [
    {
      type: Document.Rut,
      name: 'Carnet de Identidad',
      attachments: [
        { title: 'Frente', type: AttachmentType.RutFront },
        { title: 'Reverso', type: AttachmentType.RutBack },
      ],
    },
  ],
  canChooseDocument: false,
}

const digitalStore: DigitalStoreConfigs = {
  showSkipButton: false,
  showEmailInfoRegistration: false,
  acceptTermsStrategy: DigitalStoreTermsEnum.DEFAULT,
  vendorId: VendorId.EMPTY,
  agreement: {
    products: [AgreementProduct.DIGITAL_STORE],
  },
  digitalStoreNameMaxOfChars: null,
  shouldUnlinkAgreements: true,
}

const address: AddressConfigs = {
  pageType:
    process.env.CHILE_ADDRESS_PAGE_STATELESS_TOGGLE === 'true'
      ? AddressPageType.GeographicalLevels
      : AddressPageType.Legacy,
  shouldDisableTheFilledInput: false,
  geographicalLevels: { length: 2 },
  number: {
    validChars: null,
    hasNoNumberCheckbox: false,
  },
  reference: {},
  showDeliveryCheckbox: true,
  fieldsOrder: [
    AddressOrder.STREET,
    AddressOrder.NUMBER,
    AddressOrder.COMPLEMENT,
    AddressOrder.REFERENCES,
    AddressOrder.COLOGNE,
    AddressOrder.CITY,
    AddressOrder.STATE,
  ],
}

const completed: CompletedConfigs = {
  rejected: {
    hasEmailContact: false,
  },
  shouldSearchIndication: true,
  shouldCheckStatus: true,
  shouldOnlyShowConfirmation: false,
  goToClickButton: {
    show: false,
  },
}

const maintenanceRegisterPage: MaintenanceRegisterConfigs = null

const maintenancePersonalDataPage: MaintenancePersonalDataConfigs = null

const personalData: PersonalDataConfigs = {
  shouldUsePhoneSelect: true,
  showAsteriskInRequiredLabel: false,
  phones: [
    {
      type: PhoneType.CELLULAR,
      mask: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
      firstDigitValidation: createPhoneNumberFirstDigitValidation('9'),
      length: 9,
    },
    {
      type: PhoneType.LINE,
      mask: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
      length: 8,
    },
  ],
  shouldUseSeparatedNames: process.env.SHOULD_USE_SEPARATED_NAMES === 'true',
  namesOptions: ['firstName', 'lastName'],
  allocationStrategy: { levels: { length: 2 } },
}

const profile: ProfileConfigs = null

export const naturaChlConfigs: PagesTenantConfigs = {
  documentation,
  personalData,
  attachments,
  preRegister,
  address,
  digitalSignaturePage: null,
  digitalStore,
  completed,
  authorizationCode: null,
  maintenanceRegisterPage,
  maintenancePersonalDataPage,
  profile,
}
