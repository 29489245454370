import { HttpPostClient, HttpStatusCode } from '@/data/protocols/http'
import { BadRequestError } from '@/data/protocols/errors'
import { UnexpectedError } from '@/domain/errors'
import { CountryId } from '@/domain/models/country'
import { CompanyId } from '@/domain/models/company/company-id'
import { PersonalDataForm, SearchAddressWithZipCodeForm } from '@/domain/models/personal-data/personal-data'
import { PersonData } from '@/main/contexts'


export type DataForm = {
  address?: SearchAddressWithZipCodeForm,
} & Omit<PersonalDataForm, 'street' | 'complement' | 'zipCode' | 'number' | 'state' | 'city' | 'reference' | 'cologne'>

export type CompletePersonalDataHeaders = {
  countryid: CountryId,
  companyid: CompanyId,
  username: string,
  userid: string,
  'x-api-key': string,
  authorization?: string,
}

export type CompletePersonalDataParams = {
  countryId: CountryId,
  companyId: CompanyId,
  personId: string,
  data: DataForm,
  userName: string,
  userId: string,
  authorization: string,
}

export class RemoteCompletePersonalData {
  constructor(
    private readonly httpPostClient: HttpPostClient<CompletePersonalDataHeaders, PersonalDataForm>,
    private readonly xApiKey: string,
  ) {}

  complete = async ({
    countryId,
    companyId,
    data,
    personId,
    userName,
    userId,
    authorization,
  }: CompletePersonalDataParams): Promise<PersonData> => {
    const headers: CompletePersonalDataHeaders = {
      countryid: countryId,
      companyid: companyId,
      'x-api-key': this.xApiKey,
      username: userName,
      userid: userId,
    }

    if (authorization) {
      headers.authorization = authorization
    }

    const httpResponse = await this.httpPostClient.post({
      url: `/personal-data/complete/${personId}`,
      headers,
      body: data,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
      case HttpStatusCode.created:
        return httpResponse.body
      case HttpStatusCode.badRequest:
        throw new BadRequestError(httpResponse.body)
      default:
        throw new UnexpectedError()
    }
  }
}
