import React from 'react'
import {
  BUSINESS_MODELS,
  ROLES,
  FUNCTION_IDS,
  PhoneType,
} from '@/domain/models/person'
import { CountryId } from '@/domain/models/country'
import { CompanyId } from '@/domain/models/company/company-id'
import { TenantId } from '@/domain/models/tenant/tenant-id'

export type IdentityInfoProps = {
  personId: string
  countryId: CountryId,
  tenantId: TenantId,
  shouldCreatePerson?: boolean,
  businessModel?: BUSINESS_MODELS,
  role?: ROLES,
  functionId?: FUNCTION_IDS,
  sourceSystem?: string,
  companyId?: CompanyId,
  authorization: string,
  userId?: string,
  userName?: string,
  phoneNumber?: string,
  phoneType?: PhoneType,
  email?: string,
  shouldCallCompleteEndpoint?: boolean
  isPossibleToBypass?: boolean
  consultantId?: string,
  goBackPage?: () => {},
  personalDataSearchAddressWithZipCodeToggle?: boolean
  accessTokenNaturaWeb?: string
  loginId?: string
  externalComponent?: React.Component
}

export const IdentityContext = React.createContext<IdentityInfoProps>({
  personId: undefined,
  countryId: undefined,
  authorization: undefined,
  tenantId: undefined,
  shouldCreatePerson: false,
  shouldCallCompleteEndpoint: undefined,
  isPossibleToBypass: undefined,
  consultantId: undefined,
  goBackPage: undefined,
  personalDataSearchAddressWithZipCodeToggle: undefined,
  accessTokenNaturaWeb: undefined,
  loginId: undefined,
})
