import React, { useContext, useEffect, useState } from 'react'
import { Typography } from '@naturacosmeticos/natds-web'
import { IdentityContext } from '@/main/contexts'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import {
  AddressConfigs,
  ProfileConfigs,
} from '@/domain/models/tenant/tenant-configs'
import { Page } from '@/domain/models/page/page'
import { MyProfileMessages } from '@/domain/models/messages/messages'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { useAddressStyles } from './address.styles'
import { CustomCard } from '../components/custom-card'

export type AddressType = {
  zipCode: string,
  addressJoin: string,
  reference: string
}

export const Address = ({ addressData },) => {
  const [address, setAddress] = useState<AddressType>()
  const componentClasses = useAddressStyles()
  const {
    tenantId,
    personId,
  } = useContext(IdentityContext)

  const {
    address: {
      getAddress,
      editUrl,
    },
  } = useTenantConfigs(tenantId, Page.MyProfile) as ProfileConfigs

  const { zipCode: zipCodeConfig } = useTenantConfigs(
    tenantId,
    Page.Address
  ) as AddressConfigs

  const { addresses: messages, editButton: editButtonName } = usePageMessages(Page.MyProfile).messages as MyProfileMessages

  const editButtonLink = process.env.ENABLE_MY_PROFILE_EDIT_ADDRESS === 'true' ? `${editUrl}${personId}` : ''

  useEffect(() => {
    setAddress({
      zipCode: addressData.zipCode.replace(zipCodeConfig.regex, zipCodeConfig.regexOption),
      addressJoin: getAddress(addressData),
      reference: addressData.reference || messages.withoutInformation
    })
  }, [])

  return (
    <CustomCard title={messages.title} className={componentClasses.container} editButtonName={editButtonName} editButtonLink={editButtonLink}>
      <div className={componentClasses.addressContainer} data-testid="address">
        <Typography variant="body2" color="textSecondary" className={componentClasses.addressSubtitleSpace}>
          {messages.zipCode}
        </Typography>
        <Typography className={componentClasses.addressInformation}>
          {address?.zipCode}
        </Typography>
        <Typography variant="body2" color="textSecondary" className={componentClasses.addressSubtitleSpace}>
          {messages.addressJoin}
        </Typography>
        <Typography className={componentClasses.addressInformation}>
          {address?.addressJoin}
        </Typography>
        <Typography variant="body2" color="textSecondary" className={componentClasses.addressSubtitleSpace}>
          {messages.reference}
        </Typography>
        <Typography className={componentClasses.addressInformation}>
          {address?.reference}
        </Typography>
      </div>
    </CustomCard>
  )
}
