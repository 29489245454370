import { makeMyProfileGetData } from '@/main/factories/remote/my-profile/make-get-profile-data'
import { GetMyProfileParams } from '@/data/use-cases/my-profile/remote-get-my-profile-data'
import { PersonalDataForm } from '@/domain/models/personal-data/personal-data'
import { makeGetDigitalSpaceInfo } from '@/main/factories/remote/my-profile/make-get-digital-space-info'
import {
  DigitalSpaceInfo,
  GetDigitalSpaceInfoParams,
} from '@/data/use-cases/my-profile/remote-get-digital-space-info'
import {
  GetMultipleAddressParams,
  MultipleAddress,
} from '@/data/use-cases/register-maintenance/remote-get-multiple-address'
import { makeRemoteGetMultipleAddress } from '@/main/factories/remote/register-maintenance/make-remote-get-multiple-addresses'

export type MyProfileDataApi = {
  getMyProfile: (params: GetMyProfileParams) => Promise<PersonalDataForm>
  getDigitalSpaceData: (params: GetDigitalSpaceInfoParams) => Promise<DigitalSpaceInfo>
  getMultipleAddresses: (data: GetMultipleAddressParams) => Promise<MultipleAddress>
}

export const makeMyProfileDataApi = (): MyProfileDataApi => {
  const remoteGetMyProfileData = makeMyProfileGetData()
  const getMyProfile = (params: GetMyProfileParams) => remoteGetMyProfileData.get(params)

  const remoteGetDigitalSpaceInfo = makeGetDigitalSpaceInfo()
  const getDigitalSpaceData = (params: GetDigitalSpaceInfoParams) => remoteGetDigitalSpaceInfo.get(params)

  const remoteGetMultipleAddress = makeRemoteGetMultipleAddress()
  const getMultipleAddresses = (params: GetMultipleAddressParams) => remoteGetMultipleAddress.get(params)

  return {
    getMyProfile,
    getDigitalSpaceData,
    getMultipleAddresses,
  }
}
