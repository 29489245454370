import { HttpGetClient, HttpResponse } from '@/data/protocols/http'
import { SelectOption } from '@/main/components'
import { CountryId } from '@/domain/models/country'

interface AddressByLevelHeaders {
  'x-api-key': string,
}

interface AddressByLevelParams {
  countryCode: CountryId,
  companyId: number,
  level: number,
  parentCode?: string | number,
}

interface CommercialStructureResponse {
  structureLevelId: number,
  structureLevelName: string,
  geoStructureCode: string,
}

interface AddressByLevelResponse {
  items: Array<CommercialStructureResponse>,
}

export class RemoteGetGeoCommercialStructure {
  constructor(
    private readonly httpGetClient: HttpGetClient<AddressByLevelHeaders, AddressByLevelResponse>,
    private readonly xApiKey: string,
  ) {
  }

  async fetch(params: AddressByLevelParams): Promise<Array<SelectOption>> {
    const response = await this.getGeoCommercialStructure(params)
    return this.mapSuccessfulResponse(response)
  }

  private getGeoCommercialStructure(params: AddressByLevelParams): Promise<HttpResponse<AddressByLevelResponse>> {
    const url = '/commercial-structure/geographic-levels'
    const headers: AddressByLevelHeaders = {
      'x-api-key': this.xApiKey,
    }
    const query = {
      level: params.level,
      parentCode: params.parentCode,
      countryCode: params.countryCode,
      companyId: params.companyId,
    }
    return this.httpGetClient.get({ url, headers, query })
  }

  private mapSuccessfulResponse(response: HttpResponse<AddressByLevelResponse>): Array<SelectOption> {
    return response.body.items.map((structureResponse) => ({
      label: structureResponse.structureLevelName,
      value: structureResponse.geoStructureCode,
    }))
  }
}
