import React from 'react'
import { ControlledTextField } from '@/main/components'
import { FieldsOrderProps } from '@/domain/models/tenant/tenant-configs/configs'

export const ComplementField = (fieldsOrderProps: FieldsOrderProps) => {
  const { messages, configs, control } = fieldsOrderProps
  return (
    <>
      <ControlledTextField
        id="complement"
        label={messages.complement.label}
        placeholder={messages.complement.placeholder}
        control={control}
        rules={{
          maxLength: {
            message: messages.complement.rules.maxLength,
            value: configs.complement.rules.maxLength,
          },
        }}
        validChars={configs.complement.validChars}
      />
    </>
  )
}
