import React, { useContext, useEffect, useState } from 'react'
import {
  ContentSuccess,
  RegisterConfirmationContainer,
  DescriptionContainer,
  SuccessTitle,
  IndicativeContainer,
  IndicativeLabel,
  IndicativeName,
  NextStepsData,
  GoToLandingPage,
} from '@/main/pages/confirmation/success-status-card.styles'
import { Button } from '@/main/components'
import { FormContext, FormContextProps, IdentityContext } from '@/main/contexts'
import ApprovedIcon from '@/main/assets/svgs/approved_icon_thin.svg'
import { DescriptionTexts } from '@/main/pages/confirmation/status-card.styles'
import { Link, Typography } from '@naturacosmeticos/natds-web'
import {
  ContactInfoContainer,
  ContactInfoData,
  NextStepsContainer,
} from '@/main/pages/confirmation/register-confirmation/register-confirmation.styles'
import { CompletedConfigs } from '@/domain/models/tenant/tenant-configs'
import { Page } from '@/domain/models/page/page'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import {
  ParseStringToReact,
  TypographyWithHTMLParser,
} from '@/main/components/string-to-react'
import { IndicationInfo } from '@/data/use-cases/indication/remote-search-indication'
import { GetIndicationInterface } from '@/main/pages/confirmation/api/make-confirmation-page-api'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { CompletedMessages } from '@/domain/models/messages/messages'

export type RegisterConfirmationProps = {
  getIndicationPerson: GetIndicationInterface
}

export const RegisterConfirmation: React.FC<RegisterConfirmationProps> = ({
  getIndicationPerson,
}) => {
  const { tenantId, companyId, countryId } = useContext(IdentityContext)
  const { person } = useContext<FormContextProps>(FormContext)
  const personCode = person?.introducerSellerInformation?.introducerSellerId
  const isIndicativePersonLoaded = !!personCode

  const [indicativePerson, setIndicativePerson] = useState<string>('')
  const [indicativePersonStatus, setIndicativePersonStatus] = useState<string>('initial')

  const {
    goToClickButton,
    shouldShowIndicativePerson,
    shouldShowContactInfos,
    shouldShowConfirmationDescription,
    shouldShowApprovedDescription,
  } = useTenantConfigs(tenantId, Page.Completed) as CompletedConfigs

  const messages = usePageMessages(Page.Completed).messages as CompletedMessages

  const goToANewURL = () => window.location.replace(goToClickButton.path)

  useEffect(() => {
    if (shouldShowIndicativePerson && personCode) {
      getIndicationPerson({ companyId, countryId, personCode })
      .then((indicationInfo: IndicationInfo) => {
        setIndicativePerson(indicationInfo.name)
        setIndicativePersonStatus('initial')
      }).catch((err) => {
        setIndicativePerson(messages.confirmation[err.message])
        setIndicativePersonStatus('error')
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowIndicativePerson, personCode])

  return (
    <RegisterConfirmationContainer>
      <ContentSuccess>
        <img
          alt={messages.confirmation.iconAlts.confirmation}
          src={ApprovedIcon}
          aria-label="confirmation-image"
          data-testid="confirmation-image"
        />

        <SuccessTitle variant="h5" className="success_title">
          {messages.confirmation.title}
        </SuccessTitle>

        { shouldShowConfirmationDescription && (
          <DescriptionContainer data-testid="confirmation-description">
            <DescriptionTexts variant="body1" className="bold">
              {messages.confirmation.subtitle}
            </DescriptionTexts>
            <DescriptionTexts variant="body1" className="description">
              {messages.confirmation.description}
            </DescriptionTexts>
          </DescriptionContainer>
        )}

        { shouldShowApprovedDescription && (
          <DescriptionContainer data-testid="approved-description">
            <DescriptionTexts variant="body1" color="textSecondary">
              {messages.confirmation.subtitle}
            </DescriptionTexts>
            <DescriptionTexts variant="body1" className="bold">
              {messages.confirmation.description}
            </DescriptionTexts>
          </DescriptionContainer>
        )}

        { (shouldShowIndicativePerson && isIndicativePersonLoaded) && (
          <IndicativeContainer data-testid="indicative-person">
            <IndicativeLabel>{messages.confirmation.indicativePerson}</IndicativeLabel>
            <IndicativeName
              variant="body2"
              className="bold"
              color={indicativePersonStatus as any}
            >
              {indicativePerson}
            </IndicativeName>
          </IndicativeContainer>
        )}

        <NextStepsContainer>
          <NextStepsData variant="body2" className="description">
            <TypographyWithHTMLParser text={messages.confirmation.nextSteps.step1} />
          </NextStepsData>

          { !shouldShowContactInfos && (
            <NextStepsData variant="body2" className="contact">
              <ParseStringToReact stringToParse={messages.confirmation.nextSteps.contact} />
            </NextStepsData>
          )}
        </NextStepsContainer>

        { goToClickButton.show && (
          <GoToLandingPage>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={goToANewURL}
            >
              {messages.confirmation.goToLandingPage}
            </Button>
          </GoToLandingPage>
        )}

        { shouldShowContactInfos && (
          <ContactInfoContainer>
            <DescriptionTexts variant="subtitle1">
              {messages.confirmation.contactInfo.title}
            </DescriptionTexts>
            <ContactInfoData>
              <DescriptionTexts variant="subtitle2" className="bold">
                {messages.confirmation.contactInfo.phoneNumber}
              </DescriptionTexts>
              <Link
                href={`mailto: ${messages.confirmation.contactInfo.email}`}
                variant="body2"
                target="_blank"
              >
                {messages.confirmation.contactInfo.email}
              </Link>
              <Typography variant="body1">
                {messages.confirmation.contactInfo.openingHours}
              </Typography>
            </ContactInfoData>
          </ContactInfoContainer>
        )}
      </ContentSuccess>
    </RegisterConfirmationContainer>
  )
}
