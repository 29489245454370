import {
 useContext, useEffect, useRef, useState,
} from 'react'
import {
 IdentityContext,
 IdentityInfoProps,
 RouterMatchContext,
 RouterMatchContextProps,
} from '@/main/contexts'
import { PersonalDataForm } from '@/domain/models/personal-data/personal-data'
import { GetPersonalDataParams } from '@/data/use-cases/personal-data/remote-get-personal-data'

export type PersonPersonalDataResult = {
  data: PersonalDataForm
  isLoading: boolean
  error?: Error
}

type UsePersonPersonalDataParams = {
  getPersonalData: (params: GetPersonalDataParams) => Promise<PersonalDataForm>
}

export const usePersonPersonalData = ({ getPersonalData }: UsePersonPersonalDataParams): PersonPersonalDataResult => {
  const [personPersonalData, setPersonPersonalData] = useState<PersonPersonalDataResult>({
    data: {},
    isLoading: true,
    error: undefined,
  })
  const identityContext = useContext<IdentityInfoProps>(IdentityContext)
  const { params } = useContext<RouterMatchContextProps>(RouterMatchContext)

  const fetchPersonPersonalData = () => {
    const { countryId, companyId } = identityContext
    const personId = identityContext.personId ?? params?.personId

    if (!personId || !countryId || !companyId) {
      return
    }
    const parameters: GetPersonalDataParams = {
      personId,
      countryid: countryId,
      companyid: companyId,
    }
    getPersonalData(parameters)
      .then((responseData: PersonalDataForm) => {
        setPersonPersonalData({
          data: responseData,
          isLoading: false,
        })
      })
      .catch((error: Error) => {
        setPersonPersonalData({
          data: {},
          isLoading: false,
          error,
        })
      })
  }

  const fetchPersonPersonalDataRef = useRef(fetchPersonPersonalData)

  useEffect(() => {
    fetchPersonPersonalDataRef.current()
  }, [])

  return personPersonalData
}
