import React, {
  useContext, useState, useMemo, useEffect,
} from 'react'
import { useForm, Controller } from 'react-hook-form'
import {
  parse, format, isValid, differenceInYears, isBefore,
} from 'date-fns'
import {
  AuthenticatedUserContext,
  IdentityContext,
  FormContext,
  NavigationContextProps,
  NavigationContext,
  PersonData,
} from '@/main/contexts'
import { Container } from '@/main/components/pages-structures'
import { SelectOption } from '@/main/components/select/select'
import {
  StyledFormContainer,
  StyledFormItem,
} from '@/main/pages/documentation/documentation-page.styles'
import { AcceptTerms } from '@/main/components/accept-terms'
import { CountryId } from '@/domain/models/country'
import { CountryCodes, DefaultNationalityByCountryId } from '@/main/factories/presenters/country-codes'
import { getCountryNames } from '@/main/factories/presenters/country-labels'
import { genderCodes } from '@/main/factories/presenters/gender-codes'
import { getGenderNames } from '@/main/factories/presenters/gender-labels'
import { ControlledSelect } from '@/main/components/select/'
import {
  AsyncSelect,
  CardContent,
  CardHeader,
  ControlledTextField,
} from '@/main/components'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { Page } from '@/domain/models/page/page'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import { DocumentationMessages } from '@/domain/models/messages/messages'
import { DocumentationConfigs } from '@/domain/models/tenant/tenant-configs'
import {
  FormControl, FormControlLabel, Icon, InputLabel, Radio, RadioGroup, Spacing,
} from '@naturacosmeticos/natds-web'
import { useDomainState } from '@/main/hooks/useDomainState'
import {
  CompleteDocumentationForm, CompleteDocumentationRequestParams,
} from '@/domain/use-cases/documentation/complete-documentation'
import { DialogAlert } from '@/main/components/dialog-alert/dialog-alert'
import { MalaysiaEthnicitiesOptions as ethnicityOptions } from '@/main/factories/presenters/ethnicity-codes'
import { GetDocumentationResponse } from '@/domain/use-cases/documentation/get-documentation'
import { DocumentationPageApi } from './api'

enum PepVisibility {
  Yes = 'show-pep-fields',
  No = 'hide-pep-fields',
}

type DocumentationPageProps = {
  api: DocumentationPageApi
}

type FormInputs = {
  birthday: string
  gender: SelectOption
  nationalityId: SelectOption
  acceptedAgreements: boolean
  establishmentId?: SelectOption,
  admissionDate?: string,
  ethnicityId?: SelectOption
  stateOfBirth?: SelectOption
  name?: string
  startDate?: string
  endDate?: string
}

type FormDefaultValuesParams = {
  birthday?: string
  gender?: number
  nationalityId?: number
  nationalityOptions?: SelectOption[]
  genderOptions?: SelectOption[]
  ethnicityOptions?: SelectOption[]
  ethnicityId?: number
  stateOfBirth?: string
  stateOptions?:SelectOption[]
  establishmentOptions?: SelectOption[]
  establishmentId?: string
  admissionDate?: string
  publicOffice?: {
    name?: string
    startDate?: string
    endDate?: string
  }
}

const dateStringToISO = (inputValueDate: string, isString?: boolean): string | Date => {
  const date = inputValueDate.split('/').reverse().join('-')
  return isString ? date : new Date(date)
}

const formatDate = (isoDate: string) => {
  if (isoDate) {
    try {
      const date = isoDate.substring(0, 10)
      const parsedDate = parse(date, 'yyyy-MM-dd', new Date())

      return format(parsedDate, 'dd/MM/yyyy')
    } catch (err) {
      return isoDate
    }
  }

  return isoDate
}

const formDefaultValues = ({
  birthday,
  gender,
  nationalityId,
  ethnicityId,
  establishmentId,
  nationalityOptions,
  genderOptions,
  stateOptions,
  ethnicityOptions,
  establishmentOptions,
  stateOfBirth,
  publicOffice,
  admissionDate,
}: FormDefaultValuesParams): FormInputs => ({
    birthday: formatDate(birthday),
    gender: genderOptions.find((option) => option.value === gender),
    nationalityId: nationalityOptions.find((option) => option.value === nationalityId),
    acceptedAgreements: false,
    establishmentId: establishmentOptions?.find((option) => option.value === establishmentId),
    admissionDate: formatDate(admissionDate),
    ethnicityId: ethnicityOptions?.find((option) => option.value === ethnicityId),
    stateOfBirth: stateOptions?.find((option) => option.value === stateOfBirth),
    name: publicOffice?.name,
    startDate: formatDate(publicOffice?.startDate),
    endDate: formatDate(publicOffice?.endDate),
  })

const getNationalityOptions = (countryId: CountryId) => {
  const defaultNationalityOption = getDefaultNationalityOption(countryId, DefaultNationalityByCountryId[countryId])
  const nationalitiesOptions = getCountryOptionsWithoutDefaultOption(countryId, DefaultNationalityByCountryId[countryId])

  return [defaultNationalityOption, ...nationalitiesOptions]

  function getDefaultNationalityOption(countryId: CountryId, key: string) {
    return {
      value: CountryCodes[key],
      label: getCountryNames(countryId, key),
    }
  }

  function getCountryOptionsWithoutDefaultOption(countryId: CountryId, key: string) {
    const countryCodes = { ...CountryCodes }
    delete countryCodes[key]
    const countryKeys = Object.keys(countryCodes)

    return countryKeys.map((key) => ({
      value: CountryCodes[key],
      label: getCountryNames(countryId, key),
    }))
  }
}

const getGenderOptions = (countryId: CountryId) => (Object.keys(genderCodes)).map((key) => ({
  value: genderCodes[key],
  label: getGenderNames(countryId, key),
}))

const shouldLoadValues = (
  person: PersonData,
  authenticated: boolean,
  shouldHaveBirthState?: boolean,
  states?: SelectOption[],
) => {
  if (shouldHaveBirthState) {
    return person && authenticated && (!shouldHaveBirthState || (shouldHaveBirthState && states.length > 0))
  }
  return person && authenticated
}

export const DocumentationPage: React.FC<DocumentationPageProps> = ({ api }) => {
  const {
    personId,
    countryId,
    companyId,
    tenantId,
    role,
    businessModel,
    functionId,
  } = useContext(IdentityContext)
  const messages = usePageMessages(Page.Documentation).messages as DocumentationMessages
  const {
    agreementProducts,
    shouldAcceptTermsWhenAuthenticated,
    shouldHaveBirthState,
    shouldDisplayPepOption,
    shouldDisplayEstablishmentInfo,
    shouldDisplayEthnicity,
  } = useTenantConfigs(tenantId, Page.Documentation) as DocumentationConfigs
  const { person } = useContext(FormContext)
  const {
    goToNextPage = () => {},
    goToPreviousPage = () => {},
  } = useContext<NavigationContextProps>(NavigationContext)
  const {
    authenticated,
  } = useContext(AuthenticatedUserContext)

  const [isThePersonMexican, setIsThePersonMexican] = useState(false)
  const { selectPromise, states } = useDomainState({
    getStates: api.getStates,
    countryId,
    shouldFetch: shouldHaveBirthState,
    key: 'acronym',
  })

  const nationalityOptions = useMemo(() => getNationalityOptions(countryId), [countryId])
  const genderOptions = useMemo(() => getGenderOptions(countryId), [countryId])
  const defaultValues: FormInputs = {
    birthday: null,
    gender: null,
    nationalityId: null,
    acceptedAgreements: false,
    stateOfBirth: null,
    admissionDate: null,
    endDate: null,
    establishmentId: null,
    ethnicityId: null,
    name: null,
    startDate: null,
  }
  const {
    formState, control, getValues, watch, setValue, trigger, reset, register,
  } = useForm<FormInputs>({
    mode: 'onTouched',
    defaultValues,
  })

  const [isLoading, setIsLoading] = useState(shouldHaveBirthState)
  const [agreementsId, setAgreementsId] = useState<string[]>([])
  const [userNeedsToAcceptTerms, setUserNeedsToAcceptTerms] = useState(true)
  const [unexpectedErrorMessage, setUnexpectedErrorMessage] = useState('')
  const [isUnexpectedErrorDialogOpen, setIsUnexpectedErrorDialogOpen] = useState<boolean>(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dataForm, setDataForm] = useState<GetDocumentationResponse>({} as GetDocumentationResponse)
  const canGoToNextPage = formState.isValid

  const nationality = watch('nationalityId')

  const shouldAcceptTerms = !authenticated || shouldAcceptTermsWhenAuthenticated

  useEffect(() => {
    if (shouldHaveBirthState && nationality) {
      if (nationality?.value === CountryCodes.nationality_mexican) {
        setIsThePersonMexican(true)
        register('stateOfBirth', { required: messages.requiredError })
        trigger('stateOfBirth')
      } else {
        setIsThePersonMexican(false)
        setValue('stateOfBirth', null)
        register('stateOfBirth', { required: false })
        trigger('stateOfBirth')
      }
    }
  }, [shouldHaveBirthState, nationality])

  useEffect(() => {
    async function fetchData() {
      if (shouldLoadValues(person, authenticated, shouldHaveBirthState, states)) {
        const { form } = await api.getDocumentation({ tenantId, personId })
        reset(formDefaultValues({
          ...form,
          genderOptions,
          nationalityOptions,
          stateOptions: states,
          ethnicityOptions,
        }))
      }
    }
    fetchData()
    setIsLoading(false)
  }, [api, authenticated, genderOptions, nationalityOptions, person, personId, reset, shouldHaveBirthState, states, tenantId])

  const parseFormValues = (values: FormInputs): CompleteDocumentationForm => ({
    birthday: dateStringToISO(values.birthday, true),
    gender: Number(values.gender.value),
    nationalityId: Number(values.nationalityId.value),
    establishmentId: (values.establishmentId && (Number(values.establishmentId.value))),
    admissionDate: (values.admissionDate && dateStringToISO(values.admissionDate)),
    ethnicityId: (values.ethnicityId && (Number(values.ethnicityId.value))),
    acceptedAgreementIds: agreementsId,
    ...((shouldHaveBirthState && isThePersonMexican) && {
      stateOfBirth: values.stateOfBirth.value.toString(),
    }),
    ...(!!values.name && {
      publicOffice: {
        name: values.name,
        startDate: dateStringToISO(values.startDate),
        endDate: dateStringToISO(values.endDate),
      },
    }),
  })

  const getCompleteDocumentationParams = (
    parsedValues: CompleteDocumentationForm,
  ): CompleteDocumentationRequestParams => ({
    path: { personId },
    headers: {
      role,
      functionId,
      businessModel,
      tenantId,
    },
    body: {
      form: {
        ...parsedValues,
        acceptedAgreementIds: agreementsId,
      },
    },
  })

  const handleNextButtonClick = async () => {
    setIsLoading(true)
    const values = getValues()
    const parsedValues = parseFormValues(values)
    const params = getCompleteDocumentationParams(parsedValues)

    await api.completeDocumentation(params).then(() => {
      goToNextPage()
    })
    .catch((response) => {
      setUnexpectedErrorMessage(response.data.error.message)
      setIsUnexpectedErrorDialogOpen(true)
    })
    setIsLoading(false)
  }

  const [isToShowPepFields, togglePepVisibility] = useState(false)
  const showPepFields = () => togglePepVisibility(true)
  const hidePepFields = () => togglePepVisibility(false)

  const onChangeRadioPep = ({ target }) => {
    const isToShowFields = target?.value === PepVisibility.Yes
    const isToHideFields = target?.value === PepVisibility.No

    if (isToShowFields) showPepFields()
    else if (isToHideFields) hidePepFields()
  }

  const isPepDatesValid = () => {
    const { startDate, endDate } = getValues()

    return isBefore(
      parse(startDate, 'dd/MM/yyyy', new Date()),
      parse(endDate, 'dd/MM/yyyy', new Date()),
    )
  }

  return (
    <Container
      previousButtonLabel={messages.previousButtonLabel}
      onPreviousButtonClick={goToPreviousPage}
      nextButtonLabel={messages.nextButtonLabel}
      onNextButtonClick={handleNextButtonClick}
      disableNextButton={!canGoToNextPage}
      isLoading={isLoading}
    >
      <CardHeader
        title={
          shouldAcceptTerms ? messages.titleForAcceptTerm : messages.titleForGeneralInformation
        }
        subtitle={
          shouldAcceptTerms
            ? messages.instructionsForAcceptTerm
            : messages.instructionsForGeneralInformation
        }
      />
      <CardContent>
        <StyledFormContainer>
          <StyledFormItem gridArea="birthday">
            <FormControl>
              <ControlledTextField
                id="birthday"
                data-testid="birthdayTest"
                label={messages.birthdayLabel}
                mask={[/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                control={control}
                placeholder={messages.birthdayPlaceholder}
                rules={{
                  required: messages.requiredError,
                  validate: {
                    invalidDate: (birthday) => isValid(parse(birthday, 'dd/MM/yyyy', new Date())) || messages.invalidDateError,
                    minAge: (birthday) => differenceInYears(new Date(), parse(birthday, 'dd/MM/yyyy', new Date())) >= 18 || messages.minAgeError,
                    maxAge: (birthday) => differenceInYears(new Date(), parse(birthday, 'dd/MM/yyyy', new Date())) <= 100 || messages.maxAgeError,
                  },
                }}
              />
            </FormControl>
          </StyledFormItem>
          <StyledFormItem gridArea="gender">
            <FormControl>
              <ControlledSelect
                aria-label={messages.genderLabel}
                id="gender"
                label={messages.genderLabel}
                placeholder={messages.genderPlaceholder}
                options={genderOptions}
                rules={{
                  required: messages.requiredError,
                }}
                control={control}
              />
            </FormControl>
          </StyledFormItem>
          <StyledFormItem gridArea="nationality">
            <ControlledSelect
              aria-label={messages.nationalityLabel}
              id="nationalityId"
              label={messages.nationalityLabel}
              placeholder={messages.nationalityPlaceholder}
              options={nationalityOptions}
              rules={{
                required: messages.requiredError,
              }}
              control={control}
            />
          </StyledFormItem>
          { shouldDisplayEstablishmentInfo && (
            <>
              <StyledFormItem gridArea="establishmentId">
                <FormControl>
                  <ControlledSelect
                    aria-label={messages.establishmentLabel}
                    id="establishmentId"
                    label={messages.establishmentLabel}
                    placeholder={messages.establishmentPlaceholder}
                    options={genderOptions}
                    rules={{
                      required: messages.requiredError,
                    }}
                    control={control}
                  />
                </FormControl>
              </StyledFormItem>
              <StyledFormItem gridArea="admissionDate">
                <FormControl>
                  <ControlledTextField
                    id="admissionDate"
                    data-testid="admissionDateTest"
                    label={messages.admissionDateLabel}
                    mask={[/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                    control={control}
                    placeholder={messages.admissionDatePlaceholder}
                    rules={{
                      required: messages.requiredError,
                      validate: {
                        invalidDate: (date) => isValid(parse(date, 'dd/MM/yyyy', new Date())) || messages.invalidDateError,
                      },
                    }}
                  />
                </FormControl>
              </StyledFormItem>
            </>
          )}
          {
            shouldHaveBirthState && (
              <StyledFormItem gridArea="state">
                <FormControl>
                  <AsyncSelect
                    id="stateOfBirth"
                    label={messages.birthStateLabel}
                    aria-label={messages.birthStateLabel}
                    placeholder={messages.birthStatePlaceholder}
                    rules={{ required: isThePersonMexican && messages.requiredError }}
                    disabled={!isThePersonMexican}
                    control={control}
                    optionsPromise={selectPromise}
                  />
                </FormControl>
              </StyledFormItem>
            )
          }
          { shouldDisplayPepOption && (
            <>
              <StyledFormItem gridArea="pepVisibility">
                <Spacing mt="standard" mb="tiny">
                  <InputLabel style={{ position: 'relative', lineHeight: '0' }}>
                    { messages.pepVisibility.radioLabel }
                    <Icon
                      name="outlined-action-help"
                      color="primary"
                      size="tiny"
                      style={{ position: 'relative', top: '8px' }}
                    />
                  </InputLabel>
                </Spacing>

                <Spacing ml="tiny">
                  <RadioGroup onChange={onChangeRadioPep}>
                    <FormControlLabel
                      control={<Radio />}
                      data-testid="radio-pep-hidden"
                      label={messages.pepVisibility.radioOptions.no}
                      value={PepVisibility.No}
                      checked={!isToShowPepFields}
                    />
                    <FormControlLabel
                      control={<Radio />}
                      data-testid="radio-pep-show"
                      label={messages.pepVisibility.radioOptions.yes}
                      value={PepVisibility.Yes}
                    />
                  </RadioGroup>
                </Spacing>
              </StyledFormItem>

              { isToShowPepFields && (
                <>
                  <StyledFormItem gridArea="pepName">
                    <FormControl>
                      <ControlledTextField
                        id="name"
                        label={messages.publicOffice.name}
                        control={control}
                        rules={{ required: messages.requiredError }}
                      />
                    </FormControl>
                  </StyledFormItem>

                  <StyledFormItem gridArea="pepStartDate">
                    <FormControl>
                      <ControlledTextField
                        id="startDate"
                        label={messages.publicOffice.startDate}
                        mask={[/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                        control={control}
                        placeholder={messages.birthdayPlaceholder}
                        rules={{
                          required: messages.requiredError,
                          validate: {
                            invalidDate: (birthday) => isValid(parse(birthday, 'dd/MM/yyyy', new Date())) || messages.invalidDateError,
                          },
                        }}
                      />
                    </FormControl>
                  </StyledFormItem>

                  <StyledFormItem gridArea="pepEndDate">
                    <FormControl>
                      <ControlledTextField
                        id="endDate"
                        label={messages.publicOffice.endDate}
                        mask={[/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                        control={control}
                        placeholder={messages.birthdayPlaceholder}
                        rules={{
                        required: messages.requiredError,
                          validate: {
                            invalidDate: (birthday) => isValid(parse(birthday, 'dd/MM/yyyy', new Date())) || messages.invalidDateError,
                            isAfter: () => isPepDatesValid() || messages.publicOffice.error.endDateError,
                          },
                        }}
                      />
                    </FormControl>
                  </StyledFormItem>
                </>
              )}
            </>
          )}
          { shouldDisplayEthnicity && (
            <StyledFormItem gridArea="ethnicity">
              <FormControl>
                <ControlledSelect
                  aria-label={messages.ethnicityLabel}
                  id="ethnicityId"
                  label={messages.ethnicityLabel}
                  placeholder={messages.ethnicityPlaceholder}
                  options={ethnicityOptions}
                  rules={{
                      required: messages.requiredError,
                    }}
                  control={control}
                />
              </FormControl>
            </StyledFormItem>
          )}
          {
            shouldAcceptTerms && (
              <StyledFormItem gridArea="terms">
                <FormControl>
                  <Controller
                    name="acceptedAgreements"
                    control={control}
                    rules={{ required: messages.requiredError }}
                    render={({
                      onChange, onBlur, value, ref,
                    }) => (
                      <AcceptTerms
                        countryId={countryId}
                        companyId={companyId}
                        products={agreementProducts}
                        personId={personId}
                        setAgreementId={setAgreementsId}
                        shouldHide={!userNeedsToAcceptTerms}
                        setUserNeedsToAcceptTerms={setUserNeedsToAcceptTerms}
                        dialogInfo={{
                          title: messages.modal.title,
                          acceptText: messages.modal.titleAcceptLabel,
                          closeText: messages.modal.titleCloseLabel,
                        }}
                        checkbox={{
                          color: 'secondary',
                          normalLabel: messages.checkboxLabel,
                          highlightedLabel: messages.checkboxLabelLink,
                          acceptTermsAriaLabel: messages.acceptTermsAriaLabel,
                          onChange: (e) => {
                            onBlur()
                            onChange(e)
                          },
                          onBlur,
                          value,
                          inputRef: ref,
                        }}
                      />
                    )}
                  />
                </FormControl>
              </StyledFormItem>
            )
          }
        </StyledFormContainer>
        <DialogAlert
          show={isUnexpectedErrorDialogOpen}
          messages={{ infoText: unexpectedErrorMessage, closeButtonText: messages.dialog.closeButtonText }}
          closeDialogCallback={() => setIsUnexpectedErrorDialogOpen(false)}
        />
      </CardContent>
    </Container>
  )
}
