import React, { useContext } from 'react'
import { ProgressIndicator } from '@/main/components/progress-indicator'
import { Typography } from '@/main/components/typography'

import styled from 'styled-components'

import { IdentityContext } from '@/main/contexts/identity-context'
import { COUNTRIES_IDS_KEYS, CountryIdAlpha2 } from '@/domain/models/country'

import { StepConfirmation } from '@/main/pages/digital-store/pages/digital-store-status-page/components/digital-store-status/digital-store-status-page-self-service-helper/step-confirmation'
import ResetInstructions from '@/main/assets/gifs/instructions-account-reset.gif'
import { useGetPersonData } from '@/main/pages/digital-store/hooks/use-get-person-data'
import { minDeviceSizeForMediaQueries } from '@/utils/device-sizes'

import { usePageMessages } from '@/main/hooks/usePageMessages'
import { DigitalStoreStatusAccountResetStepMessages } from '@/domain/models/messages/messages'
import { Page } from '@/domain/models/page/page'
import { useTenantVendor } from '@/main/hooks/useTenantVendor'
import { useAccountBindLink } from '@/main/pages/digital-store/hooks/use-get-account-bind-link'
import { LocalConvertCountryIdFromAlpha3ToAlpha2 } from '@/data/use-cases/country/local-convert-country-id-from-alpha-3-to-alpha-2'

const StyledContainer = styled.section`
  --marginHorizontal: -16px;
  margin-left: var(--marginHorizontal);
  margin-right: var(--marginHorizontal);

  padding: 12px 32px;
  text-align: left;
  background-color: #EEEEEE;
  margin-bottom: 32px;

  @media ${minDeviceSizeForMediaQueries.tablet} {
    --marginHorizontal: 0;
  }
`
export const AccountResetStep = () => {
  const {
    countryId, personId, companyId, tenantId,
  } = useContext(IdentityContext)
  const vendor = useTenantVendor(tenantId)
  const pageMessagesOptions = { vendorName: vendor.name }
  const messages = usePageMessages(Page.DigitalStoreStatusAccountResetStep, pageMessagesOptions).messages as DigitalStoreStatusAccountResetStepMessages

  const countryAlpha2 = new LocalConvertCountryIdFromAlpha3ToAlpha2(
    COUNTRIES_IDS_KEYS,
  ).convert({ countryId })

  const { accountBindLinkData, accountBindLinkLoading } = useAccountBindLink({
    personId,
    countryCode: countryAlpha2 as CountryIdAlpha2,
    companyId,
  })

  const { personData, personDataLoading } = useGetPersonData({
    relations: ['emails'],
    personId,
    countryId,
    companyId,
  })
  const userEmail = personData?.person?.emails[0]?.email

  const onConfirm = () => window.open(accountBindLinkData, '_blank')

  if (accountBindLinkLoading || personDataLoading) return <ProgressIndicator />
  return (
    <StepConfirmation
      title={messages.title}
      confirmText={messages.buttonConfirmLabel}
      isFloatingButton
      onConfirm={onConfirm}
    >
      <>
        <StyledContainer>
          <Typography>
            <b>
              {messages.accountInformations.emailLabel}
              :
            </b>
            {' '}
            {userEmail}
          </Typography>
          <Typography>
            <b>
              {messages.accountInformations.keyLabel}
              :
            </b>
            {' '}
            {messages.accountInformations.keyLabelHint}
          </Typography>
        </StyledContainer>
        <Typography variant="body1" color="textSecondary" align="center" style={{ marginBottom: '32px' }}>
          {messages.hint.text}
        </Typography>
        <img
          alt={messages.hint.imageAlt}
          src={ResetInstructions}
          width="182px"
        />
      </>
    </StepConfirmation>
  )
}
