import { BadRequestError, NotFoundError } from '@/data/protocols/errors'
import { HttpGetClient, HttpStatusCode } from '@/data/protocols/http'
import { UnexpectedError } from '@/domain/errors'
import { BUSINESS_MODELS } from '@/domain/models/person'
import { TenantId } from '@/domain/models/tenant/tenant-id'
import {
  GetAddressByZipCode,
  GetAddressByZipCodeParams,
  GetAddressByZipCodeResponse,
} from '@/domain/use-cases/address/zip-code'

export type GetAddressByZipCodeHttpRequestHeaders = {
  'x-api-key': string
  businessmodel: BUSINESS_MODELS
  tenantid: TenantId
}

export type GetAddressByZipCodeHttpRequestResponse = GetAddressByZipCodeResponse

export class RemoteGetAddressByZipCode implements GetAddressByZipCode {
  constructor(
    private readonly httpGetClient: HttpGetClient<
      GetAddressByZipCodeHttpRequestHeaders,
      GetAddressByZipCodeHttpRequestResponse
    >,
    private readonly xApiKey: string
  ) {}

  get = async (params: GetAddressByZipCodeParams): Promise<GetAddressByZipCodeResponse> => {
    const { businessModel, code, tenantId } = params

    const headers: GetAddressByZipCodeHttpRequestHeaders = {
      'x-api-key': this.xApiKey,
      businessmodel: businessModel,
      tenantid: tenantId,
    }

    const httpResponse = await this.httpGetClient.get({
      url: `/address/zipcode/${code}`,
      headers,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body
      case HttpStatusCode.badRequest:
        throw new BadRequestError(httpResponse.body)
      case HttpStatusCode.notFound:
        throw new NotFoundError(httpResponse.body)
      default:
        throw new UnexpectedError()
    }
  }
}
