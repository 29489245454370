import {
  Button, FormHelperText, Typography,
} from '@naturacosmeticos/natds-web'
import React, {
 SyntheticEvent, useEffect, useState,
} from 'react'
import { useUploadFileStyles } from '@/main/components/upload-file/upload-file.styles'

export type ValidationStatus = undefined | 'error' | 'success'
export type ValidationResult = { status: ValidationStatus, message?: string }
export type UploadFileProps = {
  title: string
  subtitle: string
  id: string
  uploadButtonName: string
  changeButtonName: string
  customMessage?: string
  status?: ValidationStatus
  fileMaxSize: number
  maxSizeErrorMessage: string
  invalidFileFormatErrorMessage: string
  uploadCallback: (file: File) => Promise<void> | void
  accept?: string
  dataTestId?: string
  className?: string
  setAttachmentState: (validation: ValidationResult) => void
}

export const UploadFile: React.FC<UploadFileProps> = ({
  id,
  title,
  subtitle,
  uploadButtonName,
  changeButtonName,
  fileMaxSize,
  uploadCallback,
  maxSizeErrorMessage,
  status = undefined,
  customMessage,
  invalidFileFormatErrorMessage,
  dataTestId,
  className,
  accept = 'image/*',
  setAttachmentState,
}) => {
  const [thumbnail, setThumbnail] = useState(null)
  const componentClasses = useUploadFileStyles({ validationStatus: status })

  useEffect(() => {
    setAttachmentState({ status, message: customMessage })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, customMessage])

  const handleOnInputChange = async (event: SyntheticEvent) => {
    const { files } = event.target as HTMLInputElement
    if (files.length === 0) {
      setAttachmentState({ status: undefined, message: null })
      return
    }
    const file = files[0]

    if (!file.type.match(accept)) {
      setAttachmentState({ status: 'error', message: invalidFileFormatErrorMessage })
      setThumbnail(null)
      return
    }

    if (file.size <= fileMaxSize) {
      setAttachmentState({ status: undefined, message: null })
      uploadCallback(file)
    } else {
      setAttachmentState({ status: 'error', message: maxSizeErrorMessage })
      return
    }

    const thumbnail = await toBase64(file)
    setThumbnail(thumbnail)
  }

  return (
    <div data-testid={dataTestId} className={className}>
      <div style={{ display: 'flex' }}>
        <div className={`${componentClasses.uploadContainer}`}>
          {thumbnail && <Thumbnail thumbnail={thumbnail} />}
          <div className={componentClasses.contentContainer}>
            <Typography variant="overline" color="textSecondary">{title}</Typography>
            {!thumbnail && <Typography className={componentClasses.subtitle} variant="body2">{subtitle}</Typography>}
            <form>
              <input
                id={`input-${id}`}
                name={`input-${id}`}
                type="file"
                accept={accept}
                data-testid={`input-${id}`}
                onChange={handleOnInputChange}
                hidden
              />
              <label htmlFor={`input-${id}`} className={componentClasses.uploadButton}>
                <Button
                  component={'span' as any}
                  variant={getButtonStyle({ status, thumbnail })}
                  color="primary"
                >
                  {thumbnail ? changeButtonName : uploadButtonName}
                </Button>
              </label>
            </form>
          </div>
        </div>
      </div>
      {customMessage && (
        <FormHelperText state={status}>
          <div data-testid={`message-${id}`}>{customMessage}</div>
        </FormHelperText>
      )}
    </div>
  )
}

const Thumbnail = ({ thumbnail }) => {
  const componentClasses = useUploadFileStyles()

  return (
    <figure className={componentClasses.thumbnailContent}>
      <picture>
        <img
          data-testid="thumbnail-image"
          alt=""
          src={thumbnail}
          className={componentClasses.thumbnail}
        />
      </picture>
    </figure>
)
}

type GetButtonStyleParams = {status: string | undefined, thumbnail?: string | null}
export const getButtonStyle = ({ status, thumbnail }: GetButtonStyleParams): 'contained' | 'outlined' => {
  if ((!thumbnail && status === undefined) || status === 'error') {
    return 'contained'
  }
  return 'outlined'
}

const toBase64 = (file) => new Promise(((resolve, reject) => {
  const reader = new FileReader()

  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = (error) => reject(error)
}))
