import { IdentityContext, NavigationContext, NavigationContextProps } from '@/main/contexts'
import { Container } from '@/main/components/pages-structures'
import { DigitalStoreMessages } from '@/domain/models/messages/messages'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { Page } from '@/domain/models/page/page'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import { DigitalStoreConfigs } from '@/domain/models/tenant/tenant-configs'
import { useTenantVendor } from '@/main/hooks/useTenantVendor'
import React, {
  useCallback,
  useContext, useEffect, useMemo, useState,
} from 'react'
import { CustomChangeEvent } from '@/main/components/text-field/helper/handle-onchange'
import { useForm } from 'react-hook-form'
import {
  useDigitalStorePageStyles,
  Content,
} from './digital-store-page.styles'
import { UrlPreview } from './components/url-preview'
import { Advantages } from './components/advantages'
import { DigitalStorePageApi } from './api/make-digital-store-page-api'
import NameValidation from './components/name-validation'
import { ExtraEntries } from './components/extra-entries'
import SaveErrorDialog from './components/save-error-dialog'
import { EmailInfoRegistration } from './components/email-info-registration'
import { Header } from './components/header'
import { AgreementsTerms } from './components/agreements-terms/agreements-terms'
import { useDigitalStore } from './hooks/use-digital-store'

export type DigitalStorePageProps = {
  convergence?: boolean,
  onSave?: () => void,
  api: DigitalStorePageApi
}

type Debounce = {
  delay: number
  countdown: any
}

export type DigitalStoreFormInputs = {
  clabe: string,
  rfc: string,
}[]

const delay = (size: number) => new Promise((resolve) => {
  setTimeout(() => resolve(null), size)
})

export const DigitalStorePage = ({ convergence = false, onSave, api }: DigitalStorePageProps) => {
  const digitalStorePageClasses = useDigitalStorePageStyles()

  const {
    tenantId,
  } = useContext(IdentityContext)

  const {
    goToNextPage,
    goToPreviousPage,
  } = useContext<NavigationContextProps>(NavigationContext)

  const {
    acceptTermsStrategy,
    showEmailInfoRegistration,
    showWarningAfterGetSubmitError,
    digitalStoreNameMaxOfChars,
    shouldUnlinkAgreements,
  } = useTenantConfigs(tenantId, Page.DigitalStore) as DigitalStoreConfigs

  const {
    name: vendorName,
  } = useTenantVendor(tenantId)

  const {
    document: documentMessages,
    ...messages
  } = usePageMessages(Page.DigitalStore, { vendorName }).messages as DigitalStoreMessages

  const advantagesMessages = {
    title: messages.advantages.title,
    items: [
      { title: messages.advantages.firstItemTitle, content: messages.advantages.firstItemDescription },
      { title: messages.advantages.secondItemTitle, content: messages.advantages.secondItemDescription },
      { title: messages.advantages.thirdItemTitle, content: messages.advantages.thirdItemDescription },
    ],
  }

  const formMethods = useForm<DigitalStoreFormInputs>({
    mode: 'onTouched',
  })

  const {
    getValues,
    setValue,
    formState,
    trigger,
  } = formMethods

  const [isSaveErrorDialogOpen, setIsSaveErrorDialogOpen] = useState(false)

  const {
    save,
    isSaved,
    errorOnCheckAvailability,
    isLoading,
    agreements,
    errorOnSave,
    setHasError,
    isAvailable,
    helperTextKey,
    isTermsAccepted,
    setHelperTextKey,
    unlinkAgreements,
    savedExtraEntries,
    shouldDisableInput,
    checkAvailability,
    setIsTermsAccepted,
    alreadyHadSavedName,
    isLoadingAgreements,
    shouldHideAgreements,
    shouldShowSkipButton,
    hasSavedExtraEntries,
    shouldEnableSaveButton,
    userNeedsToAcceptTerms,
    currentDigitalStoreName,
    setUserNeedsToAcceptTerms,
    shouldDisableVerifyButton,
    setCurrentDigitalStoreName,
    lastCheckedDigitalStoreName,
    extraEntriesFromTenantConfig,
    hasExtraEntriesFromTenantConfig,
    showLoadWhileSavingDigitalStore,
  } = useDigitalStore({
    api,
    convergence,
    extraEntriesForm: formState,
  })

  useEffect(() => {
    if (errorOnSave) {
      setIsSaveErrorDialogOpen(true)
    }
  }, [errorOnSave])

  useEffect(() => {
    const hasExtraEntries = hasExtraEntriesFromTenantConfig && hasSavedExtraEntries

    const setExtraEntriesValues = (extraEntries: {}) => {
      Object.keys(extraEntries).forEach((entryKey) => {
        setValue(entryKey, extraEntries[entryKey])
      })
      trigger()
    }

    if (hasExtraEntries) {
      setExtraEntriesValues(savedExtraEntries)
    }
  }, [hasExtraEntriesFromTenantConfig, hasSavedExtraEntries, savedExtraEntries, setValue, trigger])

  useEffect(() => {
    if (isSaved && !errorOnSave) {
      if (!convergence) {
        goToNextPage()
      } else {
        onSave()
      }
    }
  }, [convergence, goToNextPage, isSaved, onSave, errorOnSave])

  const handleCheckAvailability = () => {
    checkAvailability(currentDigitalStoreName)
  }

  const handleSaveDigitalStore = () => {
    const extraEntriesFromForm = getValues() || {}
    save(extraEntriesFromForm)
  }

  const debounce = useMemo<Debounce>(() => ({
    delay: 200,
    countdown: 0,
  }), [])

  const handleOnChangeInput = (event: CustomChangeEvent) => {
    const { value } = event.target

    const hasValidNameCharacters = (name: string) => (
      name.match(/^[a-z0-9_]+$/i) !== null
    )

    const hassPassedMaxNumOfChars = (name: string, maxNum:number) => {
      let hassPassed = false
      if (maxNum) {
        hassPassed = name.length > maxNum
      }
      return hassPassed
    }

    const onDoneHandleChangeInput = (error: boolean, helperTextKey: string) => {
      setHasError(error)
      setHelperTextKey(helperTextKey)
    }

    const resetDigitalStoreStates = async () => {
      await delay(100)
      onDoneHandleChangeInput(false, '')
    }

    setCurrentDigitalStoreName(value)

    clearTimeout(debounce.countdown)
    debounce.countdown = setTimeout(() => {
      if (hasValidNameCharacters(value) && !hassPassedMaxNumOfChars(value, digitalStoreNameMaxOfChars)) {
        resetDigitalStoreStates()
      } else {
        onDoneHandleChangeInput(true, 'invalidNameSpace')
      }
    }, debounce.delay)
  }

  const handleNextPage = () => {
    if (alreadyHadSavedName && !convergence) {
      goToNextPage()
    } else {
      handleSaveDigitalStore()
    }
  }

  const handleSkipButtonClick = async () => {
    try {
      if (shouldUnlinkAgreements) {
        await unlinkAgreements()
      }

      goToNextPage()
    } catch (e) {
      console.error(e)
    }
  }

  const checkDigitalStoreNameState = () => {
    if (errorOnCheckAvailability) return 'error'
    if (isAvailable) return 'success'
    return undefined
  }

  const getErrorMessages = useCallback((): string | undefined => {
    const errorIndex: string[] = ['unavailableName', 'invalidNameSpace', 'genericError', 'errorSaving']
    if (!!helperTextKey && errorIndex.includes(helperTextKey)) {
      return messages[helperTextKey]
    }
    return undefined
  }, [helperTextKey, messages])

  return (
    <Container
      previousButtonLabel={!convergence ? messages.previousButtonLabel : ''}
      onPreviousButtonClick={goToPreviousPage}
      nextButtonLabel={messages.nextButtonLabel}
      onNextButtonClick={handleNextPage}
      disableNextButton={!shouldEnableSaveButton}
      showSkipButton={shouldShowSkipButton}
      skipButtonLabel={messages.skipButtonLabel}
      onSkipButtonClick={handleSkipButtonClick}
      isLoading={showLoadWhileSavingDigitalStore}
    >
      <Content>
        <Header
          title={!convergence ? messages.title : messages.convergenceTitle}
          subheader={!convergence ? messages.description : messages.convergenceDescription}
          classes={{
            root: digitalStorePageClasses.cardHeader,
            title: digitalStorePageClasses.title,
            subheader: digitalStorePageClasses.subtitle,
          }}
        />
        <Advantages
          shouldHide={!convergence}
          title={advantagesMessages.title}
          items={advantagesMessages.items}
        />
        <SaveErrorDialog
          open={isSaveErrorDialogOpen}
          message={messages.dialogError.message}
          onClickOk={() => setIsSaveErrorDialogOpen(false)}
          okButtonLabel={messages.dialogError.buttonOk}
          shouldRender={showWarningAfterGetSubmitError}
        />
        <NameValidation
          label={messages.nameLabel}
          tooltip={!convergence ? messages.nameTip : undefined}
          buttonLabel={messages.verifyButtonLabel}
          buttonIsLoading={isLoading}
          infoText={messages.hint}
          errorMessage={getErrorMessages()}
          buttonOnClick={() => handleCheckAvailability()}
          buttonOnChange={() => setHelperTextKey('')}
          onChangeInput={handleOnChangeInput}
          shouldButtonBeDisabled={shouldDisableVerifyButton}
          disabled={shouldDisableInput}
          isSuccess={checkDigitalStoreNameState() === 'success'}
          invalid={checkDigitalStoreNameState() === 'error'}
          successMessage={checkDigitalStoreNameState() === 'success' ? messages.availableName : undefined}
          inputValue={currentDigitalStoreName}
        />
        <UrlPreview
          shouldHide={!isAvailable}
          label={messages.addressLabel}
          url={`${messages.baseUrl}${lastCheckedDigitalStoreName}`}
        />
        <ExtraEntries
          shouldRender={hasExtraEntriesFromTenantConfig}
          disableFields={isLoading}
          fields={extraEntriesFromTenantConfig}
          fieldsTexts={documentMessages}
          formMethods={formMethods}
        />
        <AgreementsTerms
          isLoading={isLoadingAgreements}
          shouldHide={shouldHideAgreements}
          acceptTermsStrategy={acceptTermsStrategy}
          acceptTermsProps={{
            agreements,
            setUserNeedsToAcceptTerms,
            shouldFetch: false,
            shouldHide: !userNeedsToAcceptTerms,
            termsInfo: messages.terms.termsInfo,
            dialogInfo: {
              title: messages.terms.modalTitle,
              acceptText: messages.terms.modalAccept,
              closeText: messages.terms.modalClose,
            },
            checkbox: {
              color: 'secondary',
              normalLabel: messages.terms.agreementLabel,
              highlightedLabel: messages.terms.agreementLabelLink,
              value: isTermsAccepted,
              onChange: (isAccepted) => setIsTermsAccepted(isAccepted),
            },
          }}
        />
        <EmailInfoRegistration
          email={messages.emailInfoRegistration}
          shouldHide={showEmailInfoRegistration}
        />
      </Content>
    </Container>
  )
}
