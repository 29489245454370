import { HttpStatusCode, HttpPatchClient } from '@/data/protocols/http'
import { UpdatePersonSubstatus, UpdatePersonSubstatusParams, UpdatePersonSubstatusOutput } from '@/domain/use-cases/person'
import { UnexpectedError } from '@/domain/errors'

import {
  BadRequestError, ConflictError, NotFoundError, ForbiddenError,
} from '@/data/protocols/errors'
import { BUSINESS_MODELS, ROLES, FUNCTION_IDS } from '@/domain/models/person'
import { CountryId } from '@/domain/models/country'
import { CompanyId } from '@/domain/models/company/company-id'

export type UpdatePersonSubstatusHttpRequestHeaders = {
  'x-api-key': string,
  functionid: FUNCTION_IDS,
  role: ROLES,
  businessmodel: BUSINESS_MODELS,
  sourcesystem: string,
  countryid: CountryId,
  companyid: CompanyId,
}

export type UpdatePersonSubstatusHttpRequestResponseBody = UpdatePersonSubstatusOutput

export class RemoteUpdatePersonSubstatus implements UpdatePersonSubstatus {
  constructor(
    private readonly url: string,
    private readonly httpPatchClient: HttpPatchClient<
      UpdatePersonSubstatusHttpRequestHeaders,
      UpdatePersonSubstatusHttpRequestResponseBody
    >,
    private readonly xApiKey: string,
  ) { }

  update = async (params: UpdatePersonSubstatusParams): Promise<UpdatePersonSubstatusOutput> => {
    const headers: UpdatePersonSubstatusHttpRequestHeaders = {
      'x-api-key': this.xApiKey,
      functionid: params.functionId,
      role: params.role,
      businessmodel: params.businessModel,
      sourcesystem: params.sourceSystem,
      countryid: params.countryId,
      companyid: params.companyId,
    }

    const httpResponse = await this.httpPatchClient.patch({
      url: this.url.replace(/:personId/i, params.personId).replace(/:substatusId/i, String(params.substatusId)),
      headers,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return httpResponse.body
      case HttpStatusCode.notFound:
        throw new NotFoundError(httpResponse.body)
      case HttpStatusCode.badRequest:
        throw new BadRequestError(httpResponse.body)
      case HttpStatusCode.conflict:
        throw new ConflictError(httpResponse.body)
      case HttpStatusCode.forbidden:
        throw new ForbiddenError(httpResponse.body)
      default:
        throw new UnexpectedError()
    }
  }
}
