import { MyProfileMessages } from '@/domain/models/messages/messages'
import { TFunction } from 'i18next'

export function getMyProfile(translate: TFunction): MyProfileMessages {
    return {
        container: {
            myData: translate('myProfilePage.container.myData'),
            myLeader: translate('myProfilePage.container.myLeader'),
            digitalSpace: translate('myProfilePage.container.digitalSpace'),
        },
        contact: {
          title: translate('myProfilePage.contact.title'),
          phone: translate('myProfilePage.contact.phone'),
          email: translate('myProfilePage.contact.email'),
          tooltipPhone: translate('myProfilePage.contact.tooltipPhone'),
        },
        addresses: {
          title: translate('myProfilePage.addresses.title'),
          zipCode: translate('myProfilePage.addresses.zipCode'),
          addressJoin: translate('myProfilePage.addresses.addressJoin'),
          reference: translate('myProfilePage.addresses.reference'),
          withoutInformation: translate('myProfilePage.addresses.withoutInformation'),
        },
        consultantData: {
            naturaCode: translate('myProfilePage.consultantData.naturaCode'),
            enterSocialName: translate('myProfilePage.consultantData.enterSocialName')
        },
        digitalSpace: {
          myDigitalSpace: translate('myProfilePage.digitalSpace.myDigitalSpace'),
          socialMediaShare: translate('myProfilePage.digitalSpace.socialMediaShare'),
          welcomeTitle: translate('myProfilePage.digitalSpace.welcomeTitle'),
          defaultWelcomeMessage: translate('myProfilePage.digitalSpace.defaultWelcomeMessage'),
          digitalSpaceShare: translate('myProfilePage.digitalSpace.digitalSpaceShare'),
          digitalSpaceButton: translate('myProfilePage.digitalSpace.digitalSpaceButton'),
          copyLinkTooltip: translate('myProfilePage.digitalSpace.copyLinkTooltip'),
          createNewSpaceMessage: translate('myProfilePage.digitalSpace.createNewSpaceMessage'),
          createNewSpaceButton: translate('myProfilePage.digitalSpace.createNewSpaceButton'),
        },
        editButton: translate('myProfilePage.editButton'),
    }
}
