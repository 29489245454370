import { ControlledTextField } from '@/main/components'
import { RequiredErrorMessage } from '@/domain/models/messages/messages.d'

import React from 'react'
import { useFormContext } from 'react-hook-form'
import { validateEmail } from './validate-email'

export type EmailMessages = {
  label: string,
  placeholder: string,
  errorMessage: string,
  infoText?: string,
} & RequiredErrorMessage

export type EmailCustomProps = {
  shouldInfoTextBeVisible: boolean
  showAsteriskInRequiredLabel?: boolean
  icon?: Object
}

export type EmailTextFieldProps = {
  id?: string;
  messages: EmailMessages;
  customProps?: EmailCustomProps;
}

export const EmailTextField: React.FC<EmailTextFieldProps> = ({
  messages, id = 'email', customProps = { shouldInfoTextBeVisible: false },
}) => {
  const { control } = useFormContext()
  return (
    <ControlledTextField
      type="email"
      id={id}
      label={messages.label}
      control={control}
      placeholder={messages.placeholder}
      errorMessage={messages.errorMessage}
      rules={{ required: messages.requiredErrorMessage as string, validate: validateEmail }}
      infoText={customProps.shouldInfoTextBeVisible ? messages.infoText : undefined}
      showAsterisk={customProps.showAsteriskInRequiredLabel}
      required
      icon={customProps.icon}
    />
  )
}
