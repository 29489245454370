import React from 'react'
import Slider from 'react-slick'
import { SLIDER_SETTINGS } from '@/main/pages/confirmation/loading/loading-slider/loading-slider-settings'
import {
  SlideContainer, SlideDescription,
  SlideInfo, SlideTitle,
} from '@/main/pages/confirmation/loading/loading-slider/loading-slider.styles'

export const LoadingSlider = ({ slidesItems, messages }) => (
  <Slider {...SLIDER_SETTINGS}>
    {slidesItems.map((slide) => (
      <div key={slide.name}>
        <SlideContainer>
          <img
            src={slide.icon}
            aria-label={slide.name}
            data-testid={slide.name}
          />
          <SlideInfo>
            <SlideTitle variant="h6" color="textPrimary" className="slide_title">{messages[slide.translationKey].title}</SlideTitle>
            <SlideDescription variant="body2">{messages[slide.translationKey].description}</SlideDescription>
          </SlideInfo>
        </SlideContainer>
      </div>
    ))}
  </Slider>
)
