import { SaveConsultantInfoParams, SaveConsultantInfoResponse } from '@/domain/use-cases/register-maintenance/save-consultant-info'
import { makeGetConsultantInfo } from '@/main/factories/remote/register-maintenance/make-remote-get-consultant-info'
import { makeSaveConsultantInfo, makeSaveConsultantInfoBasicData } from '@/main/factories/remote/register-maintenance/make-remote-save-consultant-info'
import { GetPersonDataParams, PersonDataResponse, PersonData } from '@/domain/use-cases/person/get-person-data'

export type RegisterMaitenancePageApi = {
  getConsultantInfo: (data: GetPersonDataParams) => Promise<PersonData | PersonDataResponse | {} | null | unknown>
  saveConsultantInfo: (data: SaveConsultantInfoParams) => Promise<SaveConsultantInfoResponse>
  saveConsultantInfoBasicData: (data: SaveConsultantInfoParams) => Promise<SaveConsultantInfoResponse>
}

export const makeRegisterMaitenancePageApi = (): RegisterMaitenancePageApi => {
  const remoteGetConsultantInfo = makeGetConsultantInfo()
  const getConsultantInfo = (data: GetPersonDataParams) => remoteGetConsultantInfo.get(data)

  const remoteSaveConsultantInfo = makeSaveConsultantInfo()
  const saveConsultantInfo = (data: SaveConsultantInfoParams) => remoteSaveConsultantInfo.update(data)

  const remoteSaveConsultantInfoBasicData = makeSaveConsultantInfoBasicData()
  const saveConsultantInfoBasicData = (data: SaveConsultantInfoParams) => remoteSaveConsultantInfoBasicData.updateBasicData(data)

  return {
    getConsultantInfo,
    saveConsultantInfo,
    saveConsultantInfoBasicData,
  }
}
