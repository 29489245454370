import { ControlledTextField } from '@/main/components'
import { Checkbox, CheckboxProps } from '@/main/components/checkbox'
import React, { useEffect, useState } from 'react'
import { ControllerProps, useFormContext } from 'react-hook-form'

type ControlledAddressNumberFieldProps = {
  id: string
  label: string
  placeholder?: string
  noNumberLabel: string
  showAsterisk?: boolean
  validChars?: RegExp
  disabled?: boolean
  checkbox?: Pick<CheckboxProps, 'color'>
  hasNoNumberCheckbox?: boolean
} & Pick<ControllerProps<'input'>, 'rules'>

export const ControlledAddressNumberField: React.FC<ControlledAddressNumberFieldProps> = ({
  id,
  label,
  placeholder,
  rules,
  noNumberLabel,
  showAsterisk,
  validChars,
  disabled = false,
  hasNoNumberCheckbox = true,
  checkbox = { color: 'secondary' },
}) => {
  const { control, setValue, watch } = useFormContext()
  const numberValue = watch(id)
  const [noNumber, setNoNumber] = useState<boolean>(numberValue === 'S/N')

  const handleNoNumber = (event) => {
    const checked = Boolean(event.target.checked)

    setNoNumber(checked)

    const numberValue = checked ? 'S/N' : ''

    setValue(id, numberValue, { shouldValidate: true, shouldDirty: true })
  }

  useEffect(() => {
    setNoNumber(numberValue === 'S/N')
  }, [numberValue])

  return (
    <div>
      <ControlledTextField
        id={id}
        label={label}
        control={control}
        rules={rules}
        placeholder={placeholder}
        disabled={disabled || noNumber}
        showAsterisk={showAsterisk}
        validChars={validChars}
        required
      />
      {!disabled && hasNoNumberCheckbox && (
        <Checkbox
          color={checkbox.color}
          label={noNumberLabel}
          onChange={(e) => handleNoNumber(e)}
          checked={noNumber}
        />
      )}
    </div>
  )
}
