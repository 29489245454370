import {
 useContext, useEffect, useRef, useState,
} from 'react'
import { IdentityContext, IdentityInfoProps } from '@/main/contexts'
import { GetAddressDataParams } from '@/data/use-cases/address/remote-get-address-data'
import { AddressFormValues } from '../models'

export type AddressDataResult = {
  data: AddressFormValues
  isLoading: boolean
  error?: Error
}

type UseAddressDataParams = {
  getAddressData: (params: GetAddressDataParams) => Promise<AddressFormValues>
}

export const useAddressData = ({ getAddressData }: UseAddressDataParams): AddressDataResult => {
  const [addressData, setAddressData] = useState<AddressDataResult>({
    data: {},
    isLoading: true,
    error: undefined,
  })
  const { personId, countryId, companyId } = useContext<IdentityInfoProps>(IdentityContext)

  const fetchAddressData = () => {
    if (!personId || !countryId || !companyId) {
      return
    }
    const parameters: GetAddressDataParams = {
      personId,
      countryId,
      companyId,
    }

    getAddressData(parameters)
      .then((responseData: AddressFormValues) => {
        setAddressData({
          data: responseData,
          isLoading: false,
        })
      })
      .catch((error: Error) => {
        setAddressData({
          data: {},
          isLoading: false,
          error,
        })
      })
  }

  const fetchAddressDataRef = useRef(fetchAddressData)

  useEffect(() => {
    fetchAddressDataRef.current()
  }, [])

  return addressData
}
