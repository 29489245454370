import React from 'react'
import { ControlledTextField } from '@/main/components'
import { FieldsOrderProps } from '@/domain/models/tenant/tenant-configs/configs'

export const StreetField = (fieldsOrderProps: FieldsOrderProps) => {
  const { messages, configs, control } = fieldsOrderProps
  return (
    <>
      <ControlledTextField
        id="street"
        label={messages.street.label}
        placeholder={messages.street.placeholder}
        control={control}
        rules={{
          required: {
            message: messages.street.rules.required,
            value: configs.street.rules.required,
          },
          maxLength: {
            message: messages.street.rules.maxLength,
            value: configs.street.rules.maxLength,
          },
        }}
        showAsterisk={false}
        validChars={configs.street.validChars}
      />
    </>
  )
}
