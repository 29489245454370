import { Theme } from '@material-ui/core/styles'
import { makeStyles, TextField } from '@naturacosmeticos/natds-web'
import styled from 'styled-components'

export const useControlledTextFieldStyles = makeStyles<Theme>((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1.5),
  },
}))

export const FVTextField = styled(TextField)`
  .MuiInputBase-root.Mui-disabled {
    color: ${({ theme }) => `${theme.color.highEmphasis}` };
    -webkit-text-fill-color: ${({ theme }) => `${theme.color.highEmphasis}` };
    background-color: ${({ theme }) => `${theme.color.neutral100}` };
  }

  .MuiFormLabel-root.Mui-disabled {
    color: ${({ theme }) => `${theme.color.mediumEmphasis}` };
  }
`
