import {
  ControlledSelect,
  ControlledSelectProps,
  SelectOption,
} from '@/main/components'
import React, { useEffect, useState } from 'react'

export type AsyncSelectProps = {
  optionsPromise?: Promise<SelectOption[]>;
} & Omit<ControlledSelectProps, 'options'>

export const AsyncSelect: React.FC<AsyncSelectProps> = ({
 id, label, control, optionsPromise, placeholder, rules, name, disabled = false, hasAsteriskInTheLabel = false, 'data-testid': dataTestId,
}) => {
  const [isFetchingOptions, setIsFetchingOptions] = useState<boolean>(false)
  const [options, setOptions] = useState<SelectOption[]>([])

  useEffect(() => {
    if (optionsPromise) {
      setIsFetchingOptions(true)
      optionsPromise
        .then(setOptions)
        .catch(() => setOptions([]))
        .finally(() => setIsFetchingOptions(false))
    }
  }, [optionsPromise])

  return (
    <>
      <ControlledSelect
        id={id}
        data-testid={dataTestId}
        name={name}
        label={label}
        aria-label={label}
        control={control}
        placeholder={placeholder}
        options={options}
        rules={rules}
        disabled={disabled}
        isLoading={isFetchingOptions}
        hasAsteriskInTheLabel={hasAsteriskInTheLabel}
      />
    </>
  )
}
