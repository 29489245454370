import { AddressPageForms } from '@/data/use-cases/register-maintenance/remote-get-multiple-address'
import {
  AddressConfigs,
  AttachmentsConfigs,
  AuthorizationCodeConfigs,
  CompletedConfigs,
  DigitalSignaturePageConfigs,
  DigitalStoreConfigs,
  DocumentationConfigs,
  MaintenancePersonalDataConfigs,
  MaintenanceRegisterConfigs,
  PagesTenantConfigs,
  PersonalDataConfigs,
  PreRegisterConfigs,
  ProfileConfigs,
} from './configs.d'

import { VendorId } from '../../vendor/vendor-id'
import { DigitalStoreTermsEnum } from '../../digital-store/digital-store-terms-type'
import { AgreementProduct } from '../../agreement/agreement-product'
import { AddressPageType, AddressOrder } from '../../address'

const personalData: PersonalDataConfigs = null

const preRegister: PreRegisterConfigs = null

const attachments: AttachmentsConfigs = null

const digitalStore: DigitalStoreConfigs = {
  showSkipButton: false,
  showEmailInfoRegistration: false,
  acceptTermsStrategy: DigitalStoreTermsEnum.EMPTY,
  vendorId: VendorId.EMPTY,
  agreement: {
    products: [AgreementProduct.DIGITAL_STORE],
  },
  digitalStoreNameMaxOfChars: null,
  shouldUnlinkAgreements: true,
}

const documentation: DocumentationConfigs = null

const digitalSignaturePage: DigitalSignaturePageConfigs = null

const completed: CompletedConfigs = null

const authorizationCode: AuthorizationCodeConfigs = null

const maintenanceRegisterPage: MaintenanceRegisterConfigs = {
  shouldEditEmail: true,
  shouldEditPhone: true,
  phoneNumberAreaCode: '55',
  phoneNumberOptions: {
    mask: ['(', /[1-9]/, /[1-9]*/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/],
    minLength: 10,
    maxLength: 11,
  },
}

const maintenancePersonalDataPage: MaintenancePersonalDataConfigs = {
  shouldEditEmail: true,
  shouldEditPhone: true,
}

const address: AddressConfigs = {
  pageType: AddressPageType.GeographicalLevels,
  shouldDisableTheFilledInput: false,
  zipCode: {
    rules: {
      maxLength: 9,
      pattern: new RegExp(/^[0-9]{5}-[0-9]{3}$/),
    },
    mask: [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/],
    regex: /^(\d{5})(\d{3})+?$/,
    regexOption: '$1-$2',
    shouldDisplayLink: () => true,
  },
  number: {
    validChars: new RegExp(/^[A-Za-z0-9]*\d+[A-Za-z0-9]*$/),
    hasNoNumberCheckbox: true,
  },
  complement: {
    pattern: new RegExp(/^[A-Za-z0-9. ]*$/),
  },
  fieldsOrder: [
    AddressOrder.STREET,
    AddressOrder.NUMBER,
    AddressOrder.COMPLEMENT,
    AddressOrder.REFERENCES,
    AddressOrder.NEIGHBORHOOD,
    AddressOrder.CITY,
    AddressOrder.STATE,
  ]
}

const profile: ProfileConfigs = {
  contact: {
    phoneMask: {
      maskRegex: /^(\d\d)(\d{4,5})(\d{4}).*/,
      mask: '$1 $2-$3',
    },
    editUrl: '/webfv/mfe-register/maintenanceRegisterPage/',
  },
  consultantData: {
    editUrl: '/webfv/mfe-register/maintenancePersonalDataPage/',
  },
  digitalSpace: {
    digitalSpaceBaseUrl: 'https://www.natura.com.br/consultoria/',
    editMySpacePath: '/webfv/cnd/my-space',
    mySpaceDashboard: '/webfv/digitalSpace',
    openDigitalSpaceUrl: '/webfv/cnd/my-space',
  },
  address: {
    getAddress: ({ addressPlaceTypeName, street, number, complement, neighborhood, city, state }: AddressPageForms) => {
      const address = [street, number, complement, neighborhood, city].filter(Boolean).join(', ')
      const addressPlaceType = addressPlaceTypeName || ''
      return `${addressPlaceType.toLowerCase()} ${address} - ${state.toUpperCase()}`
    },
    editUrl: '/webfv/mfe-register/maintenanceAddressPage/',
  },
}

export const naturaBraConfigs: PagesTenantConfigs = {
  documentation,
  personalData,
  attachments,
  preRegister,
  digitalStore,
  address,
  digitalSignaturePage,
  completed,
  authorizationCode,
  maintenanceRegisterPage,
  maintenancePersonalDataPage,
  profile,
}
