import React, { useState, useContext, useEffect } from 'react'
import { ButtonTextFieldProps, ControlledTextFieldWithButton, InputTextFieldProps } from '@/main/components'
import { useFormContext } from 'react-hook-form'
import { Typography } from '@naturacosmeticos/natds-web'
import {
  FieldContainer, SelectedPersonContainer,
} from '@/main/components/selected-person-field/selected-person-field.styles'
import { ModalWithPersonCheckbox } from '@/main/components/modal-with-person-checkbox'
import { ModalPersonMessages } from '@/domain/models/messages/messages'
import {
  IndicationInfo,
  RemoteSearchIndicationParams,
  PersonInfoInterface,
} from '@/data/use-cases/indication/remote-search-indication'
import { IdentityContext } from '@/main/contexts'

export type PersonFieldMessages = {
  selectedPersonLabel: string
  input: {
    label: string,
    placeholder: string,
  }
  button: {
    text: string
  },
  requiredErrorMessage: string,
  modal: ModalPersonMessages
}

type SelectedPersonFieldProps = {
  messages: PersonFieldMessages,
  showAsteriskInRequiredLabel?: boolean,
  hasNoSelectedPerson?: boolean,
  id?: string,
  searchPerson?: (params: RemoteSearchIndicationParams) => Promise<IndicationInfo>
  personCode?: string
  selectedPerson: PersonInfoInterface
  setSelectedPerson: (indicationInfo: PersonInfoInterface) => void
}

export const SelectedPersonField: React.FC<SelectedPersonFieldProps> = ({
  messages,
  searchPerson,
  selectedPerson,
  setSelectedPerson,
  showAsteriskInRequiredLabel = false,
  hasNoSelectedPerson = false,
  id = 'indication',
  personCode,
}) => {
  const { control, setValue, getValues } = useFormContext()
  const fieldRules = new RegExp(/^\d{0,15}$/)
  const shouldButtonBeDisabled = hasNoSelectedPerson || !getValues(id)
  const [isSearchLoading, setIsSearchLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isPersonSelected, setIsPersonSelected] = useState(false)
  const [personErrorMessage, setPersonErrorMessage] = useState<string>('')

  const {
    companyId,
    countryId,
  } = useContext(IdentityContext)

  useEffect(() => {
    if (personCode) {
      searchPerson({
        personCode,
        companyId,
        countryId,
      }).then((indicationInfo: IndicationInfo) => {
        setSelectedPerson({ ...indicationInfo, isSelected: true })
        setPersonErrorMessage('')
      }).catch((err) => {
        setPersonErrorMessage(messages.modal[err.message])
      })
    }
  }, [personCode])

  useEffect(() => {
    if (hasNoSelectedPerson) {
      setSelectedPerson({})
    }
  }, [hasNoSelectedPerson, setSelectedPerson])

  const onSearchClick = () => {
    setIsSearchLoading(true)
    openModal()
    searchPerson({
      personCode: getValues(id),
      companyId,
      countryId,
    }).then((indicationInfo: IndicationInfo) => {
      setSelectedPerson({ ...indicationInfo, isSelected: false })
      setPersonErrorMessage('')
    }).catch((error) => {
      setSelectedPerson({})
      setPersonErrorMessage(messages.modal[error.message])
    }).finally(() => {
      setIsSearchLoading(false)
    })
  }

  const confirmIndication = () => {
    setSelectedPerson({ ...selectedPerson, isSelected: true })
    closeModal()
    setIsPersonSelected(false)
  }

  const confirmationError = () => {
    closeModal()
    setSelectedPerson({})
    setValue(id, '', { shouldValidate: true, shouldDirty: true })
  }

  const openModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const buttonProps: ButtonTextFieldProps = {
    buttonLabel: messages.button.text,
    buttonOnClick: onSearchClick,
    shouldButtonBeDisabled,
  }

  const inputProps: InputTextFieldProps = {
    disabled: hasNoSelectedPerson,
  }

  return (
    <>
      <FieldContainer>
        <ControlledTextFieldWithButton
          id={id}
          label={messages.input.label}
          control={control}
          placeholder={messages.input.placeholder}
          validChars={fieldRules}
          rules={{ required: messages.requiredErrorMessage as string }}
          showAsterisk={showAsteriskInRequiredLabel}
          inputProps={inputProps}
          buttonProps={buttonProps}
          required
        />
      </FieldContainer>

      {selectedPerson?.isSelected && (
        <SelectedPersonContainer data-testid="selected-person-container">
          <Typography variant="body2">{messages.selectedPersonLabel}</Typography>
          <Typography variant="subtitle2">{selectedPerson.name}</Typography>
        </SelectedPersonContainer>
      )}

      <ModalWithPersonCheckbox
        errorMessage={personErrorMessage}
        info={selectedPerson}
        isItemSelected={isPersonSelected}
        onItemSelected={() => setIsPersonSelected(!isPersonSelected)}
        confirmationError={confirmationError}
        confirmSelection={confirmIndication}
        isModalOpen={isModalOpen}
        handleCloseModal={closeModal}
        messages={messages.modal}
        isSearchLoading={isSearchLoading}
      />
    </>
  )
}
