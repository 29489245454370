import React, {
  ReactNode,
  useCallback,
  useLayoutEffect,
  useState,
} from 'react'
import {
  Dialog,
  DialogTitle,
  DialogActions,
} from '@/main/components/dialog'
import { Button } from '@/main/components/button'
import { DialogContent } from './dialog-agreements.styles'

enum DialogId {
  content = 'dialog-content'
}

export interface DialogAgreementsProps {
  title: string
  content: ReactNode
  closeButtonText: string
  acceptButtonText: string
  open: boolean
  onAccept: () => void
  onOpen: (open: boolean) => void
}

export const DialogAgreements: React.FC<DialogAgreementsProps> = ({
  title,
  content,
  closeButtonText,
  acceptButtonText,
  open,
  onAccept,
  onOpen,
}) => {
  const [agreementContent, setAgreementContent] = useState<ReactNode>(<></>)

  const handleClose = () => {
    onOpen(false)
  }

  const handleSetAgreementsContent = useCallback(() => {
    setAgreementContent(content)
  }, [content])

  useLayoutEffect(() => {
    handleSetAgreementsContent()
  }, [handleSetAgreementsContent])

  return (
    <Dialog
      open={open}
      maxWidth="md"
      scroll="paper"
      aria-describedby={DialogId.content}
      onEscapeKeyDown={handleClose}
      onBackdropClick={handleClose}
    >
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent id={DialogId.content}>
        {agreementContent}
      </DialogContent>
      <DialogActions>
        <Button
          color="default"
          variant="text"
          onClick={handleClose}
        >
          {closeButtonText}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={onAccept}
        >
          {acceptButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
