import React from 'react'
import { Link as DSLink } from '@naturacosmeticos/natds-web'
import { Container } from './external-link.styles'

interface ExternalLinkProps {
  text: string,
  href: string,
}

export const ExternalLink: React.FC<ExternalLinkProps> = ({
  text,
  href,
}) => (
  <Container>
    <DSLink href={href} target="_blank">
      {text}
    </DSLink>
  </Container>
)
