/* eslint-disable no-useless-escape */
export const regexToValidateCommonSpecialCharactersForMexico = new RegExp(
  /^[a-zA-Z0-9\\\-\\\(\\)\\#\\_\\\/\\:\\,\\. ]*$/
)

export const regexToValidateAllSpecialStreetCharactersForMexico = new RegExp(
  /^[a-zA-Z0-9áéíóúÁÉÍÓÚ'\\\-\\\(\\)\\#\\_\\\/\\:\\,\\. ]{0,24}$/
)

export const regexToValidateAllSpecialNumberCharactersForMexico = new RegExp(
  /^[a-zA-Z0-9áéíóúÁÉÍÓÚ'\\\-\\\(\\)\\#\\_\\\/\\:\\,\\. ]{0,8}$/
)
