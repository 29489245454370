import React, { useContext, useEffect, useState } from 'react'
import { Container } from '@/main/components/pages-structures'
import { Icon, Spacing } from '@naturacosmeticos/natds-web'
import { FormProvider, useForm } from 'react-hook-form'

import {
  EmailsType,
  IdentityContext, NavigationContext, NavigationContextProps, PhonesType,
} from '@/main/contexts'
import { useTenantConfigs } from '@/main/hooks/useTenantConfigs'
import { Page } from '@/domain/models/page/page'
import { MaintenanceRegisterConfigs } from '@/domain/models/tenant/tenant-configs'
import { usePageMessages } from '@/main/hooks/usePageMessages'
import { MaintenanceRegisterMessages } from '@/domain/models/messages/messages'
import { GetPersonDataParams, PersonData, PersonDataResponse } from '@/domain/use-cases/person'
import { Email, Phone, SaveConsultantInfoParams } from '@/domain/use-cases/register-maintenance/save-consultant-info'
import { DialogWithLoader } from '@/main/pages/register-maintenance/commons/components/dialog-with-loader/dialog-with-loader'
import { getOnlyNumbers } from '@/main/pages/register-maintenance/commons/common-fuctions'
import { Button } from '@/main/components'
import { PhoneType } from '@/domain/models/person'
import { useHistory } from 'react-router-dom'
import { PhoneNumber } from './fields/phone-number'
import { MaintenanceDialog } from './fields/maintenance-dialog'
import { Title } from './fields/title'
import { RegisterMaitenancePageApi } from './api/make-register-maintenance-page-api'
import { EmailMessages, EmailTextField } from '../personal-data/fields'

export type MaintenanceRegisterPageProps = {
  api: RegisterMaitenancePageApi
}

export type FormMaintenanceRegister = {
  email: string,
  phoneNumber: string
  phoneNumberSecondary?: string
}

export type DialogState = {
  message?: string
  isOpen: boolean
  cancelButtonName?: string
  confirmationButtonCallback?: () => void
}

type SavedContactInfo = Pick<PersonData, 'emails' | 'phones' | 'birthday' | 'gender' | 'motherName' | 'name'>

const getFirst = (arr: Array<any>) => {
  const [first] = arr || undefined
  return first
}

const getSecond = (phones: PhonesType[]) => getFirst(phones.slice(1, 2))

export const getFormattedPhoneNumber = (phone: PhonesType): string => {
  if (!phone) return undefined

  const areaCode = phone?.areaCode || ''
  const phoneNumber = phone?.phoneNumber || ''
  return areaCode.concat(phoneNumber)
}

export const MaintenanceRegisterPage = ({ api }: MaintenanceRegisterPageProps) => {
  const [modalStatus, setModalStatus] = useState<DialogState>({ message: '', isOpen: false })
  const [isLoading, setIsLoading] = useState(true)
  const [openSubmitLoading, setOpenSubmitLoading] = useState(false)
  const [savedContactInfos, setSavedContactInfos] = useState<SavedContactInfo>()
  const [showRemoveButton, setShowRemoveButton] = useState<boolean>(false)

  const {
    tenantId,
    consultantId,
    personId,
    countryId,
    companyId,
  } = useContext(IdentityContext)

  const {
    goToPreviousPage,
  } = useContext<NavigationContextProps>(NavigationContext)

  const history = useHistory()

  const {
    shouldEditEmail,
    shouldEditPhone,
    phoneNumberOptions,
    phoneNumberAreaCode,
  } = useTenantConfigs(tenantId, Page.MaintenanceRegister) as MaintenanceRegisterConfigs
  const messages = usePageMessages(Page.MaintenanceRegister).messages as MaintenanceRegisterMessages

  const phoneMessages = {
    label: messages.phoneNumber?.label,
    secondaryPhoneLabel: messages.phoneNumber.secondaryPhoneLabel,
    placeholder: messages.phoneNumber?.placeholder,
    requiredErrorMessage: messages.requiredErrorMessage,
    firstDigitErrorMessage: messages.firstDigitErrorMessage,
    lengthErrorMessage: messages.lengthErrorMessage,
    addPhoneNumber: messages.phoneNumber.addPhoneNumber,
    removePhoneNumber: messages.phoneNumber.removePhoneNumber,
  }
  const emailMessages: EmailMessages = {
    label: messages.email?.label,
    placeholder: messages.email?.placeholder,
    requiredErrorMessage: messages.requiredErrorMessage,
    errorMessage: messages.email.errorMessage,
  }

  const formMethods = useForm<FormMaintenanceRegister>({
    mode: 'onTouched',
  })

  const {
    getValues,
    reset,
    formState,
  } = formMethods

  useEffect(() => {
    const getConsultantInfo = async () => {
      const data: GetPersonDataParams = {
        personId: consultantId,
        companyId,
        countryId,
        relations: ['emails', 'telephones'],
      }
      try {
        const { person } = await api.getConsultantInfo(data) as PersonDataResponse
        const retrievedContactInfo = {
          email: getFirst(person.emails).email,
          phoneNumber: getFormattedPhoneNumber(getFirst(person.phones)),
          phoneNumberSecondary: getFormattedPhoneNumber(getSecond(person.phones)),
        }
        setSavedContactInfos({
          phones: person.phones,
          emails: person.emails,
          birthday: person.birthday,
          gender: person.gender,
          motherName: person.motherName,
          name: person.name,
        })

        setShowRemoveButton(retrievedContactInfo.phoneNumberSecondary !== undefined)
        reset(retrievedContactInfo)
      } catch {
        console.warn('Error on get consultant')
      } finally {
        setIsLoading(false)
      }
    }

    getConsultantInfo()
  }, [api, companyId, consultantId, countryId, personId, reset])

  const handleSubmit = () => {
    try {
      const { phoneNumber, phoneNumberSecondary, email } = getValues()
      if (isEmailAlreadyExists(email, savedContactInfos.emails)) {
        setModalStatus({ message: messages.duplicatedEmailErrorMessage, isOpen: true, confirmationButtonCallback: onCloseWithoutPreviousPage })
        return
      }

      setOpenSubmitLoading(true)
      const data: SaveConsultantInfoParams = {
        personId: consultantId,
        companyId,
        countryId,
        birthday: savedContactInfos.birthday,
        gender: savedContactInfos.gender,
        motherName: savedContactInfos.motherName,
        name: savedContactInfos.name,
      }

      if (isPhoneUpdated(getFirst(savedContactInfos.phones), phoneNumber)
        || isPhoneUpdatedOrRemoved(getSecond(savedContactInfos.phones), phoneNumberSecondary)) {
        data.phones = getUpdatedPhones(savedContactInfos, phoneNumber, phoneNumberSecondary)
      }

      if (isEmailUpdated(savedContactInfos, email)) {
        data.emails = getUpdatedEmails(savedContactInfos, email)
      }

      api.saveConsultantInfoBasicData(data)
        .then(() => {
          setModalStatus({ message: messages.successMessage, isOpen: true, confirmationButtonCallback: onClose })
        })
        .catch((error) => {
          const message = getErrorMessagesOnSave(error)
          setModalStatus({ message, isOpen: true, confirmationButtonCallback: onCloseWithoutPreviousPage })
        })
        .finally(() => {
          setOpenSubmitLoading(false)
        })
    } catch (e) {
      setModalStatus({ message: messages.unexpectedErrorMessage, isOpen: true, confirmationButtonCallback: onCloseWithoutPreviousPage })
      setOpenSubmitLoading(false)
    }
  }

  const goBackPreviousPage = () => (goToPreviousPage ? goToPreviousPage() : history.goBack())

  const onClose = () => {
    setModalStatus({ isOpen: false })
    goBackPreviousPage()
  }

  const onCloseWithoutPreviousPage = () => {
    setModalStatus({ isOpen: false })
  }

  const getUpdatedPhones = (savedContactInfos: SavedContactInfo, phoneNumber: string, secondaryPhoneNumber: string): Phone[] => {
    const [first, second, ...otherPhones] = savedContactInfos.phones
    const originalPhoneNumber = first
    const originalSecondaryPhoneNumber = second

    const updatedPhone = mapToPhone(phoneNumber, originalPhoneNumber, PhoneType.WHATSAPP)
    const updatedSecondaryPhone = mapToPhone(secondaryPhoneNumber, originalSecondaryPhoneNumber, PhoneType.ANY)

    const newPhones = [updatedPhone]
    if (updatedSecondaryPhone) newPhones.push(updatedSecondaryPhone)

    const updatedOthersPhones: Phone[] = otherPhones.map((phone) => ({
      areaCode: phone.areaCode,
      phoneNumber: phone.phoneNumber,
      countryCode: phone.countryCode,
      type: phone.type,
      sequence: phone.sequence,
      updatedAt: phone.updatedAt,
    }))
    return [...newPhones, ...updatedOthersPhones]
  }

  const mapToPhone = (phoneNumber: string, originalPhone: PhonesType, phoneType: PhoneType): Phone => {
    if (!phoneNumber) return null

    const onlyNumber = getOnlyNumbers(phoneNumber)

    const updatedPhone: Phone = {
      areaCode: onlyNumber.substring(0, 2),
      phoneNumber: onlyNumber.substring(2, onlyNumber.length).trim(),
      countryCode: originalPhone?.countryCode || phoneNumberAreaCode,
      type: phoneType,
    }

    return updatedPhone
  }

  const handlePhoneNumberButton = () => {
    if (showRemoveButton) {
      setModalStatus({
        message: messages.confirmationPhoneToRemoveMessage,
        isOpen: true,
        cancelButtonName: messages.cancelDialogButtonName,
        confirmationButtonCallback: () => {
          setShowRemoveButton(!showRemoveButton)
          onCloseWithoutPreviousPage()
        },
      })
    } else {
      setShowRemoveButton(!showRemoveButton)
    }
  }

  const getErrorMessagesOnSave = (error: any) => {
    const isEmailAlreadyInUseError = error?.data?.code === 'PR-0002'
    return isEmailAlreadyInUseError ? messages.emailAlreadyInUseErrorMessage : messages.unexpectedErrorMessage
  }

  const isEmailAlreadyExists = (emailToCheck: string, emails: EmailsType[]) => {
    const [, ...emailsWithoutFirstElement] = emails
    return emailsWithoutFirstElement.some((emailType) => emailType.email === emailToCheck)
  }

  return (
    <Container
      nextButtonLabel={messages.nextButtonLabel}
      showSkipButton
      previousButtonLabel={messages.backButtonLabel}
      onPreviousButtonClick={goBackPreviousPage}
      onNextButtonClick={handleSubmit}
      disableNextButton={!formState.isValid}
      isLoading={isLoading}
      nextButtonTextInline
    >
      <Title title={messages.title} />
      <FormProvider {...formMethods}>
        {
          shouldEditPhone ? (
            <>
              <PhoneNumber
                id="phoneNumber"
                name="phoneNumber"
                messages={phoneMessages}
                rules={{ required: true }}
                mask={phoneNumberOptions?.mask}
                options={{
                  maxLength: phoneNumberOptions.maxLength,
                  minLength: phoneNumberOptions.minLength,
                }}
              />
              {showRemoveButton
                && (
                  <PhoneNumber
                    id="phoneNumberSecondary"
                    name="phoneNumberSecondary"
                    messages={{ ...phoneMessages, label: phoneMessages.secondaryPhoneLabel }}
                    rules={{ required: true }}
                    mask={phoneNumberOptions?.mask}
                    options={{
                      maxLength: phoneNumberOptions.maxLength,
                      minLength: phoneNumberOptions.minLength,
                    }}
                  />
                )}
              <Button
                startIcon={<Icon name={showRemoveButton ? 'filled-action-subtract' : 'filled-action-add'} />}
                onClick={handlePhoneNumberButton}
              >
                {showRemoveButton ? phoneMessages.removePhoneNumber : phoneMessages.addPhoneNumber}
              </Button>
            </>
          ) : null
        }
        <Spacing
          className="natds2"
          display="flex"
          margin="tiny"
        />
        {
          shouldEditEmail ? (
            <EmailTextField
              messages={emailMessages}
              customProps={{
                shouldInfoTextBeVisible: true,
                icon: <Icon name="outlined-communication-email" size="small" />,
              }}
            />
          ) : null
        }
      </FormProvider>
      {modalStatus.isOpen && (
        <MaintenanceDialog
          message={modalStatus.message}
          open={modalStatus.isOpen}
          confirmButtonName={messages.dialogButtonName}
          onClickConfirmButton={modalStatus.confirmationButtonCallback}
          cancelButtonName={modalStatus.cancelButtonName}
          onClickCancelButton={onCloseWithoutPreviousPage}
        />
      )}
      <DialogWithLoader isOpen={openSubmitLoading} />
    </Container>
  )
}

function getUpdatedEmails(savedContactInfos: SavedContactInfo, email: string): Email[] {
  const [first, ...otherEmails] = savedContactInfos.emails
  const updatedEmail: Email = {
    email,
    type: first.type,
  }

  const updatedOtherEmails: Email[] = otherEmails.map((email) => ({
    email: email.email,
    type: email.type,
    sequence: email.sequence,
    updatedAt: email.updatedAt,
  }))
  return [updatedEmail, ...updatedOtherEmails]
}

function isPhoneUpdated(originalPhone: PhonesType, phoneNumber: string): boolean {
  return phoneNumber && (originalPhone?.areaCode.concat(originalPhone?.phoneNumber) !== phoneNumber)
}

function isPhoneUpdatedOrRemoved(originalPhone: PhonesType, phoneNumber: string): boolean {
  const removed = originalPhone && !phoneNumber
  return removed || isPhoneUpdated(originalPhone, phoneNumber)
}

function isEmailUpdated(savedContactInfos: SavedContactInfo, email: string): boolean {
  const savedEmail: EmailsType = getFirst(savedContactInfos.emails)
  return (savedEmail.email !== email)
}
