import { MaintenancePersonalDataMessages } from '@/domain/models/messages/messages'
import { TFunction } from 'i18next'

export function getMaintenancePersonalData(translate: TFunction): MaintenancePersonalDataMessages {
  return {
    labels: {
      name: translate('maintenancePersonalDataPage.labels.name'),
      nickname: translate('maintenancePersonalDataPage.labels.nickname'),
      placeholderNickname: translate('maintenancePersonalDataPage.labels.placeholderNickname'),
      infoTextNickname: translate('maintenancePersonalDataPage.labels.infoTextNickname'),
      gender: translate('maintenancePersonalDataPage.labels.gender'),
      previousButton: translate('maintenancePersonalDataPage.labels.previousButton'),
      saveButton: translate('maintenancePersonalDataPage.labels.saveButton'),
    },
    dialog: {
      successMessage: translate('maintenancePersonalDataPage.dialog.successMessage'),
      unexpectedErrorMessage: translate('maintenancePersonalDataPage.dialog.unexpectedErrorMessage'),
      buttonName: translate('maintenancePersonalDataPage.dialog.buttonName'),
    },
    title: translate('maintenancePersonalDataPage.title'),
    requiredMessage: translate('maintenancePersonalDataPage.requiredMessage'),
  }
}
