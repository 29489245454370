import React from 'react'
import { ControlledTextField } from '@/main/components'
import { FieldsOrderProps } from '@/domain/models/tenant/tenant-configs/configs'

export const NeighborhoodField = (fieldsOrderProps: FieldsOrderProps) => {
  const { messages, control, configs } = fieldsOrderProps
  return (
    <>
      <ControlledTextField
        id="neighborhood"
        label={messages.neighborhood.label}
        placeholder={messages.neighborhood.placeholder}
        control={control}
        rules={{
          maxLength: {
            message: messages.neighborhood.rules.maxLength,
            value: configs.neighborhood.rules.maxLength,
          },
          required: {
            message: messages.neighborhood.rules.required,
            value: configs.neighborhood.rules.required,
          },
        }}
        validChars={configs.neighborhood.validChars}
        disabled
      />
    </>
  )
}
