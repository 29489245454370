import { AgreementProduct } from '@/domain/models/agreement/agreement-product'
import { CompanyId } from '@/domain/models/company/company-id'
import { CountryId } from '@/domain/models/country/country-id'
import { Agreement } from '@/domain/use-cases/agreement'
import { TermsAgreementLabel } from '@/main/components/accept-terms/components/terms-agreement-label/terms-agreement-label'
import { FVProgressIndicator } from '@/main/components/progress-indicator'
import { Spacing } from '@/main/components/spacing'
import { Typography } from '@/main/components/typography'
import { makeRemoteGetAgreementByProduct } from '@/main/factories/remote/agreement'
import React, {
  MouseEvent,
  KeyboardEvent,
  ChangeEvent,
  useCallback,
  useEffect,
  useState,
  ReactNode,
} from 'react'
import { Checkbox, CheckboxProps } from '../checkbox'
import { AgreementContent, Container } from './accept-terms.styles'
import { DialogAgreements } from './components/dialog-agreements'

type CheckboxAcceptTerms = {
  acceptTermsAriaLabel?: string
  normalLabel: string,
  highlightedLabel: string,
  onChange?: (hasChange: boolean) => void,
} & Pick<CheckboxProps, 'color' | 'inputRef' | 'value' | 'onBlur'>

type DialogInfo = {
  title: string
  acceptText: string
  closeText: string
}

export interface AcceptTermsProps {
  checkbox: CheckboxAcceptTerms
  dialogInfo: DialogInfo
  countryId?: CountryId
  companyId?: CompanyId
  products?: AgreementProduct[]
  personId?: string
  agreements?: Agreement[]
  shouldFetch?: boolean
  termsInfo?: string
  shouldHide?: boolean
  initialDialogState?: boolean
  setUserNeedsToAcceptTerms?: (needToAccept: boolean) => void
  setAgreementId?: (id: string[]) => void
}

export const AcceptTerms: React.FC<AcceptTermsProps> = ({
  personId,
  countryId,
  companyId,
  products,
  checkbox,
  dialogInfo,
  agreements,
  shouldFetch = true,
  shouldHide = false,
  termsInfo = null,
  initialDialogState = false,
  setUserNeedsToAcceptTerms,
  setAgreementId,
}) => {
  const [loading, setLoading] = useState(false)
  const [dialog, setDialog] = useState(initialDialogState)
  const [agreementsContent, setAgreementContent] = useState<ReactNode[]>([])

  const handleSetAgreementsContent = useCallback((agreements: Agreement[]) => {
    const content = agreements.map(({ agreementId, rawText }) => (
      <Spacing
        marginBottom="standard"
        key={agreementId}
      >
        <AgreementContent
          variant="body1"
          color="textPrimary"
        >
          {rawText}
        </AgreementContent>
      </Spacing>
    ))
    setAgreementContent(content)
  }, [])

  const getAgreement = useCallback(async () => {
    const handleSetAgreementsId = (agreements: Agreement[]) => {
      const agreementsIds = agreements.map((agreement) => agreement.agreementId)
      setAgreementId(agreementsIds)
    }

    const remoteGetAgreementByProduct = makeRemoteGetAgreementByProduct()

    if (shouldFetch) {
      setLoading(true)

      try {
        const { agreements, userNeedsToAcceptTerms } = await remoteGetAgreementByProduct.get({
          personId,
          countryId,
          products,
          companyId,
        })

        handleSetAgreementsContent(agreements)

        if (setAgreementId) {
          handleSetAgreementsId(agreements)
        }
        if (setUserNeedsToAcceptTerms) {
          setUserNeedsToAcceptTerms(userNeedsToAcceptTerms)
        }
      } catch {
        // catch
      } finally {
        setLoading(false)
      }
    }
  }, [shouldFetch, setAgreementId, products, personId, countryId, companyId, handleSetAgreementsContent, setUserNeedsToAcceptTerms])

  useEffect(() => {
    getAgreement()
  }, [getAgreement])

  useEffect(() => {
    if (agreements) {
      handleSetAgreementsContent(agreements)
    }
  }, [agreements, handleSetAgreementsContent])

  const {
    acceptTermsAriaLabel,
    color,
    normalLabel,
    highlightedLabel,
    inputRef,
    value,
    onBlur,
    onChange,
  } = checkbox

  const {
    title,
    acceptText,
    closeText,
  } = dialogInfo

  const handleOpenDialog = (event: MouseEvent<HTMLElement> | KeyboardEvent): void => {
    event.preventDefault()
    setDialog(true)
  }

  const handleAcceptTerms = (): void => {
    setDialog(false)
    onChange(true)
  }

  return !shouldHide && (
    <>
      {(loading || (!agreements && shouldFetch === false))
        ? (
          <Spacing marginTop="small">
            <FVProgressIndicator
              ariaLabel={acceptTermsAriaLabel}
              testID="accept-terms-loading"
              size="semi"
            />
          </Spacing>
        )
        : (
          <Container>
            {termsInfo && (
              <Spacing marginBottom="tiny">
                <Typography>
                  {termsInfo}
                </Typography>
              </Spacing>
            )}
            <Checkbox
              color={color}
              label={(
                <TermsAgreementLabel
                  normalText={normalLabel}
                  highlightedText={highlightedLabel}
                  onClick={(event: MouseEvent<HTMLElement>) => handleOpenDialog(event)}
                  onKeyPress={(event: KeyboardEvent) => handleOpenDialog(event)}
                />
              )}
              checked={value}
              inputRef={inputRef}
              value={value}
              onBlur={onBlur}
              onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.checked)}
            />
          </Container>
        )}
      <DialogAgreements
        title={title}
        content={agreementsContent}
        closeButtonText={closeText}
        acceptButtonText={acceptText}
        open={dialog}
        onAccept={handleAcceptTerms}
        onOpen={setDialog}
      />
    </>
  )
}
